import axios from 'axios';
import URL from '../components/url';

export const GET_ALL_MISSIONS = () => {
  return axios.get(`${URL}mission/get`);
};

export const GET_MISSION_STATUS = (body) => {
  return axios.post(`${URL}user/mission-status`, body);
};

export const GET_LEADERBOARD = async (body) => {
  const accessToken = localStorage.getItem('access_token');

  return axios.post(`${URL}user/points-analytics`, body, {
    headers: { Authorization: accessToken },
  });
};

export const GET_LEADERBOARD_ALL_TIME = async (body) => {
  const accesstoken = localStorage.getItem('access_token');

  return axios.post(`${URL}leaderboard/all-time-points`, body, {
    headers: { Authorization: accesstoken },
  });
}

