import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import green_case from '../assets/green_case.png';
import pink_case from '../assets/pink_case.png';
import purple_case from '../assets/purple_case.png';
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { Typography, Grid, Menu, MenuItem, Modal, Box, TextField } from "@mui/material";
import mammoth from 'mammoth';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import axios from 'axios';
import fileDownload from 'js-file-download';
import ShareIcon from "@mui/icons-material/Share";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";

import { JSEncrypt } from "jsencrypt";
import { DECRYPT_FILE, GET_PROFILE } from "../services/solana_services";
import { DELETE_FILE, GET_ALL_STORAGE_ACCOUNT_INFO, GET_All_FILES, GET_FILES_PIN, GET_STORAGE_ACCOUNT_INFO } from "../services/services";
import moment from "moment";
import { DEFAULT_IMG, IMGS_ARR } from "../constants/ImagesExts";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuIcon from '@mui/icons-material/Menu';
import AlertMessage from "./alertComponents/AlertMessage";
// import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1E1E1E",
    color: "#949494",
    fontSize: "1rem",
    borderBottom: "1px solid #949494",
    paddingLeft: "1rem",
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.8rem",
    backgroundColor: "#121212",
    color: "#949494",
    borderBottom: "1px solid #949494",
    paddingLeft: "1rem",
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables({ refreshing })  {
  const navigate = useNavigate();
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
 
  const [data,setData]=React.useState([ {value:50}, {value:80}, {value:90}, {value:70} ])
  const [userData,setUserData]=useState(null)
  const [files,setFiles]=useState([])
  const [allFilesDetailedArr,setAllFilesDetailedArr]=useState([])
  
  const [viewType,setViewType]=useState("list")
  const [isLoading, setIsLoading] = useState(false);
  const [decryptedFileData, setDecryptedFileData] = useState(null);
  
  const [storagePublicKey,setStoragePublicKey]=useState(null)
  const [solanaPublicKey, setSolanaPublicKey] = useState("")
  const [enabled,setEnabled]=useState(true)
  const [documentPicked, setResult] = useState(null);
  const [secretKey, setSecretKey] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isImmutable, setImmutable] = useState(false);
  const [vaultsArr, setVaultsArr] = React.useState([]);
  const [vaultsArrWithSizes, setVaultsArrWithSizes] = React.useState([]);
  const [immutableModal, setImmutableModal] = useState(false);
  const [vaultIndex, setVaultIndex] = React.useState(0);
  const [menuDisplay, setMenuDisplay] = React.useState(false);
  const [loaderVisible, setLoaderVisible] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);
  const [accessToken, setAccessToken] = React.useState("");
  const [totalStorage,setTotalStorage]=useState(0);
  const [loaderVal, setLoaderVal] = useState(0);
  const [solanaPublicAddress, setSolanaPublicAddress] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [userID, setUserID] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [userName, setUserName] = useState("")
  const [sortConfig, setSortConfig] = useState(null);
  const [open, setOpen] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', imageUrl: '' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [tweetContent, setTweetContent] = useState(
    'I just stored a file on a decentralized filesystem by @GenesysGo. Try it out now via @synx_xyz',
  );
  const [filteredFiles, setFilteredFiles] = useState(files);
  const [alertPopup, setAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  // const openRenameModal = () => setOpen(true);
  // const closeRenameModal = () => setOpen(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  useEffect(() => {
      getUserData();
   
    }, [])
    useEffect(() => {
      if (refreshing) {
        getUserData()
      }
    }, [refreshing]);
  const getUserData = async () => {
    const str_view_type = localStorage.getItem('ViewType');
    if(str_view_type){
    setViewType(str_view_type)
  }
  
  const str_currentVault = localStorage.getItem('currentVault');
  let CURRENT_VAULT_INDEX = 0
  if(str_currentVault){
    setVaultIndex(parseInt(str_currentVault))
    CURRENT_VAULT_INDEX = parseInt(str_currentVault)
  }
  const str_payload = localStorage.getItem('payload');
  let payload = JSON.parse(str_payload)
  setUserEmail(payload.email)
  setUserID(payload._id)
  setFirstName(payload.firstname)
  setLastName(payload.lastname)
  setUserName(payload.username)
  setSolanaPublicKey(payload.solanaaccount)
  const str_solana_keys = localStorage.getItem('solana_key');
  setSecretKey(str_solana_keys)
  getVaults(payload.email,payload._id,str_solana_keys)
  const accessTokenVal = localStorage.getItem('access_token');
  setAccessToken(accessTokenVal)
  setSolanaPublicAddress(payload.solanaaccount)

  console.log(accessTokenVal)
 


  var enc_secret_key = encrypt.encrypt(str_solana_keys);
  let get_profile_body = {"email": payload.email, "name": payload.username, "secret_key": enc_secret_key, "typeOfLogin": payload.typeOfLogin}
  GET_PROFILE(get_profile_body)
  .then(({ data }) => {
      let vaults_array = data.data.payload.user_profile.vaults;
      setVaultsArr(data.data.payload.user_profile.vaults)
      if(vaults_array.length > 0){

        setStoragePublicKey(vaults_array[CURRENT_VAULT_INDEX].vaultaddress)
        getStorageDetails(vaults_array[CURRENT_VAULT_INDEX].vaultaddress,data.data.payload.user_profile.email);
        getStorageList(data.data.payload.user_profile.email,data.data.payload.user_profile._id);
        getPinnedList(data.data.payload.user_profile.email);
      }
      else{
        setTimeout(function(){setIsLoading(false)}, 1500)
        
      }
 
})
.catch(async function (error) {

})
}
const getVaults = async (user_email, user_id,str_solana_keys) => {
   
  var enc_secret_key = encrypt.encrypt(str_solana_keys);
  let body = {
    "id": user_id,
    "secret_key": enc_secret_key,
    "email": user_email,
  };
  GET_ALL_STORAGE_ACCOUNT_INFO(body)
    .then(({ data }) => {
      if (data.data.payload) {
       setVaultsArrWithSizes(data.data.payload.valutsInfo)
      }
      else {
      
      }
    })
    .catch(function (error) {
     
      if(!error?.response?.data?.message.includes("Unauthorized")){
        setAlertType("error")
      setAlertMessage(error?.response?.data?.message)
      setAlertPopup(true)
       }
    
    });

}
const getStorageDetails = async (key, user_email) => {
  let body = {
    "storage_public_key": key,
    "email" : user_email,
};
GET_STORAGE_ACCOUNT_INFO(body)
.then(({ data }) => {
    if(data.status == 200){
      setImmutable(data.data.payload.immutable)
      setTotalStorage(data.data.payload.totalStorage)
    }
    else{
    
    }
})
.catch(function (error) {
  if (error.response) {
    setAlertType("error")
      setAlertMessage(error?.response?.data?.message)
      setAlertPopup(true)
  
  }
 });

}
const getStorageList = async (user_email,user_id) => {
   
  let body = {
    "id": user_id,
    "email": user_email,
  };

  GET_All_FILES(body)
  .then(({ data }) => {
    if (data.status === 200) {
      const payload = data.data.payload;
      const filesArr = payload.flatMap((item) =>
        item.files.map((element) => ({
          ...element,
          storage_address: item.storageAccount,
        }))
      );
      const sortedFiles = filesArr.sort((a, b) =>
        b.last_modified.localeCompare(a.last_modified)
      );
      setFiles(sortedFiles);
      setIsLoading(false);
    }
  })
  .catch((error) => {
    setAlertType("error")
      setAlertMessage(error?.response?.data?.message)
      setAlertPopup(true)
   
  });


}
const getPinnedList = async (user_email) => {
  let body = {
    "email" : user_email,
};
GET_FILES_PIN(body)
.then(({ data }) => {
  let emptyMessage = "";
    if(data.status == 200){
      setAllFilesDetailedArr(data.data.payload)
    }
})
.catch(function (error) {

 }).finally(() => { 
  setTimeout(function(){setIsLoading(false)}, 1500)

  setIsLoading(false) });;

}
const bytesToSize = (bytes) => {
  var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 B';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

  // LJ ATTEMPT AT TURNING ON FILTERS 
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // LJ ATTEMPT AT TURNING ON FILTERS 
  const sortFiles = (files) => {
    let sortedFiles = [...files];
    if (sortConfig !== null) {
      sortedFiles.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedFiles;
  }
  const decryptFile = async (item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size ) => {
    setIsFileLoading(true)
    var enc_secret_key = encrypt.encrypt(secretKey);
              let file_body = {
                "secret_key" : enc_secret_key,
                "fileUrl" : encodeURI(url),
                "publicKey" : solanaPublicAddress,
                "email" : userEmail,
              }
              setOpen(true);
              DECRYPT_FILE(file_body)
            .then(async ({ data }) => {
                  if (data.status == 200) {
                    let isFileReady = false;
                    setDecryptedFileData(data.data.payload)
                    if(title.toLowerCase().includes("recording")){
                      url = `data:audio/mp4;base64,${data.data.payload.file}`
                    }else{
                      url = `data:${data.data.payload.mimetype};base64,${data.data.payload.file}`
                      let docxFile = false
                      if(title.includes("docx") || title.includes("doc")){
                        const parts = url.split(';base64,');
                        const contentType = parts[0].split(':')[1];
                        const byteString = atob(parts[1]);
                        const arrayBuffer = new ArrayBuffer(byteString.length);
                        const uint8Array = new Uint8Array(arrayBuffer);
                        for (let i = 0; i < byteString.length; i++) {
                          uint8Array[i] = byteString.charCodeAt(i);
                        }
                       url = new Blob([arrayBuffer], { type: contentType });
                       mammoth.convertToHtml({ arrayBuffer: url })
                       .then(result => {url = result.value
                        //  setFileHtml(result.value);
                        docxFile = true
                         setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
                         setIsFileLoading(false)
                       })
                       .done();
                      }else{
                        docxFile = false
                        setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
                        setIsFileLoading(false)
                    }
                    
                   
                    }
                   
                
             
                  }
                 
                })
                .catch(function (error) {

                  console.log(error.response.data)
                }).finally(() => { 
                 
                });
   }
   const shareFile =(url, type)=> {
    const fileUrl = url; // Replace this with the URL of the file you want to share
    let shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(fileUrl)}`;
    if(type == "facebook"){
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(fileUrl)}`;
    }
    window.open(shareUrl, '_blank', 'width=800,height=600');
  }
  const deleteFile = async (fileData) => {

    if(secretKey && fileData && fileData.storage_address){
   
      var enc_secret_key = encrypt.encrypt(secretKey);
      let body = {
        "secret_key" : enc_secret_key,
        "email" : userEmail,
        "storage_public_key" : fileData.storage_address,
        "file_name" : fileData.file_name,
        "size": fileData.size
      }
          
      
      
      DELETE_FILE(body)
        .then(({ data }) => {
          setAlertType("success")
          setAlertMessage("File has been deleted successfully!")
          setAlertPopup(true)
            getStorageList(userEmail, userID);
        })
        .catch(function (error) {
          if(error?.response?.data?.message){
            setAlertType("error")
            setAlertMessage(error?.response?.data?.message)
            setAlertPopup(true)
          }
        });
      }
    }      
  
  // LJ attempt image open modal
  const handleOpen = (item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, isEncrypted ) => {

  let docxFile = false
  if(!isEncrypted){
  
   setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
    setOpen(true);
  }else{
  
    decryptFile( item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size )
  }
 
  };
  
  const handleClose = () => {
    setOpen(false);
    setModalContent({ title: '', imageUrl: '' })
  };
  
  function downloadFile(url, fileName) {
    axios.get(url, {
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, fileName);
    });
  }
  const onRightTrigger = () => {
    let filesArr = files
    let totalFiles = filesArr.length - 1;
    let currentFile = modalContent.fileIndex;
 
 if(currentFile < totalFiles){
   currentFile = currentFile + 1;
let item = filesArr[currentFile]
let url = "https://shdw-drive.genesysgo.net/"+item.storage_address+"/"+item.file_name
console.log("vaultsArr => ",vaultsArr)
console.log("totalFiles => ",totalFiles)
let vaultData = vaultsArr.find(vaultItem => vaultItem.vaultaddress === item.storage_address);
let isEncrypted = vaultData.isEncrypted;
console.log("vaultData =>",vaultData)
let title = item.file_name
let is_video;
let is_audio;
let isDocx;
let fileIndex = currentFile;
let storageAddr = item.storage_address;
let solanaAddr = solanaPublicAddress;
let date = moment(item.last_modified).format("DD-MM-YYYY");
let size = bytesToSize(item.size);
let docxFile = false;



   let image_types = ['png', 'jpg', 'jpeg', 'gif', 'heic', 'avif'];
   var fileExt = item.file_name.split('.').pop().toLowerCase();
  let is_image =  image_types.indexOf(fileExt);
  if(is_image >= 0){
  
    is_video = false
    is_audio = false
  }else if(item.file_name.includes("Recording") || item.file_name.includes("audio")){
    is_video = false
    is_audio = true
  }
  else if(fileExt == "mp4" || fileExt.toLowerCase() == "mov") {
    is_video = true
    is_audio = false
  }
  else if (fileExt == "mp3") {
    is_video = false
    is_audio = true
 
 
  }
  if(fileExt == "docx" || fileExt == "doc" || fileExt == "pdf" || fileExt == "ppt"|| fileExt == "pptx"){
    isDocx =  true;
  
   }
console.log("NEW DATA =>",{ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile })
if(!isEncrypted){
  setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });

}else{
  decryptFile( item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size )
}

 

  }
}
const onLeftTrigger = () => {
  let filesArr = files
  let totalFiles = filesArr.length - 1;
  let currentFile = modalContent.fileIndex;
  console.log("currentFile => ",currentFile)
  console.log("totalFiles => ",totalFiles)

if(currentFile > 0){
  currentFile = currentFile - 1;
let item = filesArr[currentFile]
let url = "https://shdw-drive.genesysgo.net/"+item.storage_address+"/"+item.file_name
let vaultData = vaultsArr.find(vaultItem => vaultItem.vaultaddress === item.storage_address);
let isEncrypted = vaultData.isEncrypted;
let title = item.file_name
let is_video;
let is_audio;
let isDocx;
let fileIndex = currentFile;
let storageAddr = item.storage_address;
let solanaAddr = solanaPublicAddress;
let date = moment(item.last_modified).format("DD-MM-YYYY");
let size = bytesToSize(item.size);
let docxFile = false;



 let image_types = ['png', 'jpg', 'jpeg', 'gif', 'heic', 'avif'];
 var fileExt = item.file_name.split('.').pop().toLowerCase();
let is_image =  image_types.indexOf(fileExt);
if(is_image >= 0){

  is_video = false
  is_audio = false
}else if(item.file_name.includes("Recording") || item.file_name.includes("audio")){
  is_video = false
  is_audio = true
}
else if(fileExt == "mp4" || fileExt.toLowerCase() == "mov") {
  is_video = true
  is_audio = false
}
else if (fileExt == "mp3") {
  is_video = false
  is_audio = true


}
if(fileExt == "docx" || fileExt == "doc" || fileExt == "pdf" || fileExt == "ppt"|| fileExt == "pptx"){
  isDocx =  true;

 }
console.log("NEW DATA =>",{ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile })
if(!isEncrypted){
  setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });

}else{
  decryptFile( item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size )
}

}
}
  return isLoading ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress sx={{color: '#FFFFFF'}}/>
    </div>
  ) : (
    <>
      {alertPopup && <AlertMessage alertDisplay={alertPopup} type={alertType} message={alertMessage} />}
      <TableContainer component={Paper} sx={{ backgroundColor: "#121212", }} className="tableHeiht">
      <Table stickyHeader sx={{ minWidth: 500, backgroundColor: "#121212" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Typography className="overViewText" sx={{ fontSize: "1rem", fontWeight: "500", fontFamily: "DM Sans" }}>
                Name
                <FilterListIcon
                  onClick={() => requestSort('file_name')} 
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    verticalAlign: "middle",
                    height: "18px",
                    width: "18px",
                    paddingBottom: "0.1rem",
                    paddingLeft: "0.2rem",
                  }}
                />
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography className="overViewText" sx={{ fontSize: "1rem", fontWeight: "500", fontFamily: "DM Sans" }}>
                Date
                <FilterListIcon
                  onClick={() => requestSort('last_modified')} 
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    verticalAlign: "middle",
                    height: "18px",
                    width: "18px",
                    paddingBottom: "0.1rem",
                    paddingLeft: "0.2rem",
                  }}
                />
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography className="overViewText" sx={{ fontSize: "1rem", fontWeight: "500", fontFamily: "DM Sans" }}>
                Size
                <FilterListIcon
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    verticalAlign: "middle",
                    height: "18px",
                    width: "18px",
                    paddingBottom: "0.1rem",
                    paddingLeft: "0.2rem",
                  }}
                />
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography className="overViewText" sx={{ fontSize: "1rem", fontWeight: "500", fontFamily: "DM Sans" }}>
                Location
                <FilterListIcon
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    verticalAlign: "middle",
                    height: "18px",
                    width: "18px",
                    paddingBottom: "0.1rem",
                    paddingLeft: "0.2rem",
                  }}
                />
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography className="overViewText" sx={{ fontSize: "1rem", fontWeight: "500", fontFamily: "DM Sans" }}>
                Actions
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((item, index) => {
             let vaultData = vaultsArr.find(vaultItem => vaultItem.vaultaddress === item.storage_address);
              let image_types = ['png', 'jpg', 'jpeg', 'gif', 'heic', 'avif'];
              var fileExt = item.file_name.split('.').pop().toLowerCase();
             let isImage =  image_types.indexOf(fileExt);
             let isDocx =  false;
             let isAudio = false;
             let isVideo = false;
            
            
             if(fileExt == "docx" || fileExt == "doc" || fileExt == "pdf" || fileExt == "ppt"|| fileExt == "pptx"){
              isDocx =  true;
            
             }
            
             if (fileExt == "mp4" || fileExt.toLowerCase() == "mov") {
              if(item.file_name.includes("Recording") || item.file_name.includes("audio")){
                isAudio = true;
            
              }else{
                isVideo = true;
            
              }
            
            }
            if(fileExt == "mp3") {
              isAudio = true;
            
            }
             const found = IMGS_ARR.find(e => e.name === fileExt.toLowerCase());
             let IMAGE_DISPLAY = null;
             if(found){
               IMAGE_DISPLAY=found.image
             }else{
               IMAGE_DISPLAY=DEFAULT_IMG.image
             }

            return(
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                <Grid item sx={{ display: "flex", alignItems: "center"}} 
                    onClick={() => {
                      handleOpen(item,"https://shdw-drive.genesysgo.net/"+item.storage_address+"/"+item.file_name, item.file_name,isImage,isAudio,isVideo,isDocx,fileExt, index,files,item.storage_address, solanaPublicAddress,moment(item.last_modified).format("DD-MM-YYYY"), bytesToSize(item.size), vaultData.isEncrypted)
                      }}>
                  <img src={vaultData.isEncrypted ? IMAGE_DISPLAY : isImage > -1 ? "https://shdw-drive.genesysgo.net/"+item.storage_address+"/"+item.file_name : IMAGE_DISPLAY} alt="nftTable" style={{ height: "30px", width: "30px" }} />
                  <Typography
                    className="overViewText"
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: "400",
                      fontFamily: "DM Sans",
                      marginLeft: "0.3rem",
                      color: "#FFFFFF",
                      paddingLeft: 1
                    }}
                  >
                    {item.file_name}
                  </Typography>
              
                </Grid>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  className="overViewText"
                  sx={{ fontSize: "0.8rem", fontWeight: "400", fontFamily: "DM Sans", paddingLeft: "0.3rem" }}
                >
                  {moment(item.last_modified).format("MMM D, YYYY")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  className="overViewText"
                  sx={{ fontSize: "0.8rem", fontWeight: "400", fontFamily: "DM Sans", paddingLeft: "0.3rem" }}
                >
                  {bytesToSize(item.size)}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                { vaultData.vaultcolor == "Pink" ? (
                <img
                src={pink_case}
                alt="sideMenuPersonalIcon"
                style={{ height: "24px", width: "24px" }}
              />
                ) : vaultData.vaultcolor == "Purple" ? (
                  <img
                  src={purple_case}
                  alt="sideMenuPersonalIcon"
                  style={{ height: "24px", width: "24px" }}
                />
                ) : (
                  <img
                  src={green_case}
                  alt="sideMenuPersonalIcon"
                  style={{ height: "24px", width: "24px" }}
                />

                )}
                 
                  <Typography
                    className="overViewText"
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: "400",
                      fontFamily: "DM Sans",
                      marginLeft: "0.3rem",
                      color: "#FFFFFF",
                    }}
                  >
                    {vaultData.vaultname}
                  </Typography>
                </Grid>
              </StyledTableCell>
              <StyledTableCell>
                <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  {/* <CreateIcon sx={{ height: "18px", width: "18px" }} />
                  <PushPinIcon sx={{ height: "18px", width: "18px" }} /> */}
                  <ShareIcon onClick={() => shareFile("https://shdw-drive.genesysgo.net/"+item.storage_address+"/"+item.file_name, "twitter")} sx={{ height: "20px", width: "20px" }} />
                   <FileDownloadIcon onClick={() => downloadFile("https://shdw-drive.genesysgo.net/"+item.storage_address+"/"+item.file_name, item.file_name)} sx={{ height: "20px", width: "20px" }} />
                  <DeleteIcon onClick={() => deleteFile(item)} sx={{ height: "20px", width: "20px" }} />
                </Grid>
              </StyledTableCell>
            </StyledTableRow>
          )})}
        </TableBody>
      </Table>
      
      {/* Rename file modal
      <Modal
          // open={open}
          // onClose={closeRenameModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
          <Box 
              sx={{ 
                  position: 'absolute', 
                  width: 400, 
                  bgcolor: '#282832', 
                  borderRadius: '8px',
                  boxShadow: 24, 
                  p: 4, 
                  top: '50%', 
                  left: '50%', 
                  transform: 'translate(-50%, -50%)',
              }}
          >
              <TextField
                  placeholder="file name"
                  variant="outlined"
                  fullWidth
                  sx={{
                    border: '0.5px solid #FFFFFF', 
                    borderRadius: '4px'
                  }}
              />
          </Box>
      </Modal> */}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="l"
        PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }} 
      >
     <DialogActions>
      <IconButton
        style={{ position: 'absolute', top: 5, right: 45, color: '#fff', zIndex: 1 }}
        onClick={handleMenuOpen}
      >
        <MenuIcon />
      </IconButton>

      <IconButton
        style={{ position: 'absolute', top: 5, right: 5, color: '#fff', zIndex: 1 }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>

      <IconButton
        style={{ position: 'absolute', top: 5, right: 90, color: '#fff', zIndex: 1 }}
      >
        <FileDownloadIcon />
      </IconButton>
      
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem style={{backgroundColor: "1E1E25"}}onClick={handleMenuClose}>Share File</MenuItem>
          <MenuItem onClick={handleMenuClose}>Download</MenuItem>
          <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
        </Menu>

        </DialogActions>
        <DialogTitle
          sx={{
            color: "#A79EE5",
            fontFamily: "DM Sans",
          }}
        >
          {modalContent.title}
        </DialogTitle>

        <DialogTitle
          sx={{
            color: "#FFFFFF",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          File Uploaded: {modalContent.date}
        </DialogTitle>

        <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", 
          overflow: "auto" 
        }}
        >
          <IconButton
       
            style={{
              position: 'absolute',
              top: '50%',
              left: 5,
              color: '#fff',
              transform: 'translateY(-50%)',
              backgroundColor: '#1E1E25',
              borderRadius: '50%',
              padding: 20,
              zIndex: 1
            }} 
            onClick={() => onLeftTrigger()}
          >
            <ArrowBackIcon  />
          </IconButton>

          {isFileLoading ? (
            <img src={modalContent.url} alt={modalContent.title}
            style={{ maxWidth: "100vh", maxHeight: "70vh", objectFit: "contain", borderRadius: '7px' }} />         
          ) : modalContent.isDocx ? (
            <>
              {!modalContent.docxFile ? (
                <DocViewer documents={[
                  { uri: modalContent.url }, // Remote file
                ]} pluginRenderers={DocViewerRenderers} />
              ) : (
                <iframe srcDoc={modalContent.url} width="100vh" height="70vh" objectFit="contain" />
              )}
            </>
          ) : modalContent.is_image >= 0 ? (
            <img 
                src={modalContent.url} 
                alt={modalContent.title}
                style={{ 
                    width: "100vh", 
                    height: "50vh", 
                    objectFit: "contain", 
                    borderRadius: '7px' 
                }} 
            />  
          ) : modalContent.is_video ? (
            <video controls style={{ maxWidth: "100%", height: "auto", objectFit: "contain" }}>
              <source src={modalContent.url} type={`video/${modalContent.fileExt}`} />
              Your browser does not support the video tag.
            </video>
          ) : modalContent.is_audio ? (
            <audio controls style={{ maxWidth: "100%", height: "auto" }}>
              <source src={modalContent.url} type={`audio/${modalContent.fileExt}`} />
              Your browser does not support the audio element.
            </audio>
          ) : null}


          <IconButton
            style={{ 
              position: 'absolute',
              top: '50%', 
              right: 5, 
              color: '#fff', 
              transform: 'translateY(-50%)', 
              backgroundColor: '#1E1E25',
              borderRadius: '50%',
              padding: 20,
              zIndex: 1 
            }} 
          >
            <ArrowForwardIcon onClick={() => onRightTrigger()} />
          </IconButton>
        </DialogContent>
      </Dialog>
    </TableContainer>
      </>


  );
}
