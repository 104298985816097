import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  IconButton,
  Typography,
  CircularProgress
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { JSEncrypt } from "jsencrypt";
import { GET_SHDW_USD_RATES } from '../services/services';
import { CREATE_VAULT, GET_PROFILE } from '../services/solana_services';
import AlertMessage from './alertComponents/AlertMessage';

export function CreateVaultModal({ isCreateVaultOpen, handleCreateVaultClose }) {
  const navigate = useNavigate();
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
  const [vaultName, setVaultName] = useState('');
  const [sliderVal, setSliderVal] = useState(1);
  const [vaultSize, setVaultSize] = useState("");
  const [actualVaultSize, setActualVaultSize] = useState("");
  const [vaultString, setVaultString] = useState("");
  const [vaultColor, setVaultColor] = useState("");
  const [isImmutable, setIsImmutable] = useState(false);
  const [isRestrictToken, setIsRestrictToken] = useState(false);
  const [solanaPublicAddress, setSolanaPublicAddress] = useState("")
  const [smallSolanaAddress, setShortSolanaPublicAddress] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [solanaBalance, setSolanaBalance] = useState(0)
  const [shadowBalance, setShadowBalance] = useState(0)
  const [userData, setUserData] = useState(null)
  const [loader, setLoader] = useState(false)
  const [dropdown, setDropdown] = useState(false)
  const [colorDropdown, setColorDropdown] = useState(false)
  const [secretKey, setSecretKey] = useState("")
  const [rates, setRates] = useState(null)
  const [infoModal, setInfoModal] = useState(false);
  const [immutableModal, setImmutableModal] = useState(false);
  const [tokenModal, setTokenModal] = useState(false);
  const [isEncrypted, setIsEncrypted] = useState();
  const [alertPopup, setAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  
  
 
  useEffect(() => {
    getData();
 
  }, []);
    const getData = async () => {
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload)
    setUserData(payload)
    setSolanaPublicAddress(payload.solanaaccount)
    setUserEmail(payload.email)

const secret_key_val = localStorage.getItem('solana_key');
setSecretKey(secret_key_val)
let body = {
  "id": payload._id,
  "email": payload.email
};
GET_SHDW_USD_RATES(body)
.then(({ data }) => {
    if(data.status == 200){
     let gb = "1GB"
     setRates(data?.data?.payload?.prices)

    }
})
.catch(function (error) {
  if(error?.response?.data?.message){
    setAlertType("error")
    setAlertMessage(error?.response?.data?.message)
    setAlertPopup(true)
}

 });

  }


const triggerCreateVault = async () => {
  if(!vaultName){
      setAlertType("error")
      setAlertMessage("Vault Name is missing. Please provide Vault Name and try again. Thanks")
      setAlertPopup(true)
  }else if(!vaultSize){
    setAlertType("error")
    setAlertMessage("Vault Size is missing. Please select Vault Size and try again. Thanks")
    setAlertPopup(true)
  }
  else{
    if(userData){
      setLoader(true)
      var enc_secret_key = encrypt.encrypt(secretKey);
          let body = {
            "id": userData._id,
            "secret_key":enc_secret_key,
            "storage_account_name": vaultName,
            "storage_size": actualVaultSize,
            "email" : userEmail,
            "vault_color": vaultColor,
            "isEncrypted" : isEncrypted == "on" ? true : false,
            "isImmutable" : isImmutable == "on" ? true : false,
            "taskSlug":"create_storage_vault",
            "isBackup" : false
         }
         console.log("Body",body)
         CREATE_VAULT(body)
.then(({ data }) => {
  setAlertType("success")
  setAlertMessage("Vault has been created successfully!")
  setAlertPopup(true)
let get_profile_body = {"email": userEmail, "name": userData.username, "typeOfLogin": userData.typeOfLogin}
GET_PROFILE(get_profile_body)
.then(({ data }) => {
  localStorage.removeItem("payload")
  let userData = data.data.payload.user_profile
  localStorage.setItem("payload", JSON.stringify(userData))
  setLoader(false)
 
  navigate("/");
  window.location.reload(true);
 
})
.catch(async function (error) {

})



})
.catch(function (error) {
  if(error?.response?.data?.message){
    setAlertType("error")
    setAlertMessage(error?.response?.data?.message)
    setAlertPopup(true)
}

 });
        //  navigation.navigate("CreatingVault", {
        //   body
        // })
      }else{
          setAlertType("error")
          setAlertMessage("Unauthorized!")
          setAlertPopup(true)
     }
  }

}

  return (
  <>
    <Modal
        open={isCreateVaultOpen}
        onClose={handleCreateVaultClose}
      >

        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            height: 550,
            bgcolor: '#282832',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            ontFamily: "DM Sans", 
            color: "#FFFFFF",
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', // Add a light shadow effect
            filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15))', // Add a glowing effect      
          }}
        >
  {alertPopup && <AlertMessage alertDisplay={alertPopup} type={alertType} message={alertMessage} />}

          <IconButton
            sx={{ position: 'absolute', top: 1, right: 1, color: '#FFFFFF' }}
            onClick={handleCreateVaultClose}
          >
            <CloseIcon />
          </IconButton>
          {/* Vault Name */}
          <TextField
            label="Vault Name"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              style: { color: '#FFFFFF', fontFamily: "DM Sans" },
            }}
            InputProps={{
              style: { color: '#FFFFFF', borderColor: '#949494', '&:focus': { borderColor: '#949494' } },
            }}
            onChange={(event) => setVaultName(event.target.value)}
            sx={{ marginBottom: 2 }}
          />

          {/* Vault Size */}
          <TextField
            label="Vault Size"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              style: { color: '#FFFFFF', fontFamily: "DM Sans" },
            }}
            InputProps={{
              style: { color: '#FFFFFF', borderColor: '#949494', '&:focus': { borderColor: '#949494' } },
            }}
            sx={{ marginBottom: 2 }}
            onChange={(event) => {
              setVaultSize(event.target.value); 
              setActualVaultSize(`${event.target.value}GB`);

            }}
          />

          {/* Change Vault Color */}
          <FormControl variant="outlined" fullWidth sx={{ marginBottom: 2, borderColor: '#949494' }}>
            <InputLabel
              sx={{
                fontFamily: 'DM Sans',
                color: '#FFFFFF',
                '&.Mui-focused': {
                  color: '#FFFFFF', // Set the desired color for focused state
                },
              }}
            >
              Change Vault Color
            </InputLabel>
            <Select
             onChange={(event) => setVaultColor(event.target.value)}
              label="Change Vault Color"
              sx={{
                '& .MuiMenuItem-root': {
                  color: '#FFFFFF',
                },
                '& .MuiMenuItem-root.Mui-selected': {
                  backgroundColor: '#949494',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#949494',
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#949494',
                },
              }}
            >
              <MenuItem value="Green">Green</MenuItem>
              <MenuItem value="Pink">Pink</MenuItem>
              <MenuItem value="Purple">Purple</MenuItem>
            </Select>
          </FormControl>


          {/* Immutable Vault */}
          <Grid container alignItems="center" sx={{ marginBottom: 2 }}>
            <Grid item>
              <Typography sx={{fontFamily: "DM Sans", color: "#FFFFFF"}}>Immutable Vault</Typography>
            </Grid>
            <Grid item>
              <Switch color="primary"
                   onChange={(event) => setIsEncrypted(event.target.value)}
                    />
            </Grid>
          </Grid>

          {/* Encrypted Vault */}
          <Grid container alignItems="center" sx={{ marginBottom: 2 }}>
            <Grid item>
              <Typography sx={{fontFamily: "DM Sans", color: "#FFFFFF"}}>Encrypted Vault</Typography>
            </Grid>
            <Grid item>
              <Switch color="primary"
                onChange={(event) => setIsImmutable(event.target.value)}
                 />
            </Grid>
          </Grid>

            {/* Encrypted Vault */}
          <Grid container alignItems="center" sx={{ marginBottom: 2 }}>
            <Grid item>
              <Typography sx={{fontFamily: "DM Sans", color: "#FFFFFF", fontWeight: 'bold'}}>Estimated Cost</Typography>
            </Grid>
          </Grid>

          {/* Current SHDW price */}
          <Grid container alignItems="center" sx={{ marginBottom: 2 }}>
            <Grid item>
              <Typography sx={{ fontFamily: "DM Sans", color: "#FFFFFF", fontWeight: '100' }}>
                0.25 SHDW or 1GB of data is currently [<span style={{ color: "#FFA2C0" }}>$0.024</span>]
              </Typography>
            </Grid>
          </Grid>

          {/* Your storage price */}
          <Grid container alignItems="center" sx={{ marginBottom: 2 }}>
            <Grid item>
              <Typography sx={{ fontFamily: "DM Sans", color: "#FFFFFF", fontWeight: '100' }}>
                Your vault will cost you: [<span style={{ color: "#FFA2C0" }}>${rates && vaultSize ? rates['1GB'] * vaultSize : "0.00"}</span>]
              </Typography>
            </Grid>
          </Grid>


          {/* Buttons */}
          <Grid container justifyContent="flex-end">
            <Button variant="contained" onClick={handleCreateVaultClose} sx={{ marginRight: 2, backgroundColor: "#6C5DD3"}}>
              Cancel
            </Button>
            <Button variant="contained" sx={{ backgroundColor: "#6C5DD3" }}
            onClick={() => triggerCreateVault()}>
              {loader ? (
                <CircularProgress color="secondary" />
              ): (
                "Create Vault"
              )}
          
            </Button>
          </Grid>
        </Box>
      </Modal>
  </>
    

  );
}
