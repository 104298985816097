import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import imageIcon from "../assets/imageIcon.png";
import imagePNG from "../assets/imagePNG.png";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import PushPinIcon from "@mui/icons-material/PushPin";
import { pinnedDataSection } from "../constants/appConstants";
import { GET_FILES, GET_FILES_PIN, GET_STORAGE_ACCOUNT_INFO } from "../services/services";
import moment from "moment";
import { DEFAULT_IMG, IMGS_ARR } from "../constants/ImagesExts";

function PinnedData({ vaultIndex, refreshing }) {
  const { PINNEDHEADING, HIDETEXT, NAMEEXT, DATEEXT } = pinnedDataSection;
  const [pinnedFiles, setPinnedFiles] = useState([]);
  const [vaultsArr, setVaultsArr] = useState([]);
  const [files, setFiles] = useState([]);
  const [allFilesDetailedArr, setAllFilesDetailedArr] = useState([]);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (refreshing) {
      getUserData();
    }
  }, [refreshing]);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const str_payload = localStorage.getItem("payload");
    let payload = JSON.parse(str_payload);
    let user_email = payload.email;
    let user_id = payload._id;
    let vaults_array = payload.vaults;
    setVaultsArr(vaults_array);
    getPinnedList(vaults_array[vaultIndex].vaultaddress, payload.email);
  };

  const getPinnedList = async (key, user_email) => {
    let body = {
      email: user_email,
    };
    GET_FILES_PIN(body)
      .then(({ data }) => {
        setAllFilesDetailedArr(data.data.payload);
        getStorageList(key, user_email, data.data.payload);
      })
      .catch(function (error) {});
  };

  const getStorageList = async (key, user_email, pinnedData) => {
    let body = {
      storage_public_key: key,
      email: user_email,
    };
    GET_FILES(body)
      .then(({ data }) => {
        let emptyMessage = "";
        if (data.status == 200) {
          let arr = [];

          arr = data.data.payload.files;
          emptyMessage = "Vault is empty…";
          const compare = (a, b) => {
            if (a.file_name < b.file_name) {
              return -1;
            }
            if (a.last_nom > b.last_nom) {
              return 1;
            }
            return 0;
          };
          if (arr.length > 0) {
            arr = arr.sort((a, b) => b.last_modified.localeCompare(a.last_modified));
            emptyMessage = "";
          }
          let pinnedArr = [];

          var result = pinnedData.filter(obj => {
            return obj.vaultAddress === key;
          });
          if (arr.length > 0) {
            if (result.length > 0) {
              if (result[0].files.length > 0) {
                arr.map((item, index) => {
                  var file_obj = result[0].files.filter(obj => {
                    return obj.name === item.file_name;
                  });

                  if (file_obj.length > 0) {
                    if (file_obj[0].isFavorite) {
                      pinnedArr.push(item);
                    }
                  }
                });
              }
            }
          }

          // setPinnedFiles(pinnedArr);
          setFiles(arr);
        } else {
          alert(data.message);
        }
      })
      .catch(function (error) {});
  };

  const toggleHide = () => {
    setIsHidden(!isHidden);
  };

  return (
    <>
      <Grid
        className="pinnedDataHeading"
        item
        xs={12}
        sx={{
          marginBottom: "0.8rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            color: " #FFFFFF",
            fontSize: "1rem",
            fontWeight: "700",
            fontFamily: "DM Sans",
          }}
        >
          {PINNEDHEADING}
        </Typography>
        <Typography
          sx={{
            color: " #FFFFFF",
            fontSize: "1rem",
            fontWeight: "400",
            fontFamily: "DM Sans",
            cursor: "pointer",
          }}
          onClick={toggleHide}
        >
          {isHidden ? "Show" : HIDETEXT}
          <KeyboardArrowUpOutlinedIcon
            sx={{
              display: "inline-flex",
              verticalAlign: "middle",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "0.2rem",
              color: "#A79EE5",
              transform: isHidden ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </Typography>
      </Grid>
      {!isHidden && pinnedFiles.length > 0 ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "168px",
          }}
        >
          {pinnedFiles.slice(0, 3).map((item, index) => {
            let image_types = ["png", "jpg", "jpeg", "gif", "heic"];
            var fileExt = item.file_name.split(".").pop().toLowerCase();
            let isImage = image_types.indexOf(fileExt);
            let isDocx = false;
            let isPdf = false;
            let isPPT = false;
            let isAudio = false;
            let isVideo = false;

            if (fileExt === "pdf") {
              isPdf = true;
            }
            if (fileExt === "docx" || fileExt === "doc") {
              isDocx = true;
            }
            if (fileExt === "ppt") {
              isPPT = true;
            }
            if (fileExt === "mp4" || fileExt.toLowerCase() === "mov") {
              if (item.file_name.includes("Recording") || item.file_name.includes("audio")) {
                isAudio = true;
              } else {
                isVideo = true;
              }
            }
            if (fileExt === "mp3") {
              isAudio = true;
            }
            var fileExt = item.file_name.split(".").pop();
            const found = IMGS_ARR.find(e => e.name === fileExt.toLowerCase());
            let IMAGE_DISPLAY = null;
            if (found) {
              IMAGE_DISPLAY = found.image;
            } else {
              IMAGE_DISPLAY = DEFAULT_IMG.image;
            }

            return (
              <Grid
                key={index}
                className="pinnedBoxWidth"
                item
                xs={2.75}
                sx={{
                  height: "128px",
                }}
              >
                <Box
                  sx={{
                    height: "125px",
                    background: "#1E1E25",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "1rem",
                      paddingTop: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          vaultsArr[vaultIndex].isEncrypted
                            ? IMAGE_DISPLAY
                            : isImage > -1
                            ? "https://shdw-drive.genesysgo.net/" + vaultsArr[vaultIndex].vaultaddress + "/" + item.file_name
                            : IMAGE_DISPLAY
                        }
                        alt="synxRightBarAllFiles"
                        style={{
                          height: "100px",
                          width: "100%",
                          objectFit: "cover",
                          alignContent: "contain",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        height: "30px",
                        width: "30px",
                        background: "rgba(118, 118, 128, 0.24)",
                        borderRadius: "7.15909px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PushPinIcon sx={{ height: "16px", width: "16px" }} />
                    </Box>
                  </Box>
                </Box>
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid rgba(118, 118, 128, 0.24)",
                    borderBottomLeftRadius: "7px",
                    borderBottomRightRadius: "7px",
                  }}
                >
                  <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={IMAGE_DISPLAY}
                      alt="synxRightBarAllFiles"
                      style={{ height: "24px", width: "20px", marginLeft: "0.7rem" }}
                    />
                    <Typography
                      className="buttonBoxAllfilesText"
                      sx={{
                        color: " #FFFFFF",
                        fontSize: "0.8rem",
                        fontWeight: "500",
                        fontFamily: "DM Sans",
                        marginLeft: "0.7rem",
                      }}
                    >
                      {item.file_name.substring(0, 10)}
                      <span
                        className="buttonBoxAllfilesText"
                        style={{
                          color: "rgba(255, 255, 255, 0.6)",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                          fontFamily: "DM Sans",
                          marginLeft: "0.7rem",
                        }}
                      >
                        {moment(item.last_modified).format("MMM D, YYYY")}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <MoreVertRoundedIcon
                      className="threeDotsIcon"
                      sx={{
                        height: "24px",
                        width: "24px",
                        color: "#DADADA",
                        marginLeft: "1rem",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ): (
        <Typography
        sx={{
          color: " #FFFFFF",
          fontSize: "1.4rem",
          fontWeight: "500",
          fontFamily: "DM Sans",
          alignContent: "center"
        }}
      >
       No pinned files in this vault 📍
      </Typography>
      )}
    </>
  );
}

export default PinnedData;
