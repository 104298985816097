import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { Typography, Grid } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1E1E1E",
    color: "#949494",
    fontSize: "1rem",
    borderBottom: "1px solid #949494",
    paddingLeft: "1rem",
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.8rem",
    backgroundColor: "#121212",
    color: "#949494",
    borderBottom: "1px solid #949494",
    paddingLeft: "1rem",
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables({ refreshing })  {
  const navigate = useNavigate();
 
  return (
    <>
      <TableContainer component={Paper} sx={{ backgroundColor: "#121212", width: "100%" }} className="tableHeiht">
      <Table stickyHeader sx={{ minWidth: 500, backgroundColor: "#121212" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Typography className="overViewText" sx={{ fontSize: "1rem", fontWeight: "500", fontFamily: "DM Sans" }}>
                Ambassador Username
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography className="overViewText" sx={{ fontSize: "1rem", fontWeight: "500", fontFamily: "DM Sans" }}>
                Total Referrals
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography className="overViewText" sx={{ fontSize: "1rem", fontWeight: "500", fontFamily: "DM Sans" }}>
                Total Earnings
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
            <StyledTableRow >

              <StyledTableCell component="th" scope="row">
              </StyledTableCell>

              <StyledTableCell>
              </StyledTableCell>

              <StyledTableCell>
              </StyledTableCell>

              <StyledTableCell>
              </StyledTableCell>

              <StyledTableCell>
              </StyledTableCell>

            </StyledTableRow>
     
        </TableBody>
      </Table>
    </TableContainer>
      </>


  );
}
