import React, {useState,useEffect}from "react";
import { Grid } from "@mui/material";
import { GET_PROFILE } from "../../services/solana_services";
import { GET_ALL_STORAGE_ACCOUNT_INFO, GET_All_FILES, GET_FILES } from "../../services/services";
import { JSEncrypt } from "jsencrypt";
import TextSectionRightBar1 from "../../components/textSectionRightBar/textSectionRightBar1";

function PieChartjs({ refreshPage}) {
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
  const [storageUsed, setStorageUsed] = useState(0)
    const [totalStorage, setTotalStorage] = useState(0)
    const [documentSize, setDocumentSize] = useState("0 B")
    const [mediaSize, setMediaSize] = useState("0 B")
    const [otherSize, setOtherSize] = useState("0 B")
    const [unknownSize, setUnknownSize] = useState("0 B")
    
  useEffect(() => {
    fetchBalances()
  }, []); 
  useEffect(() => {
    if (refreshPage) {
      fetchBalances()
    }
  }, [refreshPage]);
  const fetchBalances = async () => {
     
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload)
    const secret = localStorage.getItem("solana_key")
    var enc_secret_key = encrypt.encrypt(secret);
    const str_currentVault = localStorage.getItem('currentVault');
    let CURRENT_VAULT_INDEX = 0
    if(str_currentVault){
      CURRENT_VAULT_INDEX = parseInt(str_currentVault)
    }
let get_profile_body = { 
  "email": payload.email,
   "name": payload.username,
    "secret_key": enc_secret_key,
     "typeOfLogin": payload.typeOfLogin
     }
GET_PROFILE(get_profile_body)
.then(({ data }) => {
    let vaults_array = data.data.payload.user_profile.vaults;
    if (vaults_array.length > 0) {
      // getStorageDetails(payload.email, payload._id);
      getVaults(payload.email, payload._id,secret, CURRENT_VAULT_INDEX);
      getStorageList(data.data.payload.user_profile.vaults[CURRENT_VAULT_INDEX].vaultaddress, data.data.payload.user_profile.email);
    }
 
})
.catch(async function (error) {

})
  }
  const formatFileSize = (bytes,decimalPoint) => {
      if(bytes == 0) return '0 Bytes';
      var k = 1000,
          dm = decimalPoint || 2,
          sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
   }
   const formatFileSizeWithoutUNIT = (bytes,decimalPoint) => {
    if(bytes == 0) return '0';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
 }
 
      const CalculateFileSize = (arr) => {
  const extensions = getExtensions(arr);
  const res = getSizesByExtensions(arr, extensions);
  return res;
}
const getExtensions = (arr) => {
  const ext = arr.map(datum => {
    // return datum.file_name.split(".")[1] ? datum.file_name.split(".")[1] : "others"
    return datum.file_name.split(".").pop() ? datum.file_name.toLocaleLowerCase().split(".").pop() : "others"
  
  })
  const uniqueExt = [...new Set(ext)]
  return uniqueExt;
}
const getSize = (arr, type) => {
  let size = 0;
  if (type === "others") {
    size = arr.filter(datum => {
      if (!datum.file_name.toLocaleLowerCase().split(".").pop()) {
        return datum
      }
    }).reduce((acc, obj) => {
      return acc + obj.size;
    }, 0)
  } else {
    size = arr.filter(datum => {
      if (datum.file_name.toLocaleLowerCase().split(".").pop() === type) {
        return datum
      }
    }).reduce((acc, obj) => {
     
      return acc + obj.size;
    }, 0)
  }
  return size;
}
const getSizesByExtensions = (arr, exts) => {
  const finalArray = [];
  for (let i = 0; i < exts.length; i++) {
    const obj = {};
    obj.type = exts[i];
    obj.size = getSize(arr, exts[i])
    finalArray.push(obj);
  }

  return finalArray;
}
const bytesToSize = (bytes) => {
  var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 B';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
const filter_files_sizes = (document_types, data) => {

  var filteredArrays = data.filter(function (item) {
    let lower = item.type.toLocaleLowerCase();
    return document_types.indexOf(lower) > -1;
  });
  let size = 0;
  if (filteredArrays?.length > 0) {
    size = filteredArrays.reduce((accumulator, current) => accumulator + current.size, 0);
  }
  let sizeChange = bytesToSize(size)
  return sizeChange;
}
const getVaults = async (user_email, user_id,str_solana_keys, key) => {
   
  var enc_secret_key = encrypt.encrypt(str_solana_keys);
  let body = {
    "id": user_id,
    "secret_key": enc_secret_key,
    "email": user_email,
  };
  GET_ALL_STORAGE_ACCOUNT_INFO(body)
    .then(({ data }) => {
      if (data.data.payload) {
       
       setStorageUsed(data.data.payload.valutsInfo[key].storageUsed)
       setTotalStorage(data.data.payload.valutsInfo[key].vaultSize)
      }
      else {
      
      }
    })
    .catch(function (error) {
    
    });

}
   const getStorageDetails = async (user_email, user_id) => {
      const str_solana_keys = localStorage.getItem('solana_key');
      const secret = str_solana_keys;
      var enc_secret_key = encrypt.encrypt(secret);
     
      let body = {
        "id": user_id,
        "secret_key": enc_secret_key,
        "email": user_email,
      };
    
      GET_ALL_STORAGE_ACCOUNT_INFO(body)
        .then(({ data }) => {
          if (data.data.payload) {
            let storage_u = 0
            let storage_t = 0
            if (data.data.payload.totalVaultStorageUsed != 0) {
              storage_u = (data.data.payload.totalVaultStorageUsed / 1000).toFixed(2);
            }
            if (data.data.payload.totalVaultStorage != 0) {
              storage_t = (data.data.payload.totalVaultStorage / 1000).toFixed(2);
            }
            setStorageUsed(data.data.payload.totalVaultStorageUsed)
            setTotalStorage(data.data.payload.totalVaultStorage)
          }
          else {
            alert(data.message)
          }
        })
        .catch(function (error) {
       
        });
  
    }
    const getStorageList = async (key, user_email) => {
    
      let body = {
        "storage_public_key": key,
        "email" : user_email,
    };
  
      GET_FILES(body)
        .then(({ data }) => {
          if (data.status == 200) {
            let payload = data.data.payload;
            let files_arr = payload.files;
            let items = [];
            let documents = "0 B"
            let media = "0 B"
            let others = "0 B"
            if (files_arr.length > 0) {
           
              let dataFiles = files_arr.sort((a, b) => b.last_modified.localeCompare(a.last_modified));
             
              items = dataFiles.slice(0, 8)
              let files_by_extension = CalculateFileSize(files_arr)
              var document_types = ["pdf", "psd", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "html", "txt"]
              var media_types = ["aac", "cda", "mp3", "mp4", "mov", "wav", "webm", "webp", "mpeg", "ogv", "3gp", "avif", "bmp", "gif", "ico", "jpeg", "jpg", "png","heic","HEIC"]
              var other_types = ["7z", "arc", "bz", "bz2", "gz", "jar", "rar", "tar", "zip","ipa"]
              documents = filter_files_sizes(document_types, files_by_extension)
              media = filter_files_sizes(media_types, files_by_extension)
              others = filter_files_sizes(other_types, files_by_extension)

  
            }
           
            setDocumentSize(documents)
            setMediaSize(media)
            setOtherSize(others)

         

          }
        })
        .catch(async function (error) {

         
        });
  
    }
    const convertFileSizeToBytes = (fileSize) => {
      // convert value to lowercase and trim any whitespace
      fileSize = fileSize.toLowerCase().trim();
    
      // extract numeric value and unit of measurement from string
      let numericValue = parseFloat(fileSize);
      let unitOfMeasurement = fileSize.match(/[a-z]+/i)[0];
    
      // calculate conversion factor based on unit of measurement
      let conversionFactor = 1;
    
      switch (unitOfMeasurement) {
        case "kb": conversionFactor = 1024; break;
        case "mb": conversionFactor = 1024 * 1024; break;
        case "gb": conversionFactor = 1024 * 1024 * 1024; break;
        case "tb": conversionFactor = 1024 * 1024 * 1024 * 1024; break;
        default: break;
      }
    
      // convert file size to bytes
      let fileSizeInBytes = numericValue * conversionFactor; 
    
      // return the size in bytes
      return fileSizeInBytes;
    }
  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        justifyContent: "center",
        height: "max-content",
        marginBottom: "0.5rem",
      }}
    >
      <Grid
        item
        xs={11}
        sx={{
          backgroundColor: "#282832",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
         <TextSectionRightBar1
            docsValue={documentSize}
            mediaValue={mediaSize}
            othersValue={otherSize}
            unknownValue={unknownSize}
            availStorage={formatFileSize(storageUsed,1)}
            gbBold="GB"
            totalStorage={formatFileSize(totalStorage,1)}
            valUtilized={`${parseFloat((storageUsed/totalStorage) * 100).toFixed(1)}%`}
            docPieValue={(convertFileSizeToBytes(documentSize)/totalStorage) * 100}
            picPieValue={(convertFileSizeToBytes(mediaSize)/totalStorage) * 100}
            vidPieValue={(convertFileSizeToBytes(otherSize)/totalStorage) * 100}
            otherPieValue={(convertFileSizeToBytes(unknownSize)/totalStorage) * 100}
          />
       
      </Grid>
    </Grid>
  );
}

export default PieChartjs;
