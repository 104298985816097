import React from "react";

import { Grid, Typography, Box } from "@mui/material";

import PieChartIcon from "@mui/icons-material/PieChart";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";

import NFTcollections from "../../assets/NFTcollections.png";
import NFTcollectionsColorChange from "../../assets/NFTCollectionscolorchange.png";
import { dashBoardLeftSideBar } from "../../constants/appConstants";
function TextSectionLeftBar1({
  activeDashBoard,
  activeAllFiles,
  ActiveDashBoard,
  ActiveFiles,
  activeCollectibles,
  Collectibles,
  activeLeaderBoard,
  LeaderBoard,
  activeProfile,
  Profile,
}) {
  const {
    SYNXHEADING,
    DASHBOARDTEXT,
    ALLFILESTEXT,
    DIGITALCOLLECTIBLES,
    REWARDSHUB,
    PROFILE,
  } = dashBoardLeftSideBar;
  return (
    <Grid item xs={12}>
      <Box
        className="boxHeight"
        sx={{
          height: "2rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography className="buttonText" variant="synxSectionH1">
          {SYNXHEADING}
        </Typography>
      </Box>
      <Box
        className="boxHeight"
        onClick={ActiveDashBoard}
        sx={{
          background: activeDashBoard
            ? "linear-gradient(0deg, rgba(108, 93, 211, 0.08), rgba(108, 93, 211, 0.08)), #1E1E25;"
            : "",

          height: "2rem",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          className="buttonText"
          variant="synxBtnSection"
          sx={{
            color: activeDashBoard ? "#A79EE5" : "",
            display: "flex",   // added this
            alignItems: "center",  // added this
          }}
        >
          <PieChartIcon
            className="buttonIcon"
            sx={{
              color: activeDashBoard ? "#A79EE5" : "#DADADA",
              display: "inline-flex",
              verticalAlign: "middle",
              fontSize: "1rem",
              fontWeight: "500",
              marginRight: "0.3rem",
            }}
          />
          {DASHBOARDTEXT}
        </Typography>
      </Box>
      <Box
        className="boxHeight"
        onClick={ActiveFiles}
        sx={{
          background: activeAllFiles
            ? "linear-gradient(0deg, rgba(108, 93, 211, 0.08), rgba(108, 93, 211, 0.08)), #1E1E25"
            : "",
          height: "2rem",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          className="buttonText"
          variant="synxBtnSection"
          sx={{
            color: activeAllFiles ? "#A79EE5" : "",
            display: "flex",   // added this
            alignItems: "center",  // added this
          }}
        >
          <FolderRoundedIcon
            className="buttonIcon"
            sx={{
              color: activeAllFiles ? "#A79EE5" : "#DADADA",
              display: "inline-flex",
              verticalAlign: "middle",
              fontSize: "1rem",
              fontWeight: "500",
              marginRight: "0.3rem",
            }}
          />
          {ALLFILESTEXT}
        </Typography>
      </Box>
     
      <Box
        className="boxHeight"
        onClick={Collectibles}
        sx={{
          background: activeCollectibles
            ? "linear-gradient(0deg, rgba(108, 93, 211, 0.08), rgba(108, 93, 211, 0.08)), #1E1E25"
            : "",
          height: "2rem",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          className="buttonText"
          variant="imageIconsTypography"
          sx={{
            color: activeCollectibles ? "#A79EE5" : "",
            display: "flex",   // added this
            alignItems: "center",  // added this
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {activeCollectibles ? (
              <img
                className="buttonImg"
                src={NFTcollectionsColorChange}
                alt="NFTcollectionsColorChange-Logo"
                style={{
                  height: "15px",
                  width: "15px",
                  marginRight: "0.3rem",
                  display: "inline-flex",
                  color: activeCollectibles ? "#A79EE5" : "#DADADA",
                }}
              />
            ) : (
              <img
                className="buttonImg"
                src={NFTcollections}
                alt="NFTcollections-Logo"
                style={{
                  height: "15px",
                  width: "15px",
                  marginRight: "0.3rem",
                  display: "inline-flex",
                  color: activeCollectibles ? "#A79EE5" : "#DADADA",
                }}
              />
            )}
          </Box>
          {DIGITALCOLLECTIBLES}
        </Typography>
      </Box>
      <Box
        onClick={LeaderBoard}
        className="boxHeight"
        sx={{
          background: activeLeaderBoard
            ? "linear-gradient(0deg, rgba(108, 93, 211, 0.08), rgba(108, 93, 211, 0.08)), #1E1E25"
            : "",
          height: "2rem",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          className="buttonText"
          variant="synxBtnSection"
          sx={{
            color: activeLeaderBoard ? "#A79EE5" : "",
          }}
        >
          <EmojiEventsRoundedIcon
            className="buttonIcon"
            sx={{
              color: activeLeaderBoard ? "#A79EE5" : "#DADADA",
              display: "inline-flex",
              verticalAlign: "middle",
              fontSize: "1rem",
              fontWeight: "500",
              marginRight: "0.3rem",
            }}
          />
          {REWARDSHUB}
        </Typography>
      </Box>
      <Box
        className="boxHeight"
        onClick={Profile}
        sx={{
          background: activeProfile
            ? "linear-gradient(0deg, rgba(108, 93, 211, 0.08), rgba(108, 93, 211, 0.08)), #1E1E25"
            : "",
          height: "2rem",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          className="buttonText"
          variant="synxBtnSection"
          sx={{
            color: activeProfile ? "#A79EE5" : "",
            display: "flex",   // added this
            alignItems: "center",  // added this
          }}
        >
          <AccountCircleRoundedIcon
            className="buttonIcon"
            sx={{
              color: activeProfile ? "#A79EE5" : "#DADADA",

              display: "inline-flex",
              verticalAlign: "middle",
              fontSize: "1rem",
              fontWeight: "500",
              marginRight: "0.3rem",
            }}
          />
          {PROFILE}
        </Typography>
      </Box>
    </Grid>
  );
}

export default TextSectionLeftBar1;
