import React, {useState, useEffect} from "react";
import { Grid, TextField, Button, Switch, Typography } from "@mui/material";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import {nameValidator} from "../helpers/nameValidator.js";
import {usernameValidator} from "../helpers/usernameValidator";
import {emailValidator} from "../helpers/emailValidator";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import TwitterIcon from '@mui/icons-material/Twitter';
import { GET_PROFILE } from "../services/solana_services";
import { UPDATE_PROFILE } from "../services/services";
import AlertMessage from "../components/alertComponents/AlertMessage";

function Profile() {
  const navigate = useNavigate();
  const [userID, setUserID] = useState("")
  const [userEmail, setUserEmail] = useState({ value: '', error: '' })
  const [loader, setLoader] = useState(false)
  const [firstName, setFirstName] = useState({ value: '', error: '' })
  const [lastName, setLastName] = useState({ value: '', error: '' })
  const [userName, setUserName] = useState({ value: '', error: '' })
  const [phoneNumber, setPhoneNumber] = useState({ value: '', error: '' })
  const [walletAddress, setWalletAddress] = useState({ value: '', error: '' })
  const [userData, setUserData] = useState(null)
  const [inviteLinkToShare, setInvitelinkToShare] = React.useState('');
  const [alertPopup, setAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  function logout() {
    localStorage.clear();
    navigate("/");
    window.location.reload(true);
  }
  
    useEffect(() => {
      document.title = 'Synx | Profile 👤';
      getUserData();
    }, []); 

  const getUserData = async () => {

    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload)

    setUserData(payload)
  let get_profile_body = {"email": payload.email, "name": payload.username, "typeOfLogin": payload.typeOfLogin}
  GET_PROFILE(get_profile_body)
  .then(({ data }) => {
  let userData = data.data.payload.user_profile
           setUserEmail({ value: userData.email, error: '' })
    setUserID(userData._id)
    setFirstName({ value: userData.firstname, error: '' })
    setLastName({ value: userData.lastname, error: '' })
    setUserName({ value: userData.username, error: '' })
   
    setPhoneNumber({ value: userData.phonenumber, error: '' })
    setWalletAddress({ value: userData.wlwallet, error: '' })
    

  
  })
  .catch(async function (error) {
   
  })
  }

  const [darkMode, setDarkMode] = React.useState(false);

    const handleDarkModeToggle = () => {
          setDarkMode(!darkMode);
    }
    const onUpdatePressed = () => {
      const nameError = nameValidator(firstName.value)
      const lnameError = nameValidator(lastName.value)
      const usernameError = usernameValidator(userName.value)
      const emailError = emailValidator(userEmail.value)
      if (emailError  || nameError || lnameError || usernameError) {
        setFirstName({ ...firstName, error: nameError })
        setLastName({ ...lastName, error: lnameError })
        setUserName({ ...userName, error: usernameError })
        setUserEmail({ ...userEmail, error: emailError })
  
        return
      }else{
        if(loader){
                return;
              }
              else{
                let fnames=firstName.value
                let lnames=lastName.value
                let usernames=userName.value
                let emails=userEmail.value
                let phones =phoneNumber.value
                let giveawayWallet =walletAddress.value
              setLoader(true)
          
              let body = {
                "id": userID,
                "email": emails,
                "firstname": fnames,
                "lastname": lnames,
                "username": usernames,
                "phonenumber": phones,
                "wlwallet" : giveawayWallet
            };
          
            UPDATE_PROFILE(body)
            .then(({ data }) => {
                if(data.status == 200){
                 userData.firstname = fnames;
                 userData.lastname = lnames;
                 userData.phonenumber = phones;
                 userData.username = usernames;
                 userData.wlwallet = giveawayWallet;
                 localStorage.removeItem("payload")
                 setAlertType("success")
                 setAlertMessage("Profile updated successfully!")
                 setAlertPopup(true)
                  localStorage.setItem("payload", JSON.stringify(userData))
                 
                }
            })
            .catch(function (error) {
              if(error?.response?.data?.message){
                setAlertType("error")
                setAlertMessage(error?.response?.data?.message)
                setAlertPopup(true)
            }
             })
            .finally(() => { setLoader(false) });
            }
      }
   
    }
    return (
    <ThemeProvider theme={theme}>
        {alertPopup && <AlertMessage alertDisplay={alertPopup} type={alertType} message={alertMessage} />}
      <Grid
        container
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={1.7}
          sx={{
            backgroundColor: "primary.light",
          }}
        >
          <DashboardSideBar />
        </Grid>
        
        {/* Form personal details update */}
        <Grid
          item
          xs={10}
          sx={{
            padding: theme.spacing(3),
            mx: 2,
          }}
        >
        <Grid
          item
          xs={3.5}
          className="rightBarAllfilesMargin"
          sx={{
            paddingRight: "2rem",
            display: "flex",
            justifyContent: "flex",
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
        </Grid>

        {/* Profile Heading */}
        <Grid
        item
        xs={12}
        sx={{
          marginBottom: "2rem",
          marginTop: "5rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            color: " #FFFFFF",
            fontSize: "1rem",
            fontWeight: "700",
            fontFamily: "DM Sans",
          }}
        >
          Personal Details
            </Typography>
        </Grid>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="First name"
                  defaultValue={firstName.value}
                  value={firstName.value}
                  onChange={(e) => setFirstName({value:e.target.value,error:""})}
                  InputLabelProps={{
                    style: { color: "#ffffff" },
                  }}
                  InputProps={{
                    style: { color: "#ffffff" },
                    disableUnderline: true,
                  }}
                  variant="outlined"
                  inputProps={{ style: { borderColor: 'white', color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Last name"
                  defaultValue={lastName.value}
                  value={lastName.value}
                  onChange={(e) => setLastName({value:e.target.value,error:""})}
                  InputLabelProps={{
                    style: { color: "#ffffff" },
                  }}
                  InputProps={{
                    style: { color: "#ffffff" },
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Username"
                  defaultValue={userName.value}
                  value={userName.value}
                  onChange={(e) => setUserName({value:e.target.value,error:""})}
                  InputLabelProps={{
                    style: { color: "#ffffff" },
                  }}
                  InputProps={{
                    style: { color: "#ffffff" },
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  defaultValue={userEmail.value}
                  value={userEmail.value}
                  onChange={(e) => setUserEmail({value:e.target.value,error:""})}
                  type="email"
                  InputLabelProps={{
                    style: { color: "#ffffff" },
                  }}
                  InputProps={{
                    style: { color: "#ffffff" },
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone number"
                  defaultValue={phoneNumber.value}
                  value={phoneNumber.value}
                  onChange={(e) => setPhoneNumber({value:e.target.value,error:""})}
                  type={"default"}
                  InputLabelProps={{
                    style: { color: "#ffffff" },
                  }}
                  InputProps={{
                    style: { color: "#ffffff" },
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Giveaway Wallet"
                  placeholder="Your Giveaway Wallet"
                  defaultValue={walletAddress.value}
                  value={walletAddress.value}
                  onChange={(e) => setWalletAddress({value:e.target.value,error:""})}
                  type={"default"}
                  InputLabelProps={{
                    style: { color: "#ffffff" },
                  }}
                  InputProps={{
                    style: { color: "#ffffff" },
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button sx={{backgroundColor: '#6C5DD3', color: '#FFFFFF', textTransform: 'capitalize'}} onClick={() => onUpdatePressed()} variant="contained" type="button">
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </form>
            
            {/* Profile Heading */}
            <Grid
            item
            xs={12}
            sx={{
            marginBottom: "1rem",
            marginTop: "2rem",
            display: "flex",
            justifyContent: "space-between",
            }}
            >
            <Typography
            sx={{
                color: " #FFFFFF",
                fontSize: "1rem",
                fontWeight: "700",
                fontFamily: "DM Sans",
            }}
            >
            General Settings
                </Typography>
            </Grid>

            <Grid container direction="column">
            {/* Dark mode switch */}
            <Grid item>
                <Switch checked={darkMode} onChange={handleDarkModeToggle} />
                <span>{darkMode ? 'Dark Mode: On' : 'Dark Mode: Off'}</span>
            </Grid>
            
             {/* Profile Heading */}
             <Grid
            item
            xs={12}
            sx={{
            display: "flex",
            justifyContent: "space-between",
            }}
            >
            </Grid>

             {/* <Grid
            item
            xs={12}
            sx={{
            marginBottom: "1rem",
            marginTop: "2rem",
            display: "flex",
            justifyContent: "space-between",
            }}
            >
            <Typography
            sx={{
                color: " #FFFFFF",
                fontSize: "1rem",
                fontWeight: "700",
                fontFamily: "DM Sans",
            }}
            >
            Referral Link
                </Typography>
            </Grid> */}
            {/* <Grid>
            <Typography
            sx={{
                color: " #FFFFFF",
                fontSize: "1rem",
                fontWeight: "700",
                fontFamily: "DM Sans",
            }}
            >
            Referral Link
                </Typography>
            </Grid> */}

             {/* Follow us, Join Community, Logout Cards */}
            <Grid container  style={{ paddingLeft: '6px', paddingTop: '100px'}}>
             <Grid style={{ marginHorizontal: '10px' }}>
             <Button sx={{backgroundColor: '#6C5DD3', color: '#FFFFFF', marginRight: 2, textTransform: 'capitalize'}} onClick={logout}>
             <LogoutIcon sx={{marginRight: 2}} />
             <Typography>Logout </Typography>
              </Button>
             </Grid>
             <Grid>
              <Button sx={{backgroundColor: '#6C5DD3', color: '#FFFFFF', textTransform: 'capitalize'}} onClick={() => window.open("https://twitter.com/synx_xyz")}>
              <TwitterIcon sx={{marginRight: 2}} />
                <Typography>Follow Us </Typography>
              </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Grid>
    </ThemeProvider>
  );
}

export default Profile;
