import React, {useState, useEffect} from "react";
import { Grid, Typography, Drawer } from "@mui/material";
import synxRightBarAllFiles from "../assets/synxRightBarAllFiles.png";
import PersonalIconImg from "../assets/personal.png";
import SynxWalletIcon from "../assets/SynxWalletIcon.png";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { dashBoardRightSideBar } from "../constants/appConstants";
import Divider from '@mui/material/Divider';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import UploadDrawer from './uploadDrawer';
import { SwitchVaultPopover } from "./switchVaultPopover";


import copy_24px from "../assets/copy_24px.png";
import solana_logo from "../assets/solana_logo.png";
import charts from "../assets/charts.png";
import genesysgo_logo from "../assets/genesysgo_logo.png";
import buyIcon from '../assets/manageWallet/buy.png';
import ReceiveCash from '../assets/manageWallet/ReceiveCash.png';
import sendIcon from '../assets/manageWallet/send.png';
import swapIcon from '../assets/manageWallet/swap.png';


function RightBarWallet(){
  const { UPLOADTEXT, WALLET } = dashBoardRightSideBar;
  const [modalOpen, setModalOpen] = useState(false);
  const smallSolanaAddress = 'SOL Address'; // replace with actual address
  const [copied, setCopied] = useState(false);
  const [showHoldings, setShowHoldings] = useState(true);  // Initially show the holdings
  const [uploadDrawerOpen, setUploadDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);


  const toggleHoldings = () => {
    setShowHoldings(!showHoldings);  // Toggle the visibility of the holdings on click
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(smallSolanaAddress);
    setCopied(true);
  }

  const handleSnackbarClose = () => {
    setCopied(false);
  };

  // Need to slide overlay here
  const profileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const profileClose = () => {
    setAnchorEl(null);
  };

  const walletClick = () => {
    setModalOpen(true); 
  };

  const uploadClick = () => {
    setUploadDrawerOpen(true); // Open the drawer
  };

  const handleUploadDrawerClose = () => {
    setUploadDrawerOpen(false); // Close the drawer
  };

  const handleClose = () => {
    setModalOpen(false); // Close the modal
  };

  const holdings = [
    {
      logo: genesysgo_logo,
      name: 'Shadow',
      amount: '2 SHDW',
      price: '$2',
      percentage: '2%',
    },
    {
      logo: solana_logo,
      name: 'Solana',
      amount: '2 SOL',
      price: '$20',
      percentage: '2%',
    },
    {
      logo: solana_logo,
      name: 'STAX',
      amount: '2 STAX',
      price: '$69',
      percentage: '98%',
    },
    // other holdings...
  ];

  return (
    <>
       <SwitchVaultPopover anchorEl={anchorEl} handleClose={profileClose} />
       <UploadDrawer open={uploadDrawerOpen} handleClose={handleUploadDrawerClose} />
       {/* Add the Drawer component */}
       <Drawer
        anchor='right' 
        open={modalOpen}
        onClose={handleClose}
        sx={{
          width: '25%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '25%',
            backgroundColor: '#1E1E25',
          },
        }}
      >
        {/* Header icons for side drawer */}
        <div style={{ width: '25vw', color: '#FFFFFF' }}> 
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              paddingTop: '1em', // Add padding to the top
              paddingLeft: '1em',
            }}
          >
            <Grid
              item
              xs={5.9}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                className="logoRightBarAllFiles"
                src={synxRightBarAllFiles}
                alt="synxRightBarAllFiles"
                style={{ height: '71px', width: '76px' }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12}>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={uploadClick}
                  >
                    <FileUploadIcon
                      className="filUploadIcon"
                      size="small"
                      sx={{
                        color: 'rgba(255, 255, 255, 0.5)',
                        display: 'inline-flex',
                        fontSize: '1.5rem',
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      className="fileUploadTextSize"
                      sx={{
                        fontSize: '0.6rem',
                        fontWeight: '400',
                        color: 'rgba(255, 255, 255, 0.5)',
                        fontFamily: 'DM Sans',
                      }}
                    >
                      {UPLOADTEXT}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12}>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={walletClick}
                  >
                    <img
                      className="imgSizeWalletIcon"
                      src={SynxWalletIcon}
                      alt="SynxWalletIcon-Logo"
                      style={{
                        height: '22px',
                        width: '22px',
                        display: 'inline-flex',
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '0.1rem',
                    }}
                  >
                    <Typography
                      className="fileUploadTextSize"
                      sx={{
                        fontSize: '0.6rem',
                        fontWeight: '400',
                        color: 'rgba(255, 255, 255, 0.5)',
                        fontFamily: 'DM Sans',
                      }}
                    >
                      {WALLET}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12}>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={PersonalIconImg}
                      alt="DonutChart"
                      style={{ height: '2.15rem', width: '2.15rem' }}
                      onClick={profileClick}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Divider for rest of drawer content */}
          <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)', my: 2 }} />
          
        <Grid
          container
          direction="row"
          justifyContent="leading"
          alignItems="leading"
          sx={{ color: '#FFFFFF', marginBottom: '1em' }} 
          onClick={handleClose} 
        >
          <Typography variant="h6" component="div" 
          sx={{
            fontSize: '1rem',
            color: 'rgba(255, 255, 255, 0.5)',
            fontFamily: 'DM Sans',
            paddingLeft: 2,
          }}>
            Minimize
          </Typography>
          <ArrowForwardIosIcon sx={{ color: '#A79EE5' }} /> 
        </Grid>

          {/* Header */}
          <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: '1em',
            marginBottom: '1em',
          }}
        >
          <Typography
            component="h2"
            variant="h5"
            sx={{
              color: '#fff',
              fontFamily: 'DM Sans',
              fontWeight: 'BOLD',
            }}
          >
            $77.80
          </Typography>
        </Grid>

         {/* Solana and SHDW balances should go here */}
         <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: '12px',
            width: '100%',
            flexWrap: 'nowrap',
            marginLeft: '-1rem',
          }}
        >
        <Grid item container direction="row" alignItems="center" xs={5} justifyContent="flex-end">
          <img src={solana_logo} alt="img1" style={{ marginRight: '8px', width: '16px', height: '16px'}}/>
          <Typography   
            sx={{
              fontSize: '1rem',
              color: 'rgba(255, 255, 255, 0.8)',
              fontFamily: 'DM Sans',
            }}
          >
            70 SOL
          </Typography>
        </Grid>

        <Grid item container direction="row" alignItems="center" paddingLeft={2} xs={5} justifyContent="flex-start">
          <img src={genesysgo_logo} alt="img2" style={{ marginRight: '8px', width: '16px', height: '16px'}}/>
          <Typography   
            sx={{
              fontSize: '1rem',
              color: 'rgba(255, 255, 255, 0.8)',
              fontFamily: 'DM Sans',
            }}
          >
            60.00 SHDW
          </Typography>
        </Grid>
      </Grid>


        {/* Wallet address and copy/paste */}
        <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{
          marginTop: '1.5em', // This can be adjusted based on your needs
        }}
      >
          <div onClick={copyToClipboard} style={{ width: '39%', justifyContent: 'center', backgroundColor: '#1E1E25', padding: '12px', flexDirection: 'row', borderRadius: '5px', display: 'flex', alignItems: 'center', cursor: 'pointer',   backgroundColor:"#282832"  }}>
            <Typography component="div" 
              sx={{
                fontSize: '1rem',
                color: 'FFFFFF',
                fontFamily: 'DM Sans',
                paddingLeft: 1,
                paddingRight: 1,
              }}>
              {smallSolanaAddress}
            </Typography>
            <img src={copy_24px} alt='copy-icon' style={{ width: '16px', height: '16px', objectFit: 'contain', alignSelf: 'center', filter: 'grayscale(80%)'}} />
          </div>
        </Grid>

        {/* Buttons for buy, sell, receive, trade */}
        
        <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ 
          width: '25vw',
          paddingTop: '2rem',
        }}
      >
        {[
          { text: 'Buy', icon: buyIcon },
          { text: 'Receive', icon: ReceiveCash },
          { text: 'Send', icon: sendIcon },
          { text: 'Swap', icon: swapIcon },
        ].map(({ text, icon }) => (
          <Grid item xs={3} container direction="column" alignItems="center">
            <Button
              variant="contained"
              style={{
                height: '3.5vw',
                width: '3.5vw',
                borderRadius: '50%',
                backgroundColor: '#6C5DD3',
              }}
            >
              <img
                src={icon}
                alt={`${text} icon`}
                style={{ width: '30px', height: '30px' }}
              />
            </Button>
            <Typography
              variant="body1"
              style={{
                marginTop: 15,
                fontSize: 13,
                fontWeight: 'bold',
                color: '#fff',
              }}
            >
              {text}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* Holdings */}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ color: '#FFFFFF', marginBottom: '1em', marginTop: '2.5em'}}
      >
        <Grid item>
          <Typography 
            variant="h6" 
            component="div"
            sx={{
              fontSize: '1rem',
              color: 'rgba(255, 255, 255)',
              fontFamily: 'DM Sans',
              paddingLeft: 2,
              fontWeight: 'bold', 
            }}
          >
            Holdings
          </Typography>
        </Grid>
        
        <Grid item
         sx={{
          paddingRight: 1,
        }}>
          <KeyboardArrowUpOutlinedIcon sx={{ color: '#FFFFFF', marginLeft: '1em' }}  onClick={() => setShowHoldings(!showHoldings)}  />
        </Grid>
      </Grid>

        {/* Each holding value */}

        <Grid 
          container
          sx={{ padding: '0px 16px' }}  // Add horizontal padding
        >
        {holdings.map((holding, index) => (
          <Grid 
            container 
            direction="row" 
            sx={{ 
              backgroundColor: '#282832', 
              borderRadius: '5px', 
              padding: '10px', 
              marginTop: index === 0 ? '5px' : '10px',
            }}
            key={index}
          >
            <Grid item xs={2} container alignItems="center" justifyContent="center">
              <img src={holding.logo} alt={`${holding.name} Logo`} style={{ width: '40px', height: '40px', objectFit: 'contain' }}/>
            </Grid>

            <Grid item xs={5} container direction="column" justifyContent="center">
              <Typography
                sx={{ fontSize: '1rem', color: '#fff', fontFamily: 'DM Sans', fontWeight: 'bold' }}
              >
                {holding.name}
              </Typography>
              <Typography
                sx={{ fontSize: '0.9rem', color: '#c9c9c9', fontFamily: 'DM Sans', fontWeight: 'bold' }}
              >
                {holding.amount}
              </Typography>
            </Grid>

            <Grid item xs={5} container direction="column" justifyContent="center" alignItems="flex-end">
              <Typography
                sx={{ fontSize: '1rem', color: '#fff', fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'right' }}
              >
                {holding.price}
              </Typography>
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  fontFamily: 'DM Sans',
                  fontWeight: 'bold',
                  textAlign: 'right',
                  color: "#10B981",
                }}
              >
                {holding.percentage}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      
      {/* Image component for viewing charts */}
      <Grid
        container
        sx={{ 
          padding: '5px 5px',
          mt: 1,
          mb: 1
        }}
      >
        <img 
          src={charts} 
          alt="Chart" 
          style={{
            maxWidth: '30%',
            maxHeight: '30%',
            objectFit: 'contain',
            margin: 'auto',
            display: 'block'
          }}
        />
      </Grid>

      <Grid 
        container
        justifyContent="center"
        sx={{ 
          padding: '5px 5px',
        }}
      >
        <Typography 
          sx={{ 
            fontSize: '1rem', 
            color: '#fff', 
            fontFamily: 'DM Sans', 
            textAlign: 'center'
          }}
        >
          Click on any asset to see price & trend chart!
        </Typography>
      </Grid>


        {/* Alerts copy to clipboard */}
        <Snackbar open={copied} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Copied to clipboard!
          </Alert>
        </Snackbar>

        </div>
      </Drawer>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={5.9}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            className="logoRightBarAllFiles"
            src={synxRightBarAllFiles}
            alt="synxRightBarAllFiles"
            style={{ height: "71px", width: "76px" }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
            }}
          >
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={uploadClick} // Added upload click handler
              >
                <FileUploadIcon
                  className="filUploadIcon"
                  size="small"
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    display: "inline-flex",
                    fontSize: "1.5rem",
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  className="fileUploadTextSize"
                  sx={{
                    fontSize: "0.6rem",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                    fontFamily: "DM Sans",
                  }}
                >
                  {UPLOADTEXT}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
            }}
          >
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={walletClick} // Added wallet click handler
              >
                <img
                  className="imgSizeWalletIcon"
                  src={SynxWalletIcon}
                  alt="SynxWalletIcon-Logo"
                  style={{
                    height: "22px",
                    width: "22px",
                    display: "inline-flex",
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0.1rem",
                }}
              >
                <Typography
                  className="fileUploadTextSize"
                  sx={{
                    fontSize: "0.6rem",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                    fontFamily: "DM Sans",
                  }}
                >
                  {WALLET}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
            }}
          >
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                 <img
                src={PersonalIconImg}
                style={{ height: "2.15rem", width: "2.15rem" }}
                onClick={profileClick} // Added onClick handler
               />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default RightBarWallet;
