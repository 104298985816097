import { AuthConsumer, AuthProviderData } from './AuthProvider.tsx';

export const LoginButton = () => {
  return (
    <AuthConsumer>
      {(value: AuthProviderData) =>
        value.user === null ? (
          <button
          className="btn w-60 h-10 justify-center align-middle font-semibold"
          style={{ marginTop: "15px", color: 'white', textTransform: "capitalize", fontWeight: '600', fontFamily: 'DM Sans' }}
          onClick={value.login}
          >
            Enter Synx App
          </button>
        ) : (
          <button
          className="btn w-60 h-10 justify-center align-middle font-semibold"
          style={{ marginTop: "15px", color: 'white', textTransform: "capitalize", fontWeight: '600', fontFamily: 'DM Sans' }}
           onClick={value.logout}
          >
            Exit Synx App
          </button>
        )
      }
    </AuthConsumer>
  );
};
