import React,{useState, useEffect} from "react";
import { Grid, Typography, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import moment from "moment";
import { JSEncrypt } from "jsencrypt";
import { recentlyEditSection } from "../../constants/appConstants";
import { DECRYPT_FILE, GET_PROFILE } from "../../services/solana_services";
import { GET_All_FILES, GET_FILES_PIN, GET_STORAGE_ACCOUNT_INFO } from "../../services/services";
import CloseIcon from '@mui/icons-material/Close';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import RecentlyEditedFileActionsMenu from "../recentlyEditedFileActionsMenu";
import { DEFAULT_IMG, IMGS_ARR } from "../../constants/ImagesExts";
import mammoth from 'mammoth';

function RecentlyEditedSection({storageAddress,setFileCounts, refreshing}) {
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
  let STORAGE_ADDR = storageAddress
  const [files, setFiles] = useState([])
  const [recentFiles, setRecentFiles] = useState([])
  const [vaultsArray, setVaultsArray] = useState([])
  const [solanaPublicAddress, setSolanaPublicAddress] = useState("")
  const [solanaKey, setSolanaKey] = useState("")
  const [reload, setReload] = useState(false)
  
  const { RECENTLYEDITHEADING, IMAGEPNG, EDITMINUTES, FILESDOCS, FILEXLS } =
  recentlyEditSection;
  const [decryptedFileData, setDecryptedFileData] = useState(null);
  const [modalContent, setModalContent] = useState({ title: '', imageUrl: '' });
  const [accessToken, setAccessToken] = useState("")
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("")
  const [isImmutable, setImmutable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allFilesDetailedArr,setAllFilesDetailedArr]=useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  const handleMenuOpen = (event, data) => {
    setCurrentItem(data)
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setCurrentItem(null)
    setAnchorEl(null);
  };
  
  const handleOpenModal = (file) => {
    setSelectedFile(file);
    setOpenModal(true);
  };
    
    const handleCloseModal = () => {
      setSelectedFile(null);
      setOpenModal(false);
    };
    useEffect(() => {
      if (refreshing || reload) {
        fetchBalances()
      }
    }, [refreshing, reload]);
    useEffect(() => {
      fetchBalances()
    }, []);
    const onReload = React.useCallback(async () => {
      setReload(true);
      setTimeout(function(){setReload(false)}, 2000)
    }, [reload]);
    const fetchBalances = async () => {
      const str_payload = localStorage.getItem('payload');
      let payload = JSON.parse(str_payload)
      setUserEmail(payload?.email)
      if(STORAGE_ADDR){
        getStorageDetails(payload?.email)
      }
      const accessTokenVal = localStorage.getItem('access_token');
      setAccessToken(accessTokenVal)
      const str_solana_keys = localStorage.getItem('solana_key');
      setSolanaKey(str_solana_keys)
      setSolanaPublicAddress(payload.solanaaccount)
      

      var enc_secret_key = encrypt.encrypt(str_solana_keys);
      let get_profile_body = { "email": payload.email, "name": payload.username, "secret_key": enc_secret_key, "typeOfLogin": payload.typeOfLogin }
      GET_PROFILE(get_profile_body)
      .then(({ data }) => {
          let vaults_array = data.data.payload.user_profile.vaults;
          if (vaults_array.length > 0) {
            setVaultsArray(vaults_array)
            getStorageList(payload._id, payload.email);
            getPinnedList(payload.email);
     
          }else{
            setIsLoading(false)
          }
        
      })
    .catch(async function (error) {

  })
    }
    const getPinnedList = async (user_email) => {
      let body = {
        "email" : user_email,
    };
    GET_FILES_PIN(body)
    .then(({ data }) => {
      let emptyMessage = "";
        if(data.status == 200){
          setAllFilesDetailedArr(data.data.payload)
        }
    })
    .catch(function (error) {
     
     }).finally(() => { 
          setIsLoading(false)
   });;
  
    }

    const bytesToSize = (bytes) => {
      var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 B';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
    const count_Files = (document_types, data) => {
      const mediaFiles = data.filter(item => {
        const extension = item.file_name.split(".").pop().toLocaleLowerCase()
        return document_types.indexOf(extension) > -1;
      });
    return mediaFiles.length
     
    }
      const getStorageList = async (user_id, user_email) => {
        let body = {
          "id": user_id,
          "email": user_email,
        };
    
        GET_All_FILES(body)
          .then(({ data }) => {
            if (data.status == 200) {
              let payload = data.data.payload;
              let files_arr = [];
              if(payload.length > 0){
                payload.map((item, index) => {
                  if(item.files){
                    if(item.files.length > 0){
                      item.files.forEach(element => {
                        
                        element.storage_address = item.storageAccount
                        
                        files_arr.push(element)
      
                      });
                     }
                  }
                 
    
                })
              }
             
              setFiles(files_arr)
              let items = [];
              if (files_arr.length > 0) {
                let dataFiles = files_arr.sort((a, b) => b.last_modified.localeCompare(a.last_modified));
                items = dataFiles.slice(0, 8)
                setRecentFiles(items)
                var document_types = ["pdf", "psd", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "html", "txt"]
                var media_types = ["aac", "cda", "mp3", "mp4", "mov", "wav", "webm", "webp", "mpeg", "ogv", "3gp", "avif", "bmp", "gif", "ico", "jpeg", "jpg", "png","heic","HEIC"]
                var other_types = ["7z", "arc", "bz", "bz2", "gz", "jar", "rar", "tar", "zip","ipa"]
                
                let docs_count = count_Files(document_types, files_arr)
                let media_count = count_Files(media_types, files_arr)
                let others_count = count_Files(other_types, files_arr)
    
                setFileCounts({
                  "documents" : docs_count,
                  "media" : media_count,
                  "others" : others_count,
                  "unknown" : 0
                })
              }
              setIsLoading(false)
            }
          })
          .catch(async function (error) {
            
            if(!error?.response?.data?.message.includes("Unauthorized")){
              // alert(error?.response?.data?.message)
             }
          
          });
    
      }
      
        const getStorageDetails = async (user_email) => {
          let body = {
            "storage_public_key": STORAGE_ADDR,
            "email" : user_email,
        };
        GET_STORAGE_ACCOUNT_INFO(body)
        .then(({ data }) => {
            if(data.status == 200){
              setImmutable(data.data.payload.immutable)
            }
            else{
              alert("4 = "+data.message)
            }
        })
        .catch(function (error) {
          if (error.response) {
          }
         });
      
        }
        const handleOpen = (item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, isEncrypted ) => {

          let docxFile = false
          if(!isEncrypted){
          
           setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
           setOpenModal(true);
          }else{
          
            decryptFile( item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size )
          }
         
          };
          const decryptFile = async (item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size ) => {
            setIsFileLoading(true)
            var enc_secret_key = encrypt.encrypt(solanaKey);
                      let file_body = {
                        "secret_key" : enc_secret_key,
                        "fileUrl" : encodeURI(url),
                        "publicKey" : solanaPublicAddress,
                        "email" : userEmail,
                      }
                      setOpenModal(true);
                      DECRYPT_FILE(file_body)
                    .then(async ({ data }) => {
                          if (data.status == 200) {
                            let isFileReady = false;
                            setDecryptedFileData(data.data.payload)
                            if(title.toLowerCase().includes("recording")){
                              url = `data:audio/mp4;base64,${data.data.payload.file}`
                            }else{
                              url = `data:${data.data.payload.mimetype};base64,${data.data.payload.file}`
                              let docxFile = false
                              if(title.includes("docx") || title.includes("doc")){
                                const parts = url.split(';base64,');
                                const contentType = parts[0].split(':')[1];
                                const byteString = atob(parts[1]);
                                const arrayBuffer = new ArrayBuffer(byteString.length);
                                const uint8Array = new Uint8Array(arrayBuffer);
                                for (let i = 0; i < byteString.length; i++) {
                                  uint8Array[i] = byteString.charCodeAt(i);
                                }
                               url = new Blob([arrayBuffer], { type: contentType });
                               mammoth.convertToHtml({ arrayBuffer: url })
                               .then(result => {
                           url = result.value
                                //  setFileHtml(result.value);
                                docxFile = true
                                 setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
                                 setIsFileLoading(false)
                               })
                               .done();
                              }else{
                                docxFile = false
                                setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
                                setIsFileLoading(false)
                            }
                            }
                          }
                        })
                        .catch(function (error) {
                            alert("Failed to decrypt")
                          console.log(error.response.data)
                        })
           }
        
  return (
    <>

    {/* Could come out in to it's own component - modal for file viewer*/}
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      fullWidth={true}
      maxWidth="xl" 
      PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
    >
      <DialogActions>
        <IconButton
          style={{ position: 'absolute', top: 5, right: 5, color: '#fff' }}
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogTitle
        sx={{
          color: "#A79EE5",
          fontFamily: "DM Sans",
        }}
      >
        {modalContent.title}
      </DialogTitle>

      <DialogTitle
        sx={{
          color: "#FFFFFF",
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontWeight: "400",
        }}
      >
        File Uploaded: {modalContent.date}
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Aligns content vertically
          overflow: "auto" // Adds scroll if content is too large
        }}
      >
        {isFileLoading ? (
          <img src={require("../../assets/loading_files.gif")} alt={"Loading..."}
            style={{ maxWidth: "85vw", maxHeight: "85vh", objectFit: "contain", borderRadius: '7px'}} />
        ) : modalContent.isDocx ? (
          <>
            {!modalContent.docxFile ? (
              <DocViewer documents={[
                { uri: modalContent.url }, // Remote file
              ]} pluginRenderers={DocViewerRenderers} />
            ) : (
              <iframe srcDoc={modalContent.url} width="100%" height="100%" />
            )}
          </>
        ) : modalContent.is_image >= 0 ? (
          <img src={modalContent.url} alt={modalContent.title}
            style={{ maxWidth: "85vw", maxHeight: "85vh", objectFit: "contain", borderRadius: '7px' }} />
        ) : modalContent.is_video ? (
          <video controls style={{ maxWidth: "100%", maxHeight: "100%" }}>
            <source src={modalContent.url} type={`video/${modalContent.fileExt}`} />
            Your browser does not support the video tag.
          </video>
        ) : modalContent.is_audio ? (
          <audio controls style={{ maxWidth: "100%", maxHeight: "100%" }}>
            <source src={modalContent.url} type={`audio/${modalContent.fileExt}`} />
            Your browser does not support the audio element.
          </audio>
        ) : null}
      </DialogContent>
    </Dialog>



      <Grid
        item
        xs={12}
        sx={{
          marginTop: "0.8rem",
          marginBottom: "0.8rem",
        }}
      >
        <Typography
          sx={{
            color: " #FFFFFF",
            fontSize: "1rem",
            fontWeight: "700",
            fontFamily: "DM Sans",
          }}
        >
          {RECENTLYEDITHEADING}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* first */}
        {recentFiles.length > 0 ? recentFiles.slice(0,3).map((item, index) => {
         let vaultData = vaultsArray.find(vaultItem => vaultItem.vaultaddress === item.storage_address);
         let image_types = ['png', 'jpg', 'jpeg', 'gif', 'heic', 'avif'];
         var fileExt = item.file_name.split('.').pop().toLowerCase();
        let isImage =  image_types.indexOf(fileExt);
        let isDocx =  false;
        let isAudio = false;
        let isVideo = false;
       
       
        if(fileExt == "docx" || fileExt == "doc" || fileExt == "pdf" || fileExt == "ppt"|| fileExt == "pptx"){
         isDocx =  true;
       
        }
       
        if (fileExt == "mp4" || fileExt.toLowerCase() == "mov") {
         if(item.file_name.includes("Recording") || item.file_name.includes("audio")){
           isAudio = true;
       
         }else{
           isVideo = true;
       
         }
       
       }
       if(fileExt == "mp3") {
         isAudio = true;
       
       }
        const found = IMGS_ARR.find(e => e.name === fileExt.toLowerCase());
        let IMAGE_DISPLAY = null;
        if(found){
          IMAGE_DISPLAY=found.image
        }else{
          IMAGE_DISPLAY=DEFAULT_IMG.image
        }

          return(
            <Grid
            item
            xs={3.6}
            sx={{
              height: "164px",
              backgroundColor: "#1E1E25",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <Grid item xs={12} sx={{ height: "105px" }} 
            // onClick={() => handleOpenModal(item)}
            onClick={() => {
              let files = recentFiles;
              handleOpen(item,"https://shdw-drive.genesysgo.net/"+item.storage_address+"/"+item.file_name, item.file_name,isImage,isAudio,isVideo,isDocx,fileExt, index,files,item.storage_address, solanaPublicAddress,moment(item.last_modified).format("DD-MM-YYYY"), bytesToSize(item.size), vaultData.isEncrypted)
              }}
             >
              <img
                src={vaultData.isEncrypted ? IMAGE_DISPLAY : isImage > -1 ? "https://shdw-drive.genesysgo.net/"+item.storage_address+"/"+item.file_name : IMAGE_DISPLAY}
                alt="docimage"
                style={{
                  objectFit: "cover",
                  padding: 1,
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <Grid
                item
                xs={7}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    marginLeft: "0.5rem",
                    marginTop: "0.3rem",
                    fontFamily: "DM Sans",
                    overflow:"hidden",
                    textOverflow:"ellipsis",
                    whiteSpace: "nowrap"
                  }}
                >
                 {item.file_name}
                </Typography>
  
                <Typography
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    fontSize: "0.7rem",
                    fontWeight: "400",
                    marginLeft: "0.5rem",
                    fontFamily: "DM Sans",
                  }}
                >
                  {moment(item.last_modified).fromNow(true)} ago
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
                onClick={(event) => {
                  let data = {
                    item,
                    isImage,isAudio,isVideo,isDocx,fileExt, index,files, solanaPublicAddress,
                    formattedTime : moment(item.last_modified).format("DD-MM-YYYY"),
                    size: bytesToSize(item.size), 
                    isEncrypted: vaultData.isEncrypted
                  }
                  handleMenuOpen(event,data)}}
              >
                <MoreVertRoundedIcon
                  sx={{ color: "#DADADA", marginRight: "0.5rem" }}
                />
              </Grid>
              {currentItem && 
                <RecentlyEditedFileActionsMenu
                anchorEl={anchorEl}
                onClose={handleMenuClose}

                data={currentItem}
                onReload={onReload}
                />

              }
            

            </Grid>
          </Grid>
          )
        }) : null}
      </Grid>
    </>
  );
}

export default RecentlyEditedSection;
