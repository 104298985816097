import React, { useEffect } from "react";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import { Grid } from "@mui/material";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import SearchField from "../components/searchField";
import RightBarWallet from "../components/rightBarWallet";
import NftDisplay from "../components/nftDisplay";

function Wallet() {
  useEffect(() => {
    document.title = 'Synx | Wallet 🏦';
  }, []); 

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        item
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={1.7}
          sx={{
            backgroundColor: "primary.light",
            overflow: "hidden", // Prevents left-hand dashboard from being scrollable
          }}
        >
          <DashboardSideBar />
        </Grid>
        <Grid item xs={10.3} sx={{ overflow: "auto" }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <Grid
              item
              xs={8.5}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2.1rem",
              }}
            >
              <Grid item xs={11}>
                <SearchField />
              </Grid>
            </Grid>
            <Grid
              item
              xs={3.5}
              className="rightBarAllfilesMargin"
              sx={{
                marginTop: "1rem",
                paddingLeft: "2rem",
              }}
            >
              <RightBarWallet />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={11.3}>
              <NftDisplay />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Wallet;
