import React, { useState, useEffect } from 'react';
import { Typography, Box, List, ListItem } from "@mui/material";
import { GET_LEADERBOARD } from '../services/mission_services';
import styled from 'styled-components';
import SYNX_LOGO_White from '../assets/SYNX_LOGO_White.png';

function LeaderboardList() {
  const [leaderBoardArr, setLeaderBoardArr] = useState([]);
  const CircleImage = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 25px;
  background-color: #6C5DD3;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;


  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload);
    getLeaderboardData(payload?.email);
  };

  const getLeaderboardData = (email) => {
    let body = {
      email: email,
      filter: 'by_week'
    };

    GET_LEADERBOARD(body)
      .then(({ data }) => {
        if (data.status === 200) {
          setLeaderBoardArr(data.data.payload);
        } else {
        }
      })
      .catch(function (error) {

      });
  };

  const renderItem = ({ item, index }) => {
    let keyVal = item.solanaaccount;
    const first5 = keyVal.slice(0, 4);
    var last5 = keyVal.substr(keyVal.length - 4);
    let sol_ADDR = first5 + "..." + last5;
  
    let dname = item.displayName;
    if (dname.includes("@")) {
      dname = item.user_id.substr(item.user_id.length - 5);
    }
  
    const position = index + 1;
  
    return (
      
      <ListItem
        key={index}
        sx={{
          backgroundColor: '#1E1E25',
          borderRadius: '10px',
          marginBottom: '1rem',
          width: '90%', 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Typography
            sx={{
              color: '#C7C7C8',
              marginTop: '0.2rem',
              fontSize: '1rem',
              fontFamily: 'DM Sans',
              marginRight: '1rem',
            }}
          >
            {position === 1 ? '1' : ` ${position}`}
          </Typography>
          <CircleImage>
            {item.image ? (
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '25px' }}
                src={`https://api.synx.xyz/public/uploads/images/${item.image}`}
                alt="Item Image"
              />
            ) : (
              <img
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                src={SYNX_LOGO_White}
                alt="Default Image"
              />
            )}
          </CircleImage>
          <Box>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.8)',
                fontFamily: 'DM Sans',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              {dname}
            </Typography>
            <Typography
              sx={{
                color: '#C7C7C8',
                marginTop: '0.2rem',
                fontSize: '1rem',
                fontFamily: 'DM Sans',
              }}
            >
              {sol_ADDR}
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            color: '#C7C7C8',
            marginTop: '0.2rem',
            fontSize: '1rem',
            fontFamily: 'DM Sans',
          }}
        >
          {item.total_points} Points
        </Typography>
      </ListItem>
    );
  };
  

  return (
    <List sx={{ padding: '1rem', margin: '1rem 0', overflow:"scroll", height:"50vh" }}>
      {leaderBoardArr.map((participant, index) => renderItem({ item: participant, index }))}
   
    </List>
  );
}

export default LeaderboardList;
