import SYNX_LOGO_White from '../assets/SYNX_LOGO_White.png';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Typography, Box, List, ListItem } from "@mui/material";
import styled from 'styled-components';


function DataTaskList() {
  const CircleImage = styled.div`
    width: 3rem;
    height: 3rem;
    border-radius: 25px;
    background-color: #6C5DD3;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  `;
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const navigate = useNavigate();
  
  const gridItems = [
    {
      title: "Groceries | 5 points",
      description: "Upload & label images of fruits, vegetables, packaged foods, cans, boxes, etc. These items are good for testing because they come in various shapes, sizes, colors, and textures.",
      image: "https://em-content.zobj.net/thumbs/240/apple/354/shopping-cart_1f6d2.png",
    },
    {
      title: "Receipts | 5 points",
      description: "Scan photos of your weekly spending receipts from your normal routine with the Synx mobile app document scanning capability.",
      image: "https://em-content.zobj.net/thumbs/240/apple/354/receipt_1f9fe.png",
    },
    {
      title: "Vehicles | 5 points",
      description: "Upload & label images of various types of vehicles such as cars, motorcycles, bicycles, or trucks. Help to create a diverse dataset for testing and improving vehicle recognition algorithms.",
      image: "https://em-content.zobj.net/thumbs/240/apple/354/automobile_1f697.png",
    },
  ];

  const handleGridItemClick = (item) => {
    navigate("/submit", { state: { selectedTask: item } });
  };
  
  
  const renderItem = ({ item, index }) => {
    return (
      <ListItem
        key={index}
        sx={{
          backgroundColor: '#1E1E25',
          borderRadius: '10px',
          padding: '2%',
          marginBottom: '1rem',
          width: '100%', 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: hoveredIndex === index ? "0 0 10px #A79EE5" : "none", 
          border: hoveredIndex === index ? "1px solid #A79EE5" : "none",
        }}
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(-1)}
        onClick={() => handleGridItemClick(item.title)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <img
                style={{ width: '60px', height: '60px', objectFit: 'contain' }}
                src={item.image}
                alt="Default Image"
              />
          <Box>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.8)',
                fontFamily: 'DM Sans',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              {item.title}
            </Typography>
            <Typography
              sx={{
                color: '#C7C7C8',
                marginTop: '0.2rem',
                fontSize: '1rem',
                fontFamily: 'DM Sans',
              }}
            >
              {item.description}
            </Typography>
          </Box>
        </Box>
      </ListItem>
    );
  };
  

  return (
    <List sx={{ padding: '1rem', margin: '1rem 0', overflow:"scroll"}}>
      {gridItems.map((item, index) => renderItem({ item, index }))}
    </List>
  );
}

export default DataTaskList;
