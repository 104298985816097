// UploadDrawer.js
import React, {useState, useEffect} from 'react';
import { Grid, Typography, Drawer, Divider } from "@mui/material";
import synxRightBarAllFiles from "../assets/synxRightBarAllFiles.png";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PersonalIconImg from "../assets/personal.png";
import SynxWalletIcon from "../assets/SynxWalletIcon.png";
import moment from "moment";
import { dashBoardRightSideBar } from "../constants/appConstants";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import UploadFileSection from './mainBodySection/uploadFileSection';
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { JSEncrypt } from "jsencrypt";
import solana_logo from "../assets/solana_logo.png";
import genesysgo_logo from "../assets/genesysgo_logo.png";
import { DECRYPT_FILE, GET_PROFILE } from '../services/solana_services';
import { GET_All_FILES } from '../services/services';
import { DEFAULT_IMG, IMGS_ARR } from "../constants/ImagesExts";
import mammoth from 'mammoth';

const UploadDrawer = ({ open, handleClose,updateRecentFiles,updateAllData }) => {
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
    const { UPLOADTEXT, WALLET } = dashBoardRightSideBar;
    const [uploadDrawerOpen, setUploadDrawerOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [refreshPage, setRefreshPage] = useState(false);
    const [recentFiles, setRecentFiles] = useState([])
    const [vaultsArray, setVaultsArray] = useState([])
    const [solanaPublicAddress, setSolanaPublicAddress] = useState("")
    const [solanaKey, setSolanaKey] = useState("")
    const [reload, setReload] = useState(false)
    const [decryptedFileData, setDecryptedFileData] = useState(null);
    const [modalContent, setModalContent] = useState({ title: '', imageUrl: '' });
    const [accessToken, setAccessToken] = useState("")
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [userEmail, setUserEmail] = useState("")
    const [isImmutable, setImmutable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [allFilesDetailedArr,setAllFilesDetailedArr]=useState([])
    const [selectedFile, setSelectedFile] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    useEffect(() => {
      fetchBalances()
    }, []);
    useEffect(() => {
      if (refreshPage) {
        fetchBalances()
      }
    }, [refreshPage]);

    const onRefreshPage = React.useCallback(async () => {
      setRefreshPage(true);
      setTimeout(function(){setRefreshPage(false)}, 2000)
    }, [refreshPage]);

    const fetchBalances = async () => {
      const str_payload = localStorage.getItem('payload');
      let payload = JSON.parse(str_payload)
      setUserEmail(payload?.email)
      const accessTokenVal = localStorage.getItem('access_token');
      setAccessToken(accessTokenVal)
      const str_solana_keys = localStorage.getItem('solana_key');
      setSolanaKey(str_solana_keys)
      setSolanaPublicAddress(payload.solanaaccount)
      var enc_secret_key = encrypt.encrypt(str_solana_keys);
      let get_profile_body = { "email": payload.email, "name": payload.username, "typeOfLogin": payload.typeOfLogin }
      GET_PROFILE(get_profile_body)
      .then(({ data }) => {
        if (data.status == 200) {
          let vaults_array = data.data.payload.user_profile.vaults;
          if (vaults_array.length > 0) {
            setVaultsArray(vaults_array)
            getStorageList(payload._id, payload.email);
     
          }
        
        }
        
      })
    .catch(async function (error) {

  })
    }
    const getStorageList = async (user_id, user_email) => {
      let body = {
        "id": user_id,
        "email": user_email,
      };
  
      GET_All_FILES(body)
        .then(({ data }) => {
          if (data.status == 200) {
            let payload = data.data.payload;
            let files_arr = [];
            if(payload.length > 0){
              payload.map((item, index) => {
                 if(item.files.length > 0){
                  item.files.forEach(element => {
                    
                    element.storage_address = item.storageAccount
                    
                    files_arr.push(element)
  
                  });
                 }
  
              })
            }
           
       
            let items = [];
            if (files_arr.length > 0) {
              let dataFiles = files_arr.sort((a, b) => b.last_modified.localeCompare(a.last_modified));
              items = dataFiles.slice(0, 3)
              setRecentFiles(items)
            }
          }
        })
        .catch(async function (error) {
          
        
         if(!error?.response?.data?.message.includes("Unauthorized")){
          // alert(error?.response?.data?.message)
         }
        
        });
  
    }
    const bytesToSize = (bytes) => {
      var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 B';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
    const walletClick = () => {
        setModalOpen(true); 
      };
    

    const uploadClick = () => {
        setUploadDrawerOpen(true); // Open the drawer
      };
    
    const handleUploadDrawerClose = () => {
    setUploadDrawerOpen(false); // Close the drawer
    };

    const profileClick = () => {
    alert('Profile clicked!');
    };
    const handleOpen = (item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, isEncrypted ) => {

      let docxFile = false
      if(!isEncrypted){
      
       setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
       setOpenModal(true);
      }else{
      
        decryptFile( item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size )
      }
     
      };
      const decryptFile = async (item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size ) => {
        setIsFileLoading(true)
        var enc_secret_key = encrypt.encrypt(solanaKey);
                  let file_body = {
                    "secret_key" : enc_secret_key,
                    "fileUrl" : encodeURI(url),
                    "publicKey" : solanaPublicAddress,
                    "email" : userEmail,
                  }
                  setOpenModal(true);
                  DECRYPT_FILE(file_body)
                .then(async ({ data }) => {
                      if (data.status == 200) {
                        let isFileReady = false;
                        setDecryptedFileData(data.data.payload)
                        if(title.toLowerCase().includes("recording")){
                          url = `data:audio/mp4;base64,${data.data.payload.file}`
                        }else{
                          url = `data:${data.data.payload.mimetype};base64,${data.data.payload.file}`
                          let docxFile = false
                          if(title.includes("docx") || title.includes("doc")){
                            const parts = url.split(';base64,');
                            const contentType = parts[0].split(':')[1];
                            const byteString = atob(parts[1]);
                            const arrayBuffer = new ArrayBuffer(byteString.length);
                            const uint8Array = new Uint8Array(arrayBuffer);
                            for (let i = 0; i < byteString.length; i++) {
                              uint8Array[i] = byteString.charCodeAt(i);
                            }
                           url = new Blob([arrayBuffer], { type: contentType });
                           mammoth.convertToHtml({ arrayBuffer: url })
                           .then(result => {
                       url = result.value
                            //  setFileHtml(result.value);
                            docxFile = true
                             setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
                             setIsFileLoading(false)
                           })
                           .done();
                          }else{
                            docxFile = false
                            setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
                            setIsFileLoading(false)
                        }
                        }
                      }
                    })
                    .catch(function (error) {
                        alert("Failed to decrypt")
                      console.log(error.response.data)
                    })
       }


    
    return (
        <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
            width: '25%',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: '25%',
                backgroundColor: '#1E1E25',
                width: '25%', // Set a fixed width
                overflow: 'hidden'  // Disable scrolling
            },
        }}
    >
        <div style={{ width: '25vw', color: '#FFFFFF' }}> 
            <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '1em', // Add padding to the top
              paddingLeft: '1em',
            }}
          >
            <Grid
              item
              xs={5.9}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                className="logoRightBarAllFiles"
                src={synxRightBarAllFiles}
                alt="synxRightBarAllFiles"
                style={{ height: '71px', width: '76px' }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12}>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={uploadClick}
                  >
                    <FileUploadIcon
                      className="filUploadIcon"
                      size="small"
                      sx={{
                        color: 'rgba(255, 255, 255, 0.5)',
                        display: 'inline-flex',
                        fontSize: '1.5rem',
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      className="fileUploadTextSize"
                      sx={{
                        fontSize: '0.6rem',
                        fontWeight: '400',
                        color: 'rgba(255, 255, 255, 0.5)',
                        fontFamily: 'DM Sans',
                      }}
                    >
                      {UPLOADTEXT}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12}>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={walletClick}
                  >
                    <img
                      className="imgSizeWalletIcon"
                      src={SynxWalletIcon}
                      alt="SynxWalletIcon-Logo"
                      style={{
                        height: '22px',
                        width: '22px',
                        display: 'inline-flex',
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '0.1rem',
                    }}
                  >
                    <Typography
                      className="fileUploadTextSize"
                      sx={{
                        fontSize: '0.6rem',
                        fontWeight: '400',
                        color: 'rgba(255, 255, 255, 0.5)',
                        fontFamily: 'DM Sans',
                      }}
                    >
                      {WALLET}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12}>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={PersonalIconImg}
                      alt="DonutChart"
                      style={{ height: '2.15rem', width: '2.15rem' }}
                      onClick={profileClick}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


          {/* Divider for rest of drawer content */}
          <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)', my: 2 }} />
          
        <Grid
          container
          direction="row"
          justifyContent="leading"
          alignItems="leading"
          sx={{ color: '#FFFFFF', marginBottom: '1em' }}  // Set the color and margin as needed
          onClick={handleClose} // Add the onClick event listener here
        >
          <Typography variant="h6" component="div" 
          sx={{
            fontSize: '1rem',
            color: 'rgba(255, 255, 255, 0.5)',
            fontFamily: 'DM Sans',
            paddingLeft: 2,
          }}>
            Minimize
          </Typography>
          <ArrowForwardIosIcon sx={{ color: '#A79EE5' }} /> 
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="leading"
          alignItems="leading"
          sx={{ color: '#FFFFFF', marginBottom: '1em' }}  // Set the color and margin as needed
        >
        </Grid>

        {/* Upload file section */}
        <Grid
            container
            direction="row"
            justifyContent="leading"
            alignItems="leading"
            sx={{ color: '#FFFFFF', marginBottom: '1em', mx: 2}}  // Set the color and margin as needed
        >
            <UploadFileSection updateRecentFiles={updateRecentFiles} setUploadDrawerOpen={setUploadDrawerOpen} onRefreshDrawerRecents={fetchBalances} updateAllData={updateAllData} />
        </Grid>

      {/* Recently Uploaded Files */}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ color: '#FFFFFF', marginBottom: '1em', marginTop: '2.5em'}}
      >
        <Grid item>
          <Typography 
            variant="h6" 
            component="div"
            sx={{
              fontSize: '1rem',
              color: 'rgba(255, 255, 255)',
              fontFamily: 'DM Sans',
              paddingLeft: 2,
              fontWeight: 'bold', 
            }}
          >
            Recently Uploaded
          </Typography>
        </Grid>
            
        <Grid item
         sx={{
          paddingRight: 1,
        }}>
          <KeyboardArrowUpOutlinedIcon sx={{ color: '#FFFFFF', marginLeft: '1em' }} />
        </Grid>
      </Grid>

      <Grid 
          container
          sx={{ padding: '0px 16px' }}  // Add horizontal padding
        >
        {recentFiles.map((item, index) => {
            let vaultData = vaultsArray.find(vaultItem => vaultItem.vaultaddress === item.storage_address);
            let image_types = ['png', 'jpg', 'jpeg', 'gif', 'heic', 'avif'];
            var fileExt = item.file_name.split('.').pop().toLowerCase();
           let isImage =  image_types.indexOf(fileExt);
           let isDocx =  false;
           let isAudio = false;
           let isVideo = false;
          
          
           if(fileExt == "docx" || fileExt == "doc" || fileExt == "pdf" || fileExt == "ppt"|| fileExt == "pptx"){
            isDocx =  true;
          
           }
          
           if (fileExt == "mp4" || fileExt.toLowerCase() == "mov") {
            if(item.file_name.includes("Recording") || item.file_name.includes("audio")){
              isAudio = true;
          
            }else{
              isVideo = true;
          
            }
          
          }
          if(fileExt == "mp3") {
            isAudio = true;
          
          }
           const found = IMGS_ARR.find(e => e.name === fileExt.toLowerCase());
           let IMAGE_DISPLAY = null;
           if(found){
             IMAGE_DISPLAY=found.image
           }else{
             IMAGE_DISPLAY=DEFAULT_IMG.image
           }
          return(
          <Grid 
            container 
            direction="row" 
            sx={{ 
              backgroundColor: '#282832', 
              borderRadius: '5px', 
              padding: '10px', 
              marginTop: index === 0 ? '5px' : '10px',
            }}
            key={index}
            onClick={() => {
              let files = recentFiles
              handleOpen(item,"https://shdw-drive.genesysgo.net/"+item.storage_address+"/"+item.file_name, item.file_name,isImage,isAudio,isVideo,isDocx,fileExt, index,files,item.storage_address, solanaPublicAddress,moment(item.last_modified).format("DD-MM-YYYY"), bytesToSize(item.size), vaultData.isEncrypted)
            }}
          >
            <Grid item xs={2} container alignItems="center" justifyContent="center">
              <img 
             src={vaultData.isEncrypted ? IMAGE_DISPLAY : isImage > -1 ? "https://shdw-drive.genesysgo.net/"+item.storage_address+"/"+item.file_name : IMAGE_DISPLAY}
               alt={`${item.file_name} Logo`} style={{ width: '40px', height: '40px', objectFit: 'contain' }}/>
            </Grid>

            <Grid item xs={8}  sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}>
              <Typography
                sx={{ fontSize: '1rem', color: '#fff', fontFamily: 'DM Sans', fontWeight: 'bold',
                overflow:"hidden",
                textOverflow:"ellipsis",
                whiteSpace: "nowrap" }}
              >
                {item.file_name}
              </Typography>
              <Typography
                sx={{ fontSize: '0.9rem', color: '#c9c9c9', fontFamily: 'DM Sans', fontWeight: 'bold' }}
              >
                {moment(item.last_modified).format('Do MMMM YYYY')}
              </Typography>
            </Grid>

            <Grid item xs={2} container direction="column" justifyContent="center" alignItems="flex-end">
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  fontFamily: 'DM Sans',
                  textAlign: 'right',
                }}
              >
                {bytesToSize(item.size)}
              </Typography>
            </Grid>
          </Grid>
        )})}
      </Grid>
      

      </div>
    </Drawer>
    );
};

export default UploadDrawer;
