import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 13,
  width: 140,

  borderRadius: "73px",

  background:
    "linear-gradient(0deg, rgba(108, 93, 211, 0.08), rgba(108, 93, 211, 0.08)), #282832",
  backdropFilter: "blur(150px)",

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "73px",
    background:
      "linear-gradient(131.59deg, #17142F 4.77%, #2C2658 22.55%, #423981 41.32%, #574BAA 60.09%, #6C5DD3 77.88%, #897DDC 99.61%)",
  },
}));

export default function CustomizedProgressBars({ percentUsed }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={parseInt(percentUsed)} />
    </Box>
  );
}
