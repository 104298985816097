import React, {useState, useEffect} from "react";
import { Grid, TextField, Button, Switch, Card, CardMedia, Typography, IconButton  } from "@mui/material";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import RightBarAllFiles from "../components/rightBarAllFiles";
import SearchField from "../components/searchField";
import NoteEditor from "../components/noteEditor"; 

function Notes() {
    const [notes, setNotes] = useState([]);
    
    useEffect(() => {
      document.title = 'Synx | Notes 🗒️';
    }, []); 

    const handleNoteSave = (note) => {
        setNotes((prevNotes) => [...prevNotes, note]);
    };

    return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={1.7}
          sx={{
            backgroundColor: "primary.light",
          }}
        >
          <DashboardSideBar />
        </Grid>
        <Grid item xs={10.3}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <Grid
              item
              xs={8.5}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1.8rem",
              }}
            >
              <Grid item xs={11}>
                <SearchField />
              </Grid>
            </Grid>
            <Grid
              item
              xs={3.5}
              className="rightBarAllfilesMargin"
              sx={{
                marginTop: "1rem",
                paddingLeft: "2rem",
              }}
            >
              <RightBarAllFiles />
            </Grid>
          </Grid>
           {/* Profile Heading */}
          <Grid
          item
          xs={12}
          sx={{
            marginTop: "1rem",
            marginLeft: "3rem",
            justifyContent: "space-between",
          }}
          >
          <Typography
            sx={{
              color: " #FFFFFF",
              fontSize: "1rem",
              fontWeight: "700",
              fontFamily: "DM Sans",
            }}
          >
            Notes
          </Typography>
          </Grid>
          <Grid container direction="column" >
            <NoteEditor onNoteSave={handleNoteSave} />  
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Notes;
