import React, {useState, useEffect} from "react";
import { Grid, TextField, Button, Switch, Card, CardMedia, Typography, IconButton, Box } from "@mui/material";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import CustomizedTables from "../components/referralTables";


function Spartans() {
  const [totalInvites, setTotalInvites] = useState(0); // replace with actual data

  useEffect(() => {
    document.title = 'Synx | Spartans ⚔️';
    // Fetch total invites here and update state
  }, []); 

  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ backgroundColor: "primary.main", color: "white", minHeight: "100vh" }}>
        <Grid item xs={1.7} sm={1.7} md={1.7} lg={1.7} xl={1.7} sx={{ backgroundColor: "primary.light" }}>
          <DashboardSideBar />
        </Grid>
        {/* <Grid item xs={10.3} sm={10.3} md={10.3} lg={10.3} xl={10.3} sx={{ padding: 2, display: 'flex', flexDirection: 'row', marginTop: '1%'}} container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: " #FFFFFF",
                fontSize: "1rem",
                fontWeight: "700",
                fontFamily: "DM Sans",
              }}
            >
              Synx Spartan Dashboard ⚔️
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingY: 2, alignItems: 'center', backgroundColor: '#282832', borderRadius: '12px' }}>
              <Typography variant="h7" sx={{fontFamily: "DM Sans"}} gutterBottom component="div">
                Your Total Referrals
              </Typography>
              <Typography variant="h2" sx={{fontFamily: "DM Sans"}} component="div">
                {totalInvites}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingY: 2, alignItems: 'center', backgroundColor: '#282832', borderRadius: '12px' }}>
              <Typography variant="h7" sx={{fontFamily: "DM Sans"}} gutterBottom component="div">
                Your Total Points
              </Typography>
              <Typography variant="h2" sx={{fontFamily: "DM Sans"}} component="div">
                {totalInvites}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CustomizedTables />
          </Grid>
        </Grid> */}
      </Grid>
    </ThemeProvider>
  );
}

export default Spartans;
