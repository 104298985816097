import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Carousel from 'react-material-ui-carousel'
import { useNavigate } from "react-router-dom";
import icon1 from "../assets/Twitterwhite.png";
import icon2 from "../assets/Discord.png";
import headerlogo from "../assets/synxRightBarAllFiles.png";
import { JSEncrypt } from "jsencrypt";
import onboardingGif from '../assets/onboarding/ob3.gif';
import onboardingGifSuccess from '../assets/onboarding/ob8Success.gif';
import { CREATE_FREE_PLAN } from "../services/pricing_services";
import LinearProgress from '@mui/material/LinearProgress';
import AlertMessage from "../components/alertComponents/AlertMessage";
function VaultCreation(props) {
  const navigate = useNavigate();
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
    const [loading, setLoading] = useState(true);
    const [loaderVal, setLoaderVal] = useState(0);
    const [alertPopup, setAlertPopup] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("success");
    useEffect(() => {
      triggerCreateVault()
        
    setTimeout(function(){
      setLoaderVal(30)
    }, 2000)
    setTimeout(function(){
      setLoaderVal(60)
    }, 4000)
      }, []);
      const triggerCreateVault = async () => {
        const str_payload = localStorage.getItem('payload');
        const str_solana_keys = localStorage.getItem('solana_key');
        let userData = JSON.parse(str_payload)
        if(userData){
          var enc_secret_key = encrypt.encrypt(str_solana_keys);
          var e_uuid = encrypt.encrypt("d3fed1b534cf");
       
          let body = {
            "email": userData.email,
            "user_id":userData._id,
            "plan_id": "641c9b9e988f62657b51aa77",
            "id": userData._id,
            "secret_key":enc_secret_key,
            "storage_account_name": "Synx Test",
            "storage_size": "2GB",
            "vault_color": "Purple",
            "isEncrypted" : false,
            "taskSlug":"free_storage",
            "uuid" : e_uuid
        }
        console.log("body =>",body)
        CREATE_FREE_PLAN(body)
        .then(({ data }) => {
      if(data.status == 200){
        setLoaderVal(90)
        setTimeout(function(){
        
          setLoaderVal(100)
          setLoading(false)
          setAlertType("success")
          setAlertMessage("Success!   Think of your SYNX Vault as your cloud storage drive. You’re currently on the Free plan with 2GB of storage. To make changes to your storage plan, navigate to your Profile --> Pricing --> browse and select a new plan.")
          setAlertPopup(true)
          setTimeout(function(){
            window.location.href = '/Dashboard';
          }, 3000)
     
    }, 4000)
      }
          
        })
        .catch(function (error) {
          if(error?.response?.data?.message){
            setAlertType("error")
            setAlertMessage(error?.response?.data?.message)
            setAlertPopup(true)
        }
        setTimeout(function(){
          window.location.href = '/onboarding';
        }, 2000)
         
         });
        }else{
          setAlertType("error")
          setAlertMessage("create vault - session expired")
          setAlertPopup(true)
         }
     
    
    }  
  return (
    <Grid className="bgdark h-100vh">
        {alertPopup && <AlertMessage alertDisplay={alertPopup} type={alertType} message={alertMessage} />}

      <Navbar className="header pb-1" collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Grid className="container-fluid">
          <Navbar.Brand href="#home"> <img src={headerlogo} alt="headerLogo" /> </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="https://twitter.com/synx_xyz">
                <img src={icon1} alt="icon1" />
              </Nav.Link>
              <Nav.Link href="https://discord.gg/blxckout">
                <img src={icon2} alt="icon2" />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Grid>
      </Navbar>
      <Grid container style={{ height: '100vh' }} justify="center" alignItems="center">
      <Grid item xs={12} md={12} height={"70vh"} >
          <Grid container direction="column" alignItems="center" className="logo text-center">
              <img src={loading ? onboardingGif : onboardingGifSuccess} alt="onboarding" style={{ maxWidth: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
            {loading && <LinearProgress style={{width:"60%", marginTop:40}}  variant="determinate" value={loaderVal} sx={{
                  backgroundColor: '#777777',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#C4BEED'
                  }
                }} />}
              <Typography variant="h5" style={{ fontFamily: 'DM Sans', fontWeight: 700, paddingTop: 20}}>
                  Cooking up your private vault, please do not close this page.
              </Typography>
          </Grid>
      </Grid>
   
    </Grid>
    </Grid>
  );
}

export default VaultCreation;