import URL from '../components/url';
import axios from 'axios';

export const CREATE_NOTE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}notes/create-note`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};

export const UPDATE_NOTE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}notes/update-note`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};

export const DELETE_NOTE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}notes/delete-note`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};

export const LIST_NOTES = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}notes/user-notes`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};
