import React, {useState, useEffect} from "react";
import { Grid, Button } from "@mui/material";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import icon1 from "../assets/Twitterwhite.png";
import icon2 from "../assets/Discord.png";
import headerlogo from "../assets/synxRightBarAllFiles.png";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";



function Signup() {
  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate("/onboarding");
  };

return (

    <div className="bgdark h-100vh">
        <Navbar className="header pb-1" collapseOnSelect expand="lg" bg="dark" variant="dark">
        <div className="container-fluid">
        <Navbar.Brand href="#home"> <img src={headerlogo} alt="headerLogo"/> </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
            <Nav.Link href="https://twitter.com/synx_xyz">
                <img src={icon1} alt="icon1" />
            </Nav.Link>
            <Nav.Link href="https://discord.gg/blxckout">
                <img src={icon2} alt="icon2" />
            </Nav.Link>
            </Nav>
            </Navbar.Collapse>
            </div>
        </Navbar>

        <Grid item xs={12} md={11}>
          <div className="loginpage" style={{ padding: 0, marginTop: 100 }}>
            <div className="logo text-center">
            <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> 
              <img src={logo} alt="missingLogo" />
              </div>
              <p className="fw-bold" style={{ textAlign: "center" }}>Oh look, fresh users 👀</p>
              <p style={{ textAlign: "center", color: 'pink'}}>Fast & Secure Blockchain Storage </p>
              <p style={{ textAlign: 'center', fontSize: 16}}>
                Already have an account?&nbsp;
                <a href="/" style={{ color: 'pink' }}>Log In</a>
              </p>
            </div>
          </div>
        </ Grid>
        <div className="input-box">
        <Button
            className="buttonMarginRightBar"
            variant="contained"
            sx={{
            "&:hover": {
                backgroundColor: "#6C5DD3",
            },
            backgroundColor: "#6C5DD3",
            borderRadius: "8px",
            marginBottom: "1.2rem", 
            width: "300px", // Adjust the width as desired
            maxWidth: "300px", // Adjust the maximum width as desired
            textTransform: "capitalize",
            margin: "0 auto",
            display: "block",
            }}
            onClick={handleSignUp}
        >
            Sign Up
        </Button>
        </div>
    </div>
 );
}

export default Signup;