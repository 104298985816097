import React, {useEffect} from "react";
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
function AnnotationProgress({alertDisplay, loaderVal}) {
  const [showAlert, setShowAlert] = React.useState(alertDisplay ? alertDisplay : false);
  const triggerCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };
  return (
        <Snackbar open={showAlert} autoHideDuration={null} onClose={triggerCloseAlert}>
        <Alert severity="success" sx={{ width: '100%' }}>
        {loaderVal != 100 ? "Annotation in progress" : "Annotation complete" }
         <LinearProgress  variant="determinate" value={loaderVal} />
        </Alert>
      </Snackbar>
  );
}
export default AnnotationProgress;