import React, { useState, useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import mapboxgl from "mapbox-gl";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { GET_PROFILE } from "../services/solana_services";
import { GET_All_FILES, GET_FILES_PIN } from "../services/services";
import moment from "moment";

function Map() {
  const [userLocation, setUserLocation] = useState([0,0]);
  const [vaultsArray, setVaultsArray] = useState([]);
  const [filesArr, setFilesArr] = useState([]);
  const [map, setMap] = useState(null); 

  useEffect(() => {
    document.title = "Synx | Data Map 🗺️";
    mapboxgl.accessToken = "pk.eyJ1IjoibGotc3lueCIsImEiOiJjbGs3N2lqMnEwN3U1M3RwNnZ5ZzhpeXhoIn0.myOaEPlJipHQCxQQByOiaQ";

    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then(function (result) {
        console.log("result => ",result);
        if (result.state === "granted") {
          trackUserLocation();
        } else if (result.state === "prompt") {
          const requestLocationBtn = document.createElement("button");
          requestLocationBtn.textContent = "Allow location access";
          // requestLocationBtn.addEventListener("click", () => {
            trackUserLocation();
          // });
          document.getElementById("map").appendChild(requestLocationBtn);
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
console.log("userLocation =>",userLocation)

    fetchBalances();
  }, []);

  const trackUserLocation = () => {
    console.log("User location tracking is active.");
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        console.log("position =>",position)
        setUserLocation([longitude, latitude]);
        const map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v12",
          center: [parseFloat(longitude).toFixed(4),parseFloat(latitude).toFixed(4)],
          zoom: 9,
        });
        setMap(map); 
      },
      (error) => {
        console.log("Geolocation permission denied:", error);
      },
      { enableHighAccuracy: true }
    );
  };

  const fetchBalances = async () => {
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload);
    let get_profile_body = { "email": payload.email, "name": payload.username, "typeOfLogin": payload.typeOfLogin };
    GET_PROFILE(get_profile_body)
      .then(({ data }) => {
        let vaults_array = data.data.payload.user_profile.vaults;
        const publicVaultsArr = vaults_array.filter((item) => { return item.isEncrypted === false });
        if (publicVaultsArr.length > 0) {
          setVaultsArray(publicVaultsArr);
          getStorageList(payload._id, payload.email, publicVaultsArr);
        }
      })
      .catch(async function (error) {
        // Handle error
      });
  };

  const getStorageList = async (user_id, user_email, publicVaultsArr) => {
    let body = {
      "id": user_id,
      "email": user_email,
    };
    GET_FILES_PIN(body)
      .then(({ data }) => {
        if (data.status === 200) {
          let payload = data.data.payload;
          let files_arr = [];
          if (payload.length > 0) {
            console.log("payload =>",payload)
            payload.map((item, index) => {
              let vaultData = publicVaultsArr.find(vaultItem => vaultItem.vaultaddress === item.vaultAddress);
              if (vaultData) {
                if (item.files) {
                  if (item.files.length > 0) {
                    item.files.forEach((element, i) => {
                      element.storage_address = item.vaultAddress;
                      files_arr.push(element);
                    });
                  }
                }
              }
            });
          }
          if (files_arr.length > 0) {
            const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.heic', '.avif'];
            let dataFiles = files_arr.filter(file => {
              const extension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
              return imageExtensions.includes(extension);
            });
            dataFiles = dataFiles.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
            console.log("dataFiles =>",dataFiles)
            setFilesArr(dataFiles);
          }
        }
      })
      .catch(async function (error) {
        if (error?.response?.data?.message) {
          // Handle error
        }
      });
  };

  useEffect(() => {
    const addMarkersToMap = () => {
      if (map && filesArr.length > 0) {
        filesArr.forEach((file, index) => {

  const { long, lat } = file;
  if (long && lat) {
    console.log("FILE => ",file)
    var el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = 'url(https://shdw-drive.genesysgo.net/7RwSs3vo3bP3LZ1n3ifBzrtq2wF2CB1fSKSfBMng2HBi/lock-image-marker.png)';
    el.style.width = '50px';
    el.style.height = '50px';

    new mapboxgl.Marker(el)
      .setLngLat([long, lat])
      .setPopup(
        new mapboxgl.Popup({ offset: 25 })
          .setHTML(
            `
            <img src="${file.filePath}" alt="Avatar" style="width:100%">
            <div style="padding: 2px 16px;">
              <p style="color: #000;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 200px; margin-bottom:0px">${file.name}</p>
              <p style="color: #000;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 200px; margin-bottom:0px">${lat},${long}</p>
              <p style="color: #000;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 200px; margin-bottom:0px">${moment(file.last_modified).format("DD-MM-YYYY")}</p>
            </div>
          `
          )
      )
      .addTo(map);
  }
         
        });
      }
    };

 
    addMarkersToMap();
  }, [filesArr]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ backgroundColor: "primary.main", color: "white", minHeight: "100vh" }}>
        <Grid item xs={1.7} sx={{ backgroundColor: "primary.light" }}>
          <DashboardSideBar />
        </Grid>

        <Grid item xs={10.3}>
          <div id="map" style={{ position: "relative", height: "100vh" }} />
        </Grid>
        <IconButton
          // onClick={handleRightModalOpen}
          style={{
            position: 'absolute',
            top: '3%',
            right: '2%',
            color: '#FFFFFF',
            backgroundColor: '#6C5DD3',
          }}
          aria-label="Info Icon"
        >
          <PermMediaIcon />
        </IconButton>
      </Grid>
    </ThemeProvider>
  );
}

export default Map;
