import { Route, Routes } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import "./App.css";
import DashBoard from "./pages/dashBoard";
import AllFiles from "./pages/allFiles";
import Collectibles from "./pages/collectibles";
import { Login } from "./pages/login.tsx";
import { Navigate } from "react-router-dom";
import Protected from "./components/protected";
import ProtectedLogin from "./components/protectedLogin";
import Profile from "./pages/profile";
import DataTasks from "./pages/dataTasks";
import Notes from "./pages/notes";
import Spartans from "./pages/spartans";
import Signup from "./pages/signup";
import Leaderboard from "./pages/leaderboard";
import DataSubmit from "./pages/dataSubmit";
import ComingSoon from "./pages/comingSoon";
import { Analytics } from '@vercel/analytics/react';
import WhoAreWe from "./pages/onboarding";
import Album from './pages/album';
import VaultCreation from "./pages/vaultCreation";
import { ButtonGroup } from "react-bootstrap";
import Map from "./pages/map";
import ErrorProvider from './store/ErrorProvider.tsx';
import SolanaWalletProvider from './store/SolanaWalletProvider.tsx';
import { AuthProvider } from './store/AuthProvider.tsx';

function App() {
  useEffect(() => {
  }, []);
  return (
    <>
      <SolanaWalletProvider>
      <ErrorProvider>
        <AuthProvider>
        <Analytics />
      <Routes>
        
        <Route path="/" element={  <ProtectedLogin>
          <Login />
            </ProtectedLogin>} />
        <Route
          path="/Dashboard"
          index
          element={
            <Protected>
              <DashBoard />
            </Protected>
          }
        />
        <Route
          path="/files"
          element={
            <Protected>
              <AllFiles />
            </Protected>
          }
        />
         <Route
          path="/collectibles"
          element={
            <Protected>
              <Collectibles />
            </Protected>
          }
        />
         <Route
          path="/leaderboard"
          element={
            <Protected>
              <Leaderboard />
            </Protected>
          }
        />
         <Route
          path="/profile"
          element={
            <Protected>
              <Profile />
            </Protected>
          }
        />
         <Route
          path="/data"
          element={
            <Protected>
              <DataTasks />
            </Protected>
          }
        />
         <Route
          path="/notes"
          element={
            <Protected>
              <Notes />
            </Protected>
          }
        />
         <Route
          path="/signup"
          element={
              <Signup />
          }
        />
         <Route
          path="/onboarding"
          element={
              <WhoAreWe />
          }
        />
         <Route
          path="/vaultcreation"
          element={
              <VaultCreation />
          }
        />
         <Route
          path="/map"
          element={
            <Protected>
              <Map />
            </Protected>
          }
        />
         <Route
          path="/submit"
          element={
            <Protected>
              <DataSubmit />
            </Protected>
          }
        />
          <Route
          path="/comingsoon"
          element={
            <Protected>
              <ComingSoon />
            </Protected>
          }
        />
          <Route
          path="/spartans"
          element={
            <Protected>
              <Spartans />
            </Protected>
          }
        />
         <Route
          path="/album"
          element={
            <Protected>
              <Album />
            </Protected>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
        {/* <Route path="/" element={<Protected Component={Auth} />} /> */}
      
      
      <Route path="ButtonGroup" element={<Navigate to="/ButtonGroup" />} />
        {/* <Route path="/" element={<Protected Component={Auth} />} /> */}
      </Routes>
        </AuthProvider>
   
      </ErrorProvider>
      </SolanaWalletProvider>
     
    </>
  );
}

export default App;


