import React, { useState, useEffect } from "react";
import { Grid, Typography, Drawer } from "@mui/material";
import synxRightBarAllFiles from "../assets/synxRightBarAllFiles.png";
import PersonalIconImg from "../assets/personal.png";
import SynxWalletIcon from "../assets/SynxWalletIcon.png";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { dashBoardRightSideBar } from "../constants/appConstants";
import Divider from '@mui/material/Divider';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import moment from "moment";
import copy_24px from "../assets/copy_24px.png";
import solana_logo from "../assets/solana_logo.png";
import charts from "../assets/charts.png";
import genesysgo_logo from "../assets/genesysgo_logo.png";
import buyIcon from '../assets/manageWallet/buy.png';
import ReceiveCash from '../assets/manageWallet/ReceiveCash.png';
import sendIcon from '../assets/manageWallet/send.png';
import swapIcon from '../assets/manageWallet/swap.png';
import { GET_CONVERSION_RATE, GET_PERCENT_CHANGE, GET_PERCENT_RAISE, GET_SHADOW_BALANCE, GET_SOLANA_BALANCE, GET_USDC_BALANCE } from "../services/solana_services";
import { FaCopy, FaShare,FaTimes } from 'react-icons/fa';
import QR from "../assets/qr.png"
import { Col, Row } from "react-bootstrap";


// This also contains wallet right modal

function WalletDrawer({open,close}) {
  const { UPLOADTEXT, WALLET } = dashBoardRightSideBar;
  const [copied, setCopied] = useState(false);
  const [showHoldings, setShowHoldings] = useState(true);  // Initially show the holdings
  const [uploadDrawerOpen, setUploadDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [solanaPublicAddress, setSolanaPublicAddress] = useState("")
  const [smallSolanaAddress, setShortSolanaPublicAddress] = useState("azaz")
  
  const [userEmail, setUserEmail] = useState("")
  const [solanaBalance, setSolanaBalance] = useState(0)
  const [shadowBalance, setShadowBalance] = useState(0)
  const [usdcBalance, setUsdcBalance] = useState(0)
  
  const [solInUSD, setSolInUSD] = useState(0)
  const [shdwInUSD, setShdwInUSD] = useState(0)
  const [usdcInUSD, setUSDCInUSD] = useState(0)
  
  const [hasCopied, setHasCopied] = useState(false)
  const [solChange, setSolChange] = useState(0)
  const [shdwChange, setShdwChange] = useState(0)
  const [usdChange, setUsdChange] = useState(0)
  const [refreshing, setRefreshing] = useState(false);

  const [solPercentChange, setSolPercentChnage] = useState(0);
  const [shdwPercentChange, setShdwPercentChnage] = useState(0);

  const [menuDisplay, setMenuDisplay] = React.useState(false);
  const [menuTransferDisplay, setMenuTransferDisplay] = React.useState(false);
  const [activeButton, setActiveButton] = useState("");

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  useEffect(() => {
    getData();
    get_percent_change("solana")
    get_percent_change("genesysgo-shadow")
    get_conversions();
  

  }, [])

  const getData = async () => {
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload)
let keyVal = payload.solanaaccount;
const first5 = keyVal.slice(0, 5);
var last5 = keyVal.substr(keyVal.length - 5);
let sol_ADDR = first5+"....."+last5;

    setShortSolanaPublicAddress(sol_ADDR)
    setSolanaPublicAddress(payload.solanaaccount)
    setUserEmail(payload.email)

    // getSolanaBalance(payload.solanaaccount,payload.email)
    // getShadowBalance(payload.solanaaccount,payload.email)
    // getUsdcBalance(payload.solanaaccount,payload.email)
   
  

  }

  const handleCopyClick = () => {
    // Logic to handle copy button click
    // ...
  };

  const handleShareClick = () => {
    // Logic to handle share button click
    // ...
  };
  const get_conversions  = () => {
    GET_CONVERSION_RATE("SOL")
    .then(({ data }) => {

      setSolInUSD(data?.data?.SOL?.price)

      get_percent_gain("solana",data?.data?.SOL?.price);
    })
    .catch(function (error) {
     // // console.log(error)
     });

     GET_CONVERSION_RATE("SHDW")
     .then(({ data }) => {
       setShdwInUSD(data?.data?.SHDW?.price)
      //  console.log("SHADOW in USD =>", data?.data?.SHDW?.price)
       // console.log("SHDW Price Today >>",data?.data?.SHDW?.price)

       get_percent_gain("genesysgo-shadow",data?.data?.SHDW?.price);
     })
     .catch(function (error) {
      // // console.log(error)
      });
      GET_CONVERSION_RATE("USDC")
      .then(({ data }) => {
        setUSDCInUSD(data?.data?.USDC?.price)

        get_percent_gain("usd-coin",data?.data?.USDC?.price);
        // setTimeout(function(){ get_percent_gain();}, 1000)
      })
      .catch(function (error) {
       // // console.log(error)
       });
  }
  const get_percent_gain  = (coin,current_price) => {
    const date = moment()
    .startOf("day")
    .subtract(1, "day")
    .format("DD-MM-YYYY");
    GET_PERCENT_RAISE(coin,date)
    .then(({ data }) => {
      if(data){
        if(data.id == "solana"){
          let old_price = data?.market_data?.current_price?.usd

let percentChangeSol = (((current_price - old_price) / old_price) * 100).toFixed(2)

setSolChange(percentChangeSol)
        }
        if(data.id == "genesysgo-shadow"){
          let old_price = data?.market_data?.current_price?.usd
          // console.log("SHDW Price on "+date+">> ",old_price)

let percentChangeShdw = (((current_price - old_price) / old_price) * 100).toFixed(2)
// console.log("SHDW Price Percentage Change =>",percentChangeShdw)

setShdwChange(percentChangeShdw)
        }
        if(data.id == "usd-coin"){
          let old_price = data?.market_data?.current_price?.usd
let percentChangeUsdc = (((current_price - old_price) / old_price) * 100).toFixed(2)
setUsdChange(percentChangeUsdc)
        }
      }
     
    })
    .catch(function (error) {
     // // console.log(error)
     });
  

    
  }
  const get_percent_change  = (coin) => {
    GET_PERCENT_CHANGE(coin)
    .then(({ data }) => {
     if(coin == "solana"){
      setSolPercentChnage(data[0].price_change_percentage_24h)
     }else{
      setShdwPercentChnage(data[0].price_change_percentage_24h)

     }
    })
    .catch(function (error) {
     // // console.log(error)
     });
  

    
  }
  const getSolanaBalance = (solana_public, email) => {
    let body = {
      "address": solana_public,
      "email":email
  };
 
    GET_SOLANA_BALANCE(body)
    .then(({ data }) => {
      // // console.log("data",data)
      setSolanaBalance(data.data.payload)
    })
    .catch(function (error) {
      if (error.response) {
        // // console.log(error?.response?.data?.message)
      //  alert("Please check internet connection")
      if(error?.response?.data?.message == "Unauthorized! Access Token expired or invalid!"){
        // localStorage.clear()
        
      }
      }else{
       console.log(error)
      }
     });
  }
  const getShadowBalance = (solana_public, email) => {
    let body = {
      "address": solana_public,
      "email":email
  };
  // // console.log("shadow Body", body)
    GET_SHADOW_BALANCE(body)
    .then(({ data }) => {
      // // console.log("shadow data")
      setShadowBalance(data.data.payload)
    })
    .catch(function (error) {
      if (error.response) {
        // // console.log(error.response.data)
        if(error?.response?.data?.message == "Unauthorized! Access Token expired or invalid!"){
          // localStorage.clear()
        
        }
      //  alert("Please check internet connection")
      }else{
        // // console.log(error)
      }
     });
  }
  const getUsdcBalance = (solana_public, email) => {
    let body = {
      "address": solana_public,
      "email":email
  };
  // // console.log("shadow Body", body)
    GET_USDC_BALANCE(body)
    .then(({ data }) => {
      // // console.log("shadow data")
      setUsdcBalance(data.data.payload)
    })
    .catch(function (error) {
      if (error.response) {
        // // console.log(error.response.data)
        if(error?.response?.data?.message == "Unauthorized! Access Token expired or invalid!"){
          // localStorage.clear()
         
        }
      //  alert("Please check internet connection")
      }else{
        // // console.log(error)
      }
     });
  }
  const toggleHoldings = () => {
    setShowHoldings(!showHoldings);  // Toggle the visibility of the holdings on click
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(smallSolanaAddress);
    setCopied(true);
  }

  const handleSnackbarClose = () => {
    setCopied(false);
  };

  // Need to slide overlay here
  const profileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const profileClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    close(); // Close the modal
  };

  

  
  const uploadClick = () => {
    setUploadDrawerOpen(true); // Open the drawer
  };

  const handleUploadDrawerClose = () => {
    setUploadDrawerOpen(false); // Close the drawer
  };

  let holdings = [
    {
      logo: solana_logo,
      name: 'Solana',
      amount: `${solanaBalance.toFixed(2)} SOL`,
      price: `$${(solInUSD * solanaBalance).toFixed(2)}`,
      percentage: solChange > 0 && "+"+solChange+"%",
    },
    {
      logo: genesysgo_logo,
      name: 'Shadow',
      amount: `${shadowBalance.toFixed(2)} SHDW`,
      price: `$${(shdwInUSD * shadowBalance).toFixed(2)}`,
      percentage: shdwChange > 0 && "+"+shdwChange+"%",
    }
  ];
  const getContent = (buttonName) => {
    switch (buttonName) {
      case "Receive":
        return  <div className="text-center button1 bgclr">
        <p className="text-end"><FaTimes onClick={() => setActiveButton(null)} /></p>
        <div className="w-85">
            <h4>Recieved</h4>
              <img src={QR}/> 
              <p className="f-18 py-3"> Scan QR or copy address below to <br/> recieve payment</p>
              <div>
                <button className="copybtn" onClick={handleCopyClick}>
                   Copy <FaCopy />
                </button>
                <button className="sharebtn" onClick={handleShareClick}>
                   Share <FaShare />
                </button>
              </div>
              </div>
        </div>;
      case "Send":
        return <div className="text-center bgclr">
          <p className="text-end"><FaTimes  onClick={() => setActiveButton(null)} /></p>
          <div className="w-85">
            <h4>Send</h4>
            <div className="w-90">
              <Row>
                <Col xs={12} md={6} lg={4} className="cyptoicon">
                  <img src={QR} alt="Image" className="sendcyptoicon" />
                  <div className="">
                    <span><strong>Solana</strong></span>
                    <br />
                    <span className="text-gray">73 Sol</span>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={8}>
                  <div className="text-end">
                    <span>$6,770</span>
                    <br />
                    <span className="text-success">+2.89%</span>
                  </div>
                </Col>
              </Row>
              <hr className="my-2"/>
              <Row>
                <Col xs={12} md={6} lg={4} className="cyptoicon">
                  <img src={QR} alt="Image" className="sendcyptoicon" />
                  <div className="">
                    <span><strong>Solana</strong></span>
                    <br />
                    <span className="text-gray">73 Sol</span>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={8}>
                  <div  className="text-end">
                    <span>$6,770</span>
                    <br />
                    <span className="text-success">+2.89%</span>
                  </div>
                </Col>
              </Row>
              <hr className="my-2"/>
              <Row>
                <Col xs={12} md={6} lg={4} className="cyptoicon">
                  <img src={QR} alt="Image" className="sendcyptoicon" />
                  <div className="">
                    <span><strong>Solana</strong></span>
                    <br />
                    <span className="text-gray">73 Sol</span>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={8}>
                  <div  className="text-end">
                    <span>$6,770</span>
                    <br />
                    <span className="text-success">+2.89%</span>
                  </div>
                </Col>
              </Row>
              <hr className="my-2"/>
            </div>
            <p>Select token you want to transfer</p>
          </div>
          
          </div>
      ;
      
      default:
        return null;
    }
  };
  return (
    <>
     
     <Drawer
        anchor='right' 
        open={open}
        onClose={handleClose}
        sx={{
          width: '25%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '25%',
            backgroundColor: '#1E1E25',
          },
        }}
      >
        {/* Header icons for side drawer */}
        <div style={{ width: '25vw', color: '#FFFFFF' }}> 
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              paddingTop: '1em', // Add padding to the top
              paddingLeft: '1em',
            }}
          >
            <Grid
              item
              xs={5.9}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                className="logoRightBarAllFiles"
                src={synxRightBarAllFiles}
                alt="synxRightBarAllFiles"
                style={{ height: '71px', width: '76px' }}
              />
            </Grid>
          
          </Grid>

          {/* Divider for rest of drawer content */}
          <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)', my: 2 }} />
          
        <Grid
          container
          direction="row"
          justifyContent="leading"
          alignItems="leading"
          sx={{ color: '#FFFFFF', marginBottom: '1em' }}  // Set the color and margin as needed
          onClick={handleClose} // Add the onClick event listener here
        >
          <Typography variant="h6" component="div" 
          sx={{
            fontSize: '1rem',
            color: 'rgba(255, 255, 255, 0.5)',
            fontFamily: 'DM Sans',
            paddingLeft: 2,
          }}>
            Minimize
          </Typography>
          <ArrowForwardIosIcon sx={{ color: '#A79EE5' }} /> 
        </Grid>

          {/* Header */}
          <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: '1em',
            marginBottom: '1em',
          }}
        >
          <Typography
            component="h2"
            variant="h5"
            sx={{
              color: '#fff',
              fontFamily: 'DM Sans',
              fontWeight: 'BOLD',
            }}
          >
            ${((shdwInUSD*shadowBalance) + (solInUSD*solanaBalance)).toFixed(2)}
          </Typography>
        </Grid>

         {/* Solana and SHDW balances should go here */}
         <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: '12px',
            width: '100%',
            flexWrap: 'nowrap',
            marginLeft: '-1rem',
          }}
        >
        <Grid item container direction="row" alignItems="center" xs={5} justifyContent="flex-end">
          <img src={solana_logo} alt="img1" style={{ marginRight: '8px', width: '16px', height: '16px'}}/>
          <Typography   
            sx={{
              fontSize: '1rem',
              color: 'rgba(255, 255, 255, 0.8)',
              fontFamily: 'DM Sans',
            }}
          >
            {solanaBalance.toFixed(2)} SOL
          </Typography>
        </Grid>

        <Grid item container direction="row" alignItems="center" paddingLeft={2} xs={5} justifyContent="flex-start">
          <img src={genesysgo_logo} alt="img2" style={{ marginRight: '8px', width: '16px', height: '16px'}}/>
          <Typography   
            sx={{
              fontSize: '1rem',
              color: 'rgba(255, 255, 255, 0.8)',
              fontFamily: 'DM Sans',
            }}
          >
            {shadowBalance.toFixed(2)} SHDW
          </Typography>
        </Grid>
      </Grid>


        {/* Wallet address and copy/paste */}
        <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{
          marginTop: '1.5em', // This can be adjusted based on your needs
        }}
      >
          <div onClick={copyToClipboard} style={{ width: '39%', justifyContent: 'center', backgroundColor: '#1E1E25', padding: '12px', flexDirection: 'row', borderRadius: '5px', display: 'flex', alignItems: 'center', cursor: 'pointer',   backgroundColor:"#282832"  }}>
            <Typography component="div" 
              sx={{
                fontSize: '1rem',
                color: 'FFFFFF',
                fontFamily: 'DM Sans',
                paddingLeft: 1,
                paddingRight: 1,
              }}>
             {smallSolanaAddress}
            </Typography>
            <img src={copy_24px} alt='copy-icon' style={{ width: '16px', height: '16px', objectFit: 'contain', alignSelf: 'center', filter: 'grayscale(80%)'}} />
          </div>
        </Grid>

        {/* Buttons for buy, sell, receive, trade */}
        
        <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ 
          width: '25vw',
          paddingTop: '2rem',
        }}
      >
        {[
          { text: 'Buy', icon: buyIcon },
          { text: 'Receive', icon: ReceiveCash },
          { text: 'Send', icon: sendIcon },
          { text: 'Swap', icon: swapIcon },
        ].map(({ text, icon }) => (
          <Grid item xs={3} container direction="column" alignItems="center">
            <Button
              variant="contained"
              style={{
                height: '8vh',
                width: '8vh',
                borderRadius: '50%',
                backgroundColor: '#6C5DD3',
              }}
              onClick={() => handleClick(text)}
            >
              <img
                src={icon}
                alt={`${text} icon`}
                style={{ width: '30px', height: '30px' }}
              />
            </Button>
            <Typography
              variant="body1"
              style={{
                marginTop: 15,
                fontSize: 13,
                fontWeight: 'bold',
                color: '#fff',
              }}
            >
              {text}
            </Typography>
          </Grid>
        ))}
      </Grid>

 
      {activeButton == "Send" || activeButton == "Receive" ? getContent(activeButton) : (
     <>
             {/* Holdings */}
             <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ color: '#FFFFFF', marginBottom: '1em', marginTop: '2.5em'}}
      >
        <Grid item>
          <Typography 
            variant="h6" 
            component="div"
            sx={{
              fontSize: '1rem',
              color: 'rgba(255, 255, 255)',
              fontFamily: 'DM Sans',
              paddingLeft: 2,
              fontWeight: 'bold', 
            }}
          >
            Holdings
          </Typography>
        </Grid>
        
        <Grid item
         sx={{
          paddingRight: 1,
        }}>
          <KeyboardArrowUpOutlinedIcon sx={{ color: '#FFFFFF', marginLeft: '1em' }}  onClick={() => setShowHoldings(!showHoldings)}  />
        </Grid>
      </Grid>

       {/* Each holding value */}

       <Grid 
       container
       sx={{ padding: '0px 16px' }}  // Add horizontal padding
     >
     {holdings.map((holding, index) => (
       <Grid 
         container 
         direction="row" 
         sx={{ 
           backgroundColor: '#282832', 
           borderRadius: '5px', 
           padding: '10px', 
           marginTop: index === 0 ? '5px' : '10px',
         }}
         key={index}
       >
         <Grid item xs={2} container alignItems="center" justifyContent="center">
           <img src={holding.logo} alt={`${holding.name} Logo`} style={{ width: '40px', height: '40px', objectFit: 'contain' }}/>
         </Grid>

         <Grid item xs={5} container direction="column" justifyContent="center">
           <Typography
             sx={{ fontSize: '1rem', color: '#fff', fontFamily: 'DM Sans', fontWeight: 'bold' }}
           >
             {holding.name}
           </Typography>
           <Typography
             sx={{ fontSize: '0.9rem', color: '#c9c9c9', fontFamily: 'DM Sans', fontWeight: 'bold' }}
           >
             {holding.amount}
           </Typography>
         </Grid>

         <Grid item xs={5} container direction="column" justifyContent="center" alignItems="flex-end">
           <Typography
             sx={{ fontSize: '1rem', color: '#fff', fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'right' }}
           >
             {holding.price}
           </Typography>
           <Typography
             sx={{
               fontSize: '0.9rem',
               fontFamily: 'DM Sans',
               fontWeight: 'bold',
               textAlign: 'right',
               color: "#10B981",
             }}
           >
             {holding.percentage}
           </Typography>
         </Grid>
       </Grid>
     ))}
   </Grid>
   
   {/* Image component for viewing charts */}
   <Grid
     container
     sx={{ 
       padding: '5px 5px',
       mt: 1,
       mb: 1
     }}
   >
     <img 
       src={charts} 
       alt="Chart" 
       style={{
         maxWidth: '30%',
         maxHeight: '30%',
         objectFit: 'contain',
         margin: 'auto',
         display: 'block'
       }}
     />
   </Grid>
     </>
  
      )}

       

      <Grid 
        container
        justifyContent="center"
        sx={{ 
          padding: '5px 5px',
        }}
      >
        <Typography 
          sx={{ 
            fontSize: '1rem', 
            color: '#fff', 
            fontFamily: 'DM Sans', 
            textAlign: 'center'
          }}
        >
        </Typography>
      </Grid>


        {/* Alerts copy to clipboard */}
        <Snackbar open={copied} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Copied to clipboard!
          </Alert>
        </Snackbar>

        </div>
      </Drawer>

    </>
  );
}

export default WalletDrawer;
