import React, {useState,useEffect}from "react";

import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import { Grid } from "@mui/material";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import DashboardRightSideBar from "../components/dashboardRightSideBar";
import SearchField from "../components/searchField";
import OverViewSection from "../components/mainBodySection/overViewSection";
import RecentlyEditedSection from "../components/mainBodySection/recentlyEditedSection";
import UploadFileSection from "../components/mainBodySection/uploadFileSection";
import InviteFriendsModal from "../components/inviteFriendsBanner";

function DashBoard() {
  useEffect(() => {
    document.title = 'Synx | Dashboard 📊';
    const str_currentVault = localStorage.getItem('currentVault');
    if(str_currentVault){
      setVaultIndex(parseInt(str_currentVault))
    }
  }, []); 
  const [vaultIndex, setVaultIndex] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const [fileCounts, setFileCounts] = useState({
    "documents" : 0,
    "media" : 0,
    "others" : 0,
    "unknown" : 0
  });
  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    setTimeout(function(){setRefreshing(false)}, 2000)
  }, [refreshing]);
  const handleChangeVault = (event) => {
    
    setVaultIndex(event.target.value)
  } 

  return (
    <ThemeProvider theme={theme}>
      <InviteFriendsModal />
      <Grid
        container
        item
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          minheight: "100vh",
        }}
      >
        <Grid
          item
          xs={1.7}
          sx={{
            backgroundColor: "primary.light",
          }}
        >
          <DashboardSideBar updateData={onRefresh} />
        </Grid>

        <Grid
          item
          xs={7.3}
          sx={{
            marginTop: "2.1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={11}>
            <SearchField searchable={false} />
            <OverViewSection fileCounts={fileCounts} refreshing={refreshing} />
            <RecentlyEditedSection vaultIndexVal={vaultIndex}  refreshing={refreshing} setFileCounts={setFileCounts}  />
            
            <UploadFileSection updateRecentFiles={onRefresh} onRefreshDrawerRecents={() => {console.log("updated")}} handleCloseUpload={() => {console.log("updated")}}  />
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            backgroundColor: "primary.light",
          }}
        >
          <DashboardRightSideBar handleChangeVault={handleChangeVault} updateAllData={onRefresh}  />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default DashBoard;
