import { useState } from 'react';
import ErrorContext from './error-context.ts';

const ErrorProvider = (props: any) => {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [isShown, setIsShown] = useState(false);

  const setContextTitle = (newTitle: string) => setTitle(newTitle);

  const setContextMessage = (newMessage: string) => setMessage(newMessage);

  const showErrorMessage = () => {
    setIsShown(true);
    setTimeout(() => setIsShown(false), 3000);
  };

  const errorContext = {
    title: title,
    setTitle: setContextTitle,
    message: message,
    setMessage: setContextMessage,
    shown: isShown,
    setShown: showErrorMessage,
  };

  return <ErrorContext.Provider value={errorContext}>{props.children}</ErrorContext.Provider>;
};

export default ErrorProvider;
