


export const dashBoardLeftSideBar = {
  SYNXHEADING: "My Synx",
  DASHBOARDTEXT: " Dashboard",
  ALLFILESTEXT: "Files",
  RECENTFILES: "Recent Files",
  DIGITALCOLLECTIBLES: " Collectibles",
  REWARDSHUB: "Leaderboard",
  PROFILE: "Profile",
  TOOLSHEADING: "Ecosystem Tools",
  SYNXSEND: "Synx Market",
  MINT: "Synx Map",
  ALBUM: "Synx Memories",
  SPARTANS: "Synx Spartans",
  GENERALHEADING: "General",
  SETTINGS: "Settings",
  SUPPORT: "Support",
};

export const dashBoardRightSideBar = {
  UPLOADTEXT: "Upload",
  WALLET: "Wallet",
  GOPREMIUM: "Download mobile app",
  UPGRADETEXT: "Sync your files across devices.",
  UPGRADEBUTTONTEXT: "Download now",
};
export const overViewSection = {
  OVERVIEWHEADING: "Overview",
  DOCS: "Docs",
  DOCSITEMSDATA: "117 items",
  IMAGES: "Media",
  IMAGESITEMDATA: "868 items",
  VIDEOS: "Others",
  VIDEOSITEMSDATA: "89 items",
  AUDIO: "Unknown",
  AUIOITEMSDATA: "26 items",
};
export const recentlyEditSection = {
  RECENTLYEDITHEADING: "Recently Uploaded",
  IMAGEPNG: "image.png",
  EDITMINUTES: "Edited 8m ago",
  FILESDOCS: "file.docx",
  FILEXLS: "file.xlsx",
};
export const UploadSection = {
  UPLOADHEADING: "Upload",
  DRAGANDDROP: "Drag and drop files, or Browse",
  SYNXSUPPORTEXT: "SYNX supports all file types",
};
export const pinnedDataSection = {
  PINNEDHEADING: "Pinned",
  HIDETEXT: "Hide",
  NAMEEXT: "Name.ext",
  DATEEXT: "Feb 3, 2023",
};
export const nftDataSection = {
  COLLECTIBLEHEADING: "Digital Collectibles",
  HIDETEXT: "Hide",
  COLLECTIONNAME: "Survivors NFT",
  FLOORPRICE: "6.9 SOL",
};
export const rewardsHubHeaderSection = {
  USERNAME: "Olly Owl",
  PAGETITLE: "Rewards Hub",
  LEVEL_SMALL: "2",
  LEVEL_LONG: "Level 2",
  POINTS_TO_NEXT: " 500 points to next level!",
  CURRENT_LEVEL: "2",
  NEXT_LEVEL: "3",
  UPGRADE: "Upgrade",
};
export const rewardsHubSection = {
  // Add when completing rewards hub
};
