import React from "react";

import { Grid, Box, Typography } from "@mui/material";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { PieChart } from "react-minimal-pie-chart";

function TextSectionRightBar1({
  docsValue,
  mediaValue,
  othersValue,
  unknownValue,
  availStorage,
  gbBold,
  totalStorage,
  valUtilized,
  docPieValue,
  picPieValue,
  vidPieValue,
  otherPieValue,
}) {
  return (
    <>
      <Grid
        item
        xs={10}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            margin: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PieChart
            className=" pieChartImage "
            style={{
              height: "9rem",
              width: "9rem",
            }}
            background="#32323E"
            startAngle={80}
            totalValue={100}
            lineWidth={25}
            rounded
            data={[
              { title: "Others", value: otherPieValue, color: "#FFB5CD" },
              { title: "Videos", value: vidPieValue, color: "#A0F3E2" },
              { title: "Pictures", value: picPieValue, color: "#4D85E6" },
              { title: "Documents", value: docPieValue, color: "#897DDC" },
            ]}
          />
          <Typography
            className="textPieTop"
            sx={{
              position: "absolute",
              top: "3.5rem",
              fontSize: "1.8rem",
              fontWeight: "700",
              fontFamily: "DM Sans",
            }}
          >
            {valUtilized}
          </Typography>
          <Typography
            className="textPieTopUtilize"
            sx={{
              position: "absolute",
              top: "6rem",
              fontSize: "0.8rem",
              fontWeight: "500",
              fontFamily: "DM Sans",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
            Utilized
          </Typography>
        </Box>
        <Box
          className="boxHeight"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "2rem",
          }}
        >
          <Typography
            className="availStorageText"
            sx={{
              fontSize: "1rem",
              fontWeight: "500",
              fontFamily: "DM Sans",
            }}
          >
            Available Storage
          </Typography>
        </Box>
        <Box
          className="boxHeight"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "2rem",
          }}
        >
          <Typography
            className="gbData"
            sx={{
              fontSize: "1.1rem",
              fontWeight: "700",
              fontFamily: "DM Sans",
            }}
          >
            {availStorage}&nbsp;/&nbsp;
            {totalStorage}
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "0.5rem",
          marginBottom: "1rem",
        }}
      >
        <Grid item xs={5.5}>
          <Box
            className="boxHeight"
            sx={{
              height: "2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              className="buttonText"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              <FiberManualRecordIcon
                className="buttonIcon"
                sx={{
                  color: "#897DDC",
                  display: "inline-flex",
                  verticalAlign: "middle",
                  fontSize: "1rem",
                  fontWeight: "500",
                  marginRight: "0.3rem",
                }}
              />
              Documents
            </Typography>
          </Box>
          <Box
            className="boxHeight"
            sx={{
              height: "2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              className="buttonText"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              <FiberManualRecordIcon
                className="buttonIcon"
                sx={{
                  color: "#4D85E6",
                  display: "inline-flex",
                  verticalAlign: "middle",
                  fontSize: "1rem",
                  fontWeight: "500",
                  marginRight: "0.3rem",
                }}
              />
              Media
            </Typography>
          </Box>
          <Box
            className="boxHeight"
            sx={{
              height: "2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              className="buttonText"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              <FiberManualRecordIcon
                className="buttonIcon"
                sx={{
                  color: "#88F0DB",
                  display: "inline-flex",
                  verticalAlign: "middle",
                  fontSize: "1rem",
                  fontWeight: "500",
                  marginRight: "0.3rem",
                }}
              />
              Others
            </Typography>
          </Box>
          <Box
            className="boxHeight"
            sx={{
              height: "2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              className="buttonText"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              <FiberManualRecordIcon
                className="buttonIcon"
                sx={{
                  color: "#FFA2C0",
                  display: "inline-flex",
                  verticalAlign: "middle",
                  fontSize: "1rem",
                  fontWeight: "500",
                  marginRight: "0.3rem",
                }}
              />
              Unknown
            </Typography>
          </Box>
        </Grid>
        <Grid xs={5}>
          <Box
            className="boxHeight"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "2rem",
            }}
          >
            <Typography
              className="buttonText"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              {docsValue}
            </Typography>
          </Box>
          <Box
            className="boxHeight"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "2rem",
            }}
          >
            <Typography
              className="buttonText"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              {mediaValue}
            </Typography>
          </Box>
          <Box
            className="boxHeight"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "2rem",
            }}
          >
            <Typography
              className="buttonText"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              {othersValue}
            </Typography>
          </Box>
          <Box
            className="boxHeight"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "2rem",
            }}
          >
            <Typography
              className="buttonText"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              {unknownValue}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default TextSectionRightBar1;
