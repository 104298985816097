import React, {useState, useEffect} from "react";
import { Menu, Box, MenuItem, Modal, Typography} from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
// import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import QRCode from "react-qr-code";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { DELETE_FILE } from "../services/services";
import { JSEncrypt } from "jsencrypt";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getDocument } from 'pdfjs-dist/webpack';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';

const RecentlyEditedFileActionsMenu = ({ anchorEl, onClose, data, onReload }) => {
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
  let FILE_URL = "https://shdw-drive.genesysgo.net/"+data.item.storage_address+"/"+data.item.file_name
  const [copied, setCopied] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [summary, setSummary] = useState('');
  const [summaryOpen, setSummaryOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");


  const openSummaryDialog = () => {
    setSummaryOpen(true);
  };

  const closeSummaryDialog = () => {
    setSummaryOpen(false);
  };

  const summarizePDF = async () => {
    setLoading(true);
    setProgress(0);
    const FILE_URL = "https://shdw-drive.genesysgo.net/"+data.item.storage_address+"/"+data.item.file_name;
    const pdf = await getDocument(FILE_URL).promise;
    let text = '';
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      text += content.items.map(item => item.str).join(' ');
    }
    text = text.slice(0, 1500);

    const prompt = "Read the following text, and then write a concise summary in three short sentences. The sentences must start and end:\n\n" + text;
    const body = {
      "model": "text-davinci-003",
      "prompt": prompt,
      "temperature": 0.4, 
      "max_tokens": 250,
      "top_p": 1,
      "frequency_penalty": 0.2,
      "presence_penalty": 0.6
    };
  
    setTimeout(() => {
      axios.post(`https://api.openai.com/v1/completions`, body, {
        headers: { Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}` },
      })   
      .then(({ data }) => {
        setSummary(data.choices[0].text);
        console.log(data.choices[0].text);
        openSummaryDialog();
        setAlertType("success")
        setAlertMessage("Summarizing file...")
        setAlertPopup(true)
      })
      .catch(function (error) {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
    }, 0);
  }   

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500)
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const shareFile =(url, type)=> {
    const fileUrl = url; 
    let shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(fileUrl)}`;
    if(type == "facebook"){
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(fileUrl)}`;
    }
    window.open(shareUrl, '_blank', 'width=800,height=600');
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText(FILE_URL);
    setCopied(true);
  }
  const handleSnackbarClose = () => {
    setCopied(false);
  };

  const deleteFile = async () => {
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload);
    if (payload) {
      const str_solana_keys = localStorage.getItem('solana_key');
      if (str_solana_keys && data.item.storage_address && data.item) {
        var enc_secret_key = encrypt.encrypt(str_solana_keys);
        let body = {
          "secret_key" : enc_secret_key,
          "email" : payload.email,
          "storage_public_key" : data.item.storage_address,
          "file_name" : data.item.file_name,
          "size": data.item.size
        };
        DELETE_FILE(body)
          .then(({ data }) => {
            if (data.status === 200) {
              onReload();
              onClose();
              setSnackbarOpen(true); 
            }
          })
          .catch(function (error) {
            alert(error?.response?.data?.message);   
          });
      }
    }
  };
  

  return (
    <>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Deleted file
        </Alert>
      </Snackbar>

      <Snackbar open={loading} autoHideDuration={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Alert severity="info" sx={{width: '100%'}}>
          Generating summary...
              <LinearProgress />
         </Alert>
      </Snackbar>

      <Snackbar open={copied} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Copied to clipboard!
          </Alert>
      </Snackbar>

        <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      PaperProps={{
        style: { backgroundColor: "#1E1E25" },
        elevation: 1,
      }}
      >
      <MenuItem
        sx={{color: "#FFFFFF"}}
        onClick={() => {summarizePDF()}}
        style={{ display: FILE_URL.endsWith('.pdf') ? 'flex' : 'none' }}
      >
        <AutoFixHighOutlinedIcon sx={{ marginRight: "0.5rem"}} />
        Summarize
      </MenuItem>
      <MenuItem
        sx={{
          color: "#FFFFFF",
        }}
        onClick={() => {
          shareFile(FILE_URL, "twitter")
        }}
      >
        <ShareOutlinedIcon sx={{ marginRight: "0.5rem" }} />
        Twitter
      </MenuItem>
      <MenuItem
        sx={{
          color: "#FFFFFF",
        }}
        onClick={() => {window.open(FILE_URL, "_blank", "noreferrer")}}
      >
        <LaunchOutlinedIcon sx={{ marginRight: "0.5rem" }} />
        Pop Out
      </MenuItem>
      
      <MenuItem
        sx={{
          color: "#FFFFFF",
        }}
        onClick={copyToClipboard}
      >
        <FileCopyOutlinedIcon sx={{ marginRight: "0.5rem" }} />
        Copy Link
      </MenuItem> 

      {/* <MenuItem
        sx={{
          color: "#FFFFFF",
        }}
        // onClick={handlePrint}
      >
        <PrintOutlinedIcon sx={{ marginRight: "0.5rem" }} />
        Print
      </MenuItem> */}
     
      <MenuItem
        sx={{
          color: "#FFFFFF",
        }}
          onClick={handleOpen}
      >
        <SmartphoneOutlinedIcon sx={{ marginRight: "0.5rem" }} />
        Mobile
      </MenuItem>

      <MenuItem
        sx={{
          color: "#FFFFFF",
        }}
      >
        <FileDownloadIcon sx={{ marginRight: "0.5rem" }} />
        Download
      </MenuItem>
      <MenuItem
        sx={{
          color: "#FFFFFF",
        }}
        onClick={() => deleteFile()}
      >
        <DeleteOutlinedIcon sx={{ marginRight: "0.5rem" }} />
        Delete
      </MenuItem>
    </Menu>

  {/* QR code modal */}
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="qr-code-modal-title"
      aria-describedby="qr-code-modal-description"
    >
      <div 
        style={{
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          backgroundColor: '#282832', 
          color: 'white', 
          padding: '20px', 
          margin: 'auto', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          position: 'absolute',
          borderRadius: '12px'
        }}
      >
        <Typography id="qr-code-modal-title" variant="h6" style={{ fontFamily: 'DM Sans', fontWeight: 700, paddingBottom: 10}}>
          Scan QR with Mobile
        </Typography>
        <Typography id="qr-code-modal-title" style={{ fontFamily: 'DM Sans', fontWeight: 700, paddingBottom: 10}}>
          You can only do this with public files.
        </Typography>
        <QRCode value={FILE_URL} size={300} />
      </div>
    </Modal>

    <Dialog
        open={summaryOpen}
        onClose={closeSummaryDialog}
        aria-labelledby="summary-dialog-title"
        aria-describedby="summary-dialog-description"
        sx={{ '& .MuiDialog-paper': { position: 'absolute', bottom: 0, left: 0, width: '50%', maxHeight: '50%', backgroundColor: '#282832' } }}
    >
        <DialogTitle id="summary-dialog-title" sx={{ fontFamily: 'DM Sans', display: 'flex', fontWeight: 'bold', justifyContent: 'space-between', backgroundColor: '#282832', color: '#fff' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AutoFixHighOutlinedIcon sx={{ marginRight: "0.5rem"}} />
            <h4>Synx Summary</h4>
          </Box>
          <IconButton edge="end" color="inherit" onClick={closeSummaryDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ fontFamily: 'DM Sans', backgroundColor: '#282832', color: '#fff' }}>
          {summary}
        </DialogContent>
    </Dialog>
    </>
        
  );
};

export default RecentlyEditedFileActionsMenu;
