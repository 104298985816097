import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Divider,
  IconButton,
  Popover,
  Typography
} from '@mui/material';
import SYNXLogo2 from '../assets/SYNXlogo2.png';
import CloseIcon from '@mui/icons-material/Close';
import green_case from '../assets/green_case.png';
import pink_case from '../assets/pink_case.png';
import purple_case from '../assets/purple_case.png';
import Add_vault from '../assets/Add_vault.png';
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SettingsIcon from '@mui/icons-material/Settings';
import { CreateVaultModal } from "./createVaultModal";
import { useNavigate } from "react-router-dom";


import { JSEncrypt } from "jsencrypt";
import { GET_ALL_STORAGE_ACCOUNT_INFO } from '../services/services';

export function SwitchVaultPopover({ anchorEl, handleClose, changeVault, updateAllData }) {
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
  const [vaultsArrWithSizes, setVaultsArrWithSizes] = useState([]);
  const [vaultIndex, setVaultIndex] = useState(0);
  const [vaultsArr, setVaultsArr] = useState([]);
  const [isCreateVaultOpen, setIsCreateVaultOpen] = useState(false);
  const navigate = useNavigate();

  function logout() {
    localStorage.clear();
    navigate("/");
    window.location.reload(true);
  }
  
  useEffect(() => {
    getVaults();
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getVaults = async () => {
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload);
    let user_email = payload.email;
    let user_id = payload._id;
    setVaultsArr(payload.vaults);
    const vaultIndex = localStorage.getItem('currentVault');
    if(vaultIndex){
      setVaultIndex(vaultIndex);
    

    }
    const str_solana_keys = localStorage.getItem('solana_key');
    var enc_secret_key = encrypt.encrypt(str_solana_keys);
    let body = {
      "id": user_id,
      "secret_key": enc_secret_key,
      "email": user_email,
    };
    GET_ALL_STORAGE_ACCOUNT_INFO(body)
      .then(({ data }) => {
        if (data.data.payload) {
          setVaultsArrWithSizes(data.data.payload.valutsInfo);
        }
      })
      .catch(function (error) {
      });
  };

  const bytesToSize = (bytes) => {
    var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 B';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  const handleCreateVaultOpen = () => {
    setIsCreateVaultOpen(true);
  };

  const handleCreateVaultClose = () => {
    setIsCreateVaultOpen(false);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          borderRadius: 8,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', // Add a light shadow effect
          filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15))', // Add a glowing effect
        },
      }}
    >
      <IconButton
        style={{ position: 'absolute', top: 5, right: 5, color: '#fff' }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <div style={{
        width: 400,
        height: 'fit-content',
        backgroundColor: '#1E1E25',
        fontFamily: 'DM Sans',
        color: '#fff',
        fontWeight: '400',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '7px'
      }}>
        <img src={SYNXLogo2} style={{ width: '140px', height: '77px' }} alt="SYNX Logo" />
        <Grid container direction="row" style={{ marginTop: '10px' }}>
          <Typography variant="body2" style={{ fontFamily: 'DM Sans', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>
            You are currently viewing files within your <span>Personal Vault</span>. You can switch vaults or create a new vault below.
          </Typography>
        </Grid>

        <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)', my: 2 }} />

        {/* Each Vault storage value */}
        <Grid
          container
          sx={{ width: '100%' }}  // Add horizontal padding
        >
          {vaultsArrWithSizes.length > 0 && vaultsArrWithSizes.map((item, index) => {
            let freeSpace = parseInt(((item.vaultSize - item.storageUsed) / item.vaultSize) * 100);
            let colorTo = "#A83D42";
            if (freeSpace > 50) {
              colorTo = "#10B981";
            }
            if (freeSpace > 20 && freeSpace < 50) {
              colorTo = "#FFBF00";
            }
            if (freeSpace < 20) {
              colorTo = "#A83D42";
            }
            
            return (
              <Grid
                onClick={() => {
                  setVaultIndex(index);
                  localStorage.setItem("currentVault", parseInt(index));
                  changeVault(index);
                  updateAllData();
                }}
                container
                direction="row"
                sx={{
                  backgroundColor: '#282832',
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: index === 0 ? '5px' : '10px',
                }}
                key={index}
              >
                <Grid item xs={2} container alignItems="center" justifyContent="center">
                  {vaultsArr.length > 0 ? vaultsArr[index].vaultcolor === "Pink" ? (
                    <img src={pink_case} alt={`pink Logo`} style={{ width: '40px', height: '40px', objectFit: 'contain' }} />

                  ) : vaultsArr[index].vaultcolor === "Purple" ? (
                    <img src={purple_case} alt={`purple Logo`} style={{ width: '40px', height: '40px', objectFit: 'contain' }} />

                  ) : (
                    <img src={green_case} alt={`green Logo`} style={{ width: '40px', height: '40px', objectFit: 'contain' }} />

                  ) : null}
                </Grid>

                <Grid item xs={5} container direction="column" justifyContent="center" paddingLeft={1}>
                  <Typography
                    sx={{ fontSize: '1rem', color: '#fff', fontFamily: 'DM Sans', fontWeight: 'bold' }}
                  >
                    {item.vaultname ? item.vaultname : "Unknown"}
                  </Typography>
                  <Typography
                    sx={{ fontSize: '0.9rem', fontFamily: 'DM Sans', fontWeight: 'bold' }}
                  >
                    {bytesToSize(item.vaultSize)}
                  </Typography>
                </Grid>

                <Grid item xs={5} container direction="row" justifyContent="center" alignItems="center">
                  {vaultIndex != index && <ArrowRightAltIcon sx={{ marginRight: '4px' }} />}

                  <Typography
                    sx={{
                      fontSize: '0.9rem',
                      fontFamily: 'DM Sans',
                      fontWeight: '400',
                      textAlign: 'right',
                    }}
                  >
                    {vaultIndex == index ? "Viewing" : "Switch to"}
                  </Typography>
                </Grid>
              </Grid>
            )
          })}
        </Grid>

        {/* Create a new vault */}
        <Grid container direction="row" alignItems="center" sx={{ marginTop: '20px' }} onClick={handleCreateVaultOpen} >
          <Grid item sx={{ paddingLeft: 2.5 }}>
            <img src={Add_vault} alt="Add Vault" style={{ width: '24px', height: '24px' }} />
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ fontFamily: 'DM Sans', fontSize: '16px', fontWeight: 500, paddingLeft: 1 }}>
              Create another SYNX Vault
            </Typography>
          </Grid>
        </Grid>

        {/* Settings button
        <Grid container direction="row" alignItems="center" sx={{ marginTop: '20px' }}>
          <Grid item sx={{ paddingLeft: 2.5 }}>
            <SettingsIcon />
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ fontFamily: 'DM Sans', fontSize: '16px', fontWeight: 500, paddingLeft: 1 }}>
              Settings
            </Typography>
          </Grid>
        </Grid> */}

        <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)', my: 1 }} />

        {/* Litepaper & terms of service */}
        <Grid container direction="row" alignItems="center" justifyContent="center" sx={{ marginTop: '10px' }}>
        <Button
            className="buttonMarginRightBar"
            variant="contained"
            sx={{
              "&:hover": {
                backgroundColor: "#6C5DD3",
              },
              backgroundColor: "#6C5DD3",
              borderRadius: "8px",
              width: "40%",
              textTransform: "capitalize",
            }}
            onClick={logout}
          >
            Logout
          </Button>
        </Grid>

      </div>
      <CreateVaultModal isCreateVaultOpen={isCreateVaultOpen} handleCreateVaultClose={handleCreateVaultClose}/>

    

    </Popover>
  );
}
