import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { JSEncrypt } from "jsencrypt";
import { Grid, Typography, Box, Button} from "@mui/material";

import TextSectionLeftBar1 from "../components/textSectionLeftBar/textSectionLeftBar1";
import TextSectionLeftBar2 from "../components/textSectionLeftBar/textSectionLeftBar2";
import TextSectionLeftBar3 from "../components/textSectionLeftBar/textSectionLeftBar3";
import CustomizedProgressBars from "./progressBar";
import logoSynx from "../assets/logoSynx.png";
import {useLocation} from 'react-router-dom';
import { GET_PROFILE } from "../services/solana_services";
import { GET_ALL_STORAGE_ACCOUNT_INFO } from "../services/services";
function DashboardSideBar({updateData}) {
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
  const location = useLocation();
  let currentPage = location.pathname.toLowerCase()
  const [storageUsed, setStorageUsed] = useState(0)
  const [totalStorage, setTotalStorage] = useState(0)
  const [activeUpload, setActiveUpload] = useState(false);
  const [activeRecord, setActiveRecord] = useState(false);
  const [activeSupport, setActiveSupport] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    fetchData()
  }, []); 
  const fetchData = async () => {
     
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload)
    const secret = localStorage.getItem("solana_key")
    var enc_secret_key = encrypt.encrypt(secret);
    const str_currentVault = localStorage.getItem('currentVault');
    let CURRENT_VAULT_INDEX = 0
    if(str_currentVault){
      CURRENT_VAULT_INDEX = parseInt(str_currentVault)
    }
let get_profile_body = { 
  "email": payload.email,
   "name": payload.username,
    "secret_key": enc_secret_key,
     "typeOfLogin": payload.typeOfLogin
     }
GET_PROFILE(get_profile_body)
.then(({ data }) => {
  if (data.status == 200) {
    getStorageDetails(data.data.payload.user_profile.email, data.data.payload.user_profile._id);

  }
})
.catch(async function (error) {

  if (error.response) {
  
  }
})
  }
  const getStorageDetails = async (user_email, user_id) => {
    const str_solana_keys = localStorage.getItem('solana_key');
    const secret = str_solana_keys;
    var enc_secret_key = encrypt.encrypt(secret);
   
    let body = {
      "id": user_id,
      "secret_key": enc_secret_key,
      "email": user_email,
    };
  
    GET_ALL_STORAGE_ACCOUNT_INFO(body)
      .then(({ data }) => {
        if (data.data.payload) {
          let storage_u = 0
          let storage_t = 0
          if (data.data.payload.totalVaultStorageUsed != 0) {
            storage_u = (data.data.payload.totalVaultStorageUsed / 1000).toFixed(2);
          }
          if (data.data.payload.totalVaultStorage != 0) {
            storage_t = (data.data.payload.totalVaultStorage / 1000).toFixed(2);
          }
          setStorageUsed(data.data.payload.totalVaultStorageUsed)
          setTotalStorage(data.data.payload.totalVaultStorage)
        }
        else {
          alert(data.message)
        }
      })
      .catch(function (error) {
      
      });

  }

  const formatFileSize = (bytes,decimalPoint) => {
    if(bytes == 0) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
 }
  function ActiveDashBoard() {
    navigate("/Dashboard");
  }
  function ActiveFiles() {

    navigate("/files");
  }
  function RecentFiles() {
  
  }
  function Collectibles() {
 
    navigate("/collectibles");
  }
  function LeaderBoard() {
   
    navigate("/leaderboard");
  }
  function Profile() {
   
    navigate("/profile");
  }
  function DataMarket() {
   
    navigate("/data");

  }
  function Upload() {
  
  }
  function Notes() {
   
    navigate("/notes");
  }
  function Record() {
   
  }
  function Spartans() {
    
    navigate("/spartans");
  }
  function Album() {
    
    navigate("/album");
  }
  function Support() {

  }

  return (
    <Grid
      container
      item
      sx={{
        height: "100vh",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="synxLogo"
            src={logoSynx}
            alt="Synx-Logo"
            style={{ height: "90%", width: "90%" }}
          />
        </Box>
        <Grid item xs={12}>
          <TextSectionLeftBar1
            activeDashBoard={currentPage == "/Dashboard" ? true : false}
            ActiveDashBoard={ActiveDashBoard}
            activeAllFiles={currentPage == "/files" ? true : false}
            ActiveFiles={ActiveFiles}
            activeCollectibles={currentPage == "/collectibles" ? true : false}
            Collectibles={Collectibles}
            activeLeaderBoard={currentPage == "/leaderboard" ? true : false}
            LeaderBoard={LeaderBoard}
            activeProfile={currentPage == "/profile" ? true : false}
            Profile={Profile}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "0.7rem" }}>
          <TextSectionLeftBar2
            activeDataMarket={currentPage == "/data" ? true : false}
            DataMarket={DataMarket}
            activeUpload={activeUpload}
            Upload={Upload}
            activeNotes={currentPage == "/notes" ? true : false}
            Notes={Notes}
            activeRecord={activeRecord}
            Record={Record}
            activeSpartans={currentPage == "/spartans" ? true : false}
            Spartans={Spartans}
            // activeAlbum={currentPage == "/album" ? true : false}
            // Album={Album}
          />
        </Grid>

        <Grid item xs={12} >
          <TextSectionLeftBar3
            activeSupport={activeSupport}
            Support={Support}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className="textSize"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          className="textSize"
          sx={{
            fontWeight: "700",
            fontSize: "0.8rem",
            color: "#C4BEED",
            fontFamily: "DM Sans",
          }}
        >
          {formatFileSize(storageUsed,1)} used
        </Typography>
        <Typography
          className="textSizeBottom"
          sx={{
            fontWeight: "400",
            fontSize: "0.7rem",
            color: "rgba(255, 255, 255, 0.7)",
            fontFamily: "DM Sans",
            paddingY: "0.3rem",
          }}
        >
          {`${parseFloat((storageUsed/totalStorage) * 100).toFixed(1)}%`} used - {`${formatFileSize(totalStorage - storageUsed,1)}`} free
        </Typography>

        <Box
          sx={{
            margin: "0.5rem",
          }}
        >
          <CustomizedProgressBars className="progressWidth" percentUsed={`${parseFloat((storageUsed/totalStorage) * 100).toFixed(1)}`} />
        </Box>
        <Typography
          className="textSizeBottom"
          sx={{
            fontWeight: "400",
            fontSize: "0.65rem",
            color: "rgba(255, 255, 255, 0.5)",
            fontFamily: "DM Sans",
            marginBottom: "0.5rem",
            paddingY: "0.3rem",
          }}
        >
          Litepaper . Terms of Service
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DashboardSideBar;
