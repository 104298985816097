import React, { useState, useEffect } from 'react'
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { JSEncrypt } from "jsencrypt";
import { GET_ALL_STORAGE_ACCOUNT_INFO } from "../services/services";
export default function BasicSelect({ handleChangeVault, onRefreshPage }) {
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
 
  const [vaultsArrWithSizes, setVaultsArrWithSizes] = useState([]);
  const [vaultIndex, setVaultIndex] = useState(0);
  
  useEffect(() => {
    getVaults()
  }, []);
  const getVaults = async () => {
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload)
    if(payload){

      let user_email = payload.email
      let user_id = payload._id
      const str_currentVault = localStorage.getItem('currentVault');
      let CURRENT_VAULT_INDEX = 0
      if(str_currentVault){
        setVaultIndex(parseInt(str_currentVault))
        CURRENT_VAULT_INDEX = parseInt(str_currentVault)
      }
      const secret = localStorage.getItem("solana_key")


      var enc_secret_key = encrypt.encrypt(secret);
      let body = {
        "id": user_id,
        "secret_key": enc_secret_key,
        "email": user_email,
      };
      GET_ALL_STORAGE_ACCOUNT_INFO(body)
        .then(({ data }) => {
          if (data.data.payload) {
           setVaultsArrWithSizes(data.data.payload.valutsInfo)
          }
        })
        .catch(function (error) {
          if (error.response) {
          }
        });
    }
  

  }
  return (
    <Box>
      <FormControl>
        {vaultsArrWithSizes.length > 0 &&      <Select
          className="selectBox "
          sx={{
            ".MuiSvgIcon-root ": {
              fill: "#7D7D7D !important",
            },
            border: "1px solid #7D7D7D",
            borderRadius: "10px",
            width: "175px",
            height: "44px",
            color: " #7D7D7D",
            fontWeight: "400",
            fontSize: "14px",
            fontFamily: "DM Sans",
          }}
          value={vaultIndex}
          onChange={(event) => {
            setVaultIndex(event.target.value)
            localStorage.setItem("currentVault",parseInt(event.target.value))
            onRefreshPage()
            handleChangeVault(event)}}
          name="isAvailable"
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
{vaultsArrWithSizes.length > 0 && vaultsArrWithSizes.map((item, index) => {
 return(
  <MenuItem key={index} value={index}>{item.vaultname}</MenuItem>
 )
})}
        
          {/* <MenuItem value="Personal">Personal</MenuItem>
          <MenuItem value="Team">Team</MenuItem> */}
        </Select>}
   
      </FormControl>
    </Box>
  );
}
