import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import RightBarAllFiles from "../components/rightBarAllFiles";
import SearchField from "../components/searchField";
import LeaderboardList from "../components/leaderboardList";
import LeaderboardPrize from "../components/leaderboardPrize";
import LeaderboardListAllTime from "../components/leaderboardListAllTime";

function Leaderboard() {
  const [view, setView] = useState('weekly');
  const [isWeeklyActive, setWeeklyActive] = useState(false);
  const [isAllTimeActive, setAllTimeActive] = useState(false);

  useEffect(() => {
    document.title = "Synx | Leaderboard 🏆";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          minHeight: "100vh",
          overflowX: 'hidden',
        }}
      >
        <Grid
          item
          xs={1.7}
          sx={{
            backgroundColor: "primary.light",
          }}
        >
          <DashboardSideBar />
        </Grid>

        <Grid item xs={10.3}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <Grid
              item
              xs={8.5}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1.8rem",
              }}
            >
              <Grid item xs={11}>
                <SearchField />
              </Grid>
            </Grid>

            <Grid
              item
              xs={3.5}
              className="rightBarAllfilesMargin"
              sx={{
                marginTop: "1rem",
                paddingLeft: "2rem",
              }}
            >
              <RightBarAllFiles />
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            spacing={2}
            sx={{
              marginTop: "1rem",
              marginLeft: "2rem",
              justifyContent: "flex-start",
            }}
          >
            <Grid item>
              <Typography
                sx={{
                  color: " #FFFFFF",
                  fontSize: "1rem",
                  fontWeight: "700",
                  fontFamily: "DM Sans",
                  marginBottom: 1,
                  marginRight: 10,
                  textAlign: 'center'
                }}
              >
                This Weeks Prize
              </Typography>
              <LeaderboardPrize sx={{marginLeft: "5rem"}} />
             </Grid>
            </Grid>

            {/* Leaderboards */}
            <Grid container justifyContent="center">

            <Grid item>
            <Typography
              sx={{ 
                color: isWeeklyActive ? '#C96183' : '#FFFFFF',
                '&:hover': {
                  color: '#C96183',
                  cursor: 'pointer'
                },
                '&:active': {
                  color: '#C96183'
                },
                fontSize: "1.2rem",
                fontWeight: "700", 
                fontFamily: "DM Sans",
                marginLeft: 2,
              }}
              onClick={() => {
                setWeeklyActive(true); 
                setAllTimeActive(false); 
                setView('weekly');
              }}
            >
                 Weekly 🎯
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: "1.2rem",
                  fontWeight: "700", 
                  fontFamily: "DM Sans",
                  marginLeft: 2,
                  textAlign: 'center'
                }}
              >
                |
              </Typography> 
            </Grid>
            <Grid item>
              <Typography
                sx={{ 
                  color: isAllTimeActive ? '#6C5DD3' : '#FFFFFF',
                  '&:hover': {
                    color: '#6C5DD3',
                    cursor: 'pointer'
                  },
                  '&:active': {
                    color: '#4C3CA5'
                  },
                  fontSize: "1.2rem",
                  fontWeight: "700", 
                  fontFamily: "DM Sans",
                  marginLeft: 2,
                }}
                onClick={() => {
                  setWeeklyActive(false); 
                  setAllTimeActive(true); 
                  setView('allTime');
                }}
              >
                 All Time 🕰️
              </Typography>
            </Grid>
            </Grid>

            <Grid
             sx={{ marginLeft: "5rem" }}  
             >
            {view === 'weekly' ? (
            <LeaderboardList sx={{ width: '100%' }} />
            ) : (
            <LeaderboardListAllTime sx={{ width: '100%' }} />
            )}
            </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Leaderboard;
