import React, { useState, useEffect, useCallback } from "react";
import { Grid, Typography, Box, CircularProgress, Drawer, Button, IconButton, Menu, MenuItem  } from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { nftDataSection } from "../constants/appConstants";
import axios from 'axios';

function NftDisplay() {
  const [hoverIndex, setHoverIndex] = useState(null);
  const { COLLECTIBLEHEADING } = nftDataSection;
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nftArr, setNftArr] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [drawerNFTOpen, setDrawerNFTOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNFTClose = () => {
    setOpen(false);
  };
  
  const handleOpen = (nft) => {
    setSelectedNFT(nft);
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const refreshData = useCallback(() => {
    getData();
    onRefresh();
  }, []);

  useEffect(() => {
    getData();
    window.addEventListener('focus', refreshData);

    return () => {
      window.removeEventListener('focus', refreshData);
    };
  }, [refreshData]);

  const getData = async () => {
    const payload = JSON.parse(localStorage.getItem('payload'));
    if (payload && payload.solanaaccount) {
      const solanaaccount = payload.solanaaccount;
      getNFTs(solanaaccount);
    }
  };
  

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    setTimeout(() => setRefreshing(false), 2000);
  }, [refreshing]);

  const getNFTs = async (solAccount) => {
    setLoading(true);
    await axios.get(`https://api.shyft.to/sol/v1/nft/read_all?network=mainnet-beta&address=${solAccount}`, {
        headers: {
            'Accept': 'application/json',
            'x-api-key' : `gd4YK6W_4yGlwN5u`
        }
    })
    .then(({ data }) => {
        if(data){
            const personGroupedByName = groupBy(data.result, 'name');
            let arr = [];
            Object.entries(personGroupedByName).map(([key,v])=>{
                arr.push({
                    "title" : key,
                    "content" : v,
                    "total": v.length
                })
            });
            setNftArr(arr);
        }
    })
    .catch((error) => {
    })
    .finally(() => { 
        setTimeout(() => setLoading(false))
    });
}


const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
      );
      return result;
      }, {}); 
  };

  return (
    <div>
    <Grid
      className="pinnedDataHeading"
      item
      xs={12}
      sx={{
        marginBottom: "0.8rem",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          color: " #FFFFFF",
          fontSize: "1rem",
          fontWeight: "700",
          fontFamily: "DM Sans",
        }}
      >
        {COLLECTIBLEHEADING}
      </Typography>
    </Grid>

    {loading ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '25%' }}>
      <CircularProgress sx={{ color: "#6C5DD3" }} />
      <Typography sx={{ marginTop: 1, fontStyle: 'DM Sans', fontWeight: '500' }}>Loading Collectibles...</Typography>
    </Box>
    ) : (
      <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        overflow:"scroll",
        height:"80vh"
      }}
    >
  {nftArr.map((nft, i) => {
    const hoverStyles = i === hoverIndex ? {
      border: "1px solid #A79EE5",
      boxShadow: "0 0 10px #A79EE5",

    } : {};

    return (
      <Grid
        key={i}  
        className={`pinnedBoxWidth ${i === hoverIndex ? 'hovered' : ''}`}
        item
        xs={3}
        sx={{
          m: 1,
          ...hoverStyles,
        }}
        onMouseEnter={() => setHoverIndex(i)}
        onMouseLeave={() => setHoverIndex(null)}
      >
         <Box
            sx={{
              background: "#1E1E25",
              overflow: "hidden",
              p: 1,
              height:300
            }}

          >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",  
              width: "100%",  
              objectFit: "cover",
            }}
          >
            <img
              src={nft.content[0].image_uri}
              alt="NFTImage"
              style={{
                width: "100%",
                height: "100%",
                objectFit:"cover"
              }}
              onClick={() => handleOpen(nft)}
            />
          </Box>
          </Box>
          <Grid
            item
            xs={12}
            sx={{
              height: "80px",
              display: "flex",
              alignItems: "center",
              border: "1px solid rgba(118, 118, 128, 0.24)",
              borderBottomLeftRadius: "7px",
              borderBottomRightRadius: "7px",
              p: 1.8,
              maxHeight: "200px"
            }}
          >
          <Grid item xs={10} sx={{ display: "flex"}}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <Typography
                className="buttonBoxAllfilesText"
                sx={{
                  color: " #FFFFFF",
                  fontSize: "0.8rem",
                  fontWeight: "700",
                  fontFamily: "DM Sans",
                  marginLeft: "0.5rem"
                }}
              >
                {nft.content[0].name}
              </Typography>
              <span
                className="buttonBoxAllfilesText"
                style={{
                  color: "#A79EE5",
                  fontSize: "0.8rem",
                  fontWeight: "700",
                  fontFamily: "DM Sans",
                  marginLeft: "0.5rem"
                }}
              >
                {nft.content[0].collection.family}
              </span>
            </div>
          </Grid>

          <Grid item xs={2}>
            <IconButton 
              onClick={handleClick}
            >
              <MoreVertRoundedIcon
                className="threeDotsIcon"
                sx={{
                  height: "24px",
                  width: "24px",
                  color: "rgba(255, 255, 255, 0.6)",
                }}
              />
            </IconButton>
          </Grid>
          </Grid>

              {/* Token Menu items for transfer, list and burn */}
              <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  PaperProps={{
                    style: { backgroundColor: "#1E1E25" }, 
                    elevation: 1, 
                  }}
                >
                  <MenuItem
                    sx={{
                      color: "#FFFFFF", 
                    }}
                  >
                    Burn Token
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: "#FFFFFF", 
                    }}
                  >
                    Transfer
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: "#FFFFFF", 
                    }}
                  >
                    List on Marketplace
                  </MenuItem>
              </Menu>

              </Grid>
              
            );
            })
          }


          {/* Where the NFT information section starts */}
          <Drawer
            open={open}
            onClose={handleClose}
            anchor="right"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{ display: 'flex', justifyContent: 'center' }} 
            BackdropProps={{
              style: {
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
              },
            }}
          >
            <Box 
              sx={{ 
                bgcolor: '#1E1E25',
                border: '2px solid #000', 
                p: 2,
                width: '25vw', 
                height: '100%',
                display: 'flex', 
                flexDirection: 'column',
                overflow: 'auto'
              }}
            >
              {selectedNFT && (
                <Box sx={{ 
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  color: '#FFFFFF'
                }}>
                  <Typography sx={{
                    color: " #FFFFFF",
                    fontSize: "1.5rem",
                    fontWeight: "700",
                    fontFamily: "DM Sans",
                    marginBottom: 2,
                  }}>
                    {selectedNFT.content[0].name}
                  </Typography>
                  <img 
                    id="nft-image"
                    src={selectedNFT.content[0].image_uri} 
                    style={{ 
                      width: '100%', 
                      height: '50%',
                      objectFit: 'cover',
                      borderRadius: '7px'
                    }} 
                  />
              
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'row', 
                      gap: '10px',
                      width: '90%',
                      justifyContent: 'center',
                      marginTop: '20px',
                      fontFamily: "DM Sans"
                    }}
                  >
                  </Box>
                  <Box sx={{ 
                    width: '90%',
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingTop: "0.75rem",
                    fontFamily: "DM Sans"
                  }}>
                    <Typography variant="h6" component="h2" style={{color: '#d3d3d3', fontFamily: "DM Sans", fontWeight: "bold",}}>Description</Typography>
                    <Typography   
                      sx={{
                        color: " #FFFFFF",
                        fontSize: "1rem",
                        fontWeight: "500",
                        fontFamily: "DM Sans",
                        paddingTop: "0.75rem",
                      }}
                    >
                      {selectedNFT.content[0].description}
                    </Typography>
                  </Box>
                  <Typography sx={{
                    color: " #FFFFFF",
                    fontSize: "2rem",
                    fontWeight: "700",
                    fontFamily: "DM Sans"
                  }}>
                  {selectedNFT.content[0].url}
                  </Typography>

                 {/* map attributes */}
                  <Box 
                    sx={{ 
                      width: '90%',
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      paddingTop: "1rem",
                      fontFamily: "DM Sans"
                    }}
                  >
                    <Typography variant="h6" component="h2" style={{color: '#d3d3d3', fontFamily: "DM Sans", fontWeight: "bold", marginBottom: "1rem"}}>Attributes</Typography>
                    
                    <Grid container spacing={2}>
                      {selectedNFT.content[0].attributes_array.map((attr, index) => (
                        <>
                          <Grid item xs={6}>
                            <Typography   
                              sx={{
                                color: "#d3d3d3",
                                fontSize: "1rem",
                                fontWeight: "500",
                                fontFamily: "DM Sans",
                              }}
                            >
                              {attr.trait_type}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography   
                              sx={{
                                color: " #FFFFFF",
                                fontSize: "1rem",
                                fontWeight: "500",
                                fontFamily: "DM Sans",
                              }}
                            >
                              {attr.value}
                            </Typography>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Box>

                  <Button 
                    variant="contained"
                    onClick={handleNFTClose}
                    style={{
                     backgroundColor: '#3B3B3B',
                     marginTop: 'auto', 
                     marginBottom: '20%', 
                     width: '90%', 
                     padding: 10, 
                     fontWeight: 'bold', 
                     fontSize: '1rem',
                     textTransform: 'none',
                     fontFamily: "DM Sans"
                    }} >
                    Go Back to Collection
                  </Button>
                </Box>
              )}
            </Box>
          </Drawer>
        </Grid>
        
      )}
    </div>
  );
}

export default NftDisplay;