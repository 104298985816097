import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Import Quill's snow theme CSS
import { Button, Box } from '@mui/material';
import { CREATE_NOTE } from '../services/notes_services';

function NoteEditor({ onNoteSave }) {
  const quillRef = useRef(); // Create a ref to store the Quill instance
  const editorRef = useRef(); // Create a ref to store the editor div element

  useEffect(() => {
    quillRef.current = new Quill(editorRef.current, {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],
          [{ font: [] }],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction
          [{ color: [] }], // dropdown with defaults from theme
          [{ align: [] }],
          ['clean'],
        ],
      },
    });
  }, []);

  const handleNoteSave = async () => {
    const content = quillRef.current.getContents(); // Get the Quill.js contents
    const lines = content.ops.map((op) => op.insert.trim()).filter((line) => line !== '');
    const title = lines[0] || ''; // Get the first non-empty line as the title
    const description = lines.slice(1).join('\n'); // Join the remaining lines as the note description

    const body = {
      title: title,
      description: description,
    };

    const accessToken = localStorage.getItem('access_token');

    try {
      const response = await CREATE_NOTE(body, accessToken); // Call the CREATE_NOTE API function with the access token
      const createdNote = response.data; // Extract the created note from the response
      onNoteSave(createdNote); // Invoke the onNoteSave callback with the created note
    } catch (error) {
      // Handle the error here
    }

    quillRef.current.setContents([]); // Clear the Quill.js contents
  };

  return (
    <>
      <style>
        {`
          .ql-toolbar.ql-snow {
            border: none !important;
            display: flex !important;
            justify-content: center !important;
            padding: 20px !important; // Height of the toolbar + some extra space
            margin_bottom: 20px !important;
          }
          .ql-container.ql-snow {
            padding-top: 20px !important; // Height of the toolbar + some extra space
            margin-top: 10px !important;
          }
        `}
      </style>

      <Box sx={{ margin: 2 }}>
        <div style={{ backgroundColor: '#121212' }}>
          <div ref={editorRef} style={{ height: '500px', borderRadius: '7px', marginRight: '20px', marginLeft: '20px' }} />
        </div>
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleNoteSave}
            sx={{
              margin: 2,
              backgroundColor: '#6C5DD3',
              color: '#FFFFFF',
              fontFamily: 'DM Sans',
              textTransform: 'none',
              width: '25%',
              paddingVertical: '10',
              fontStyle: 'bold',
            }}
          >
            Synx your note!
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default NoteEditor;
