import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Button, Box, Typography, Snackbar, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText  } from '@mui/material';
import Alert from '@mui/material/Alert';
import useMediaQuery from "@mui/material/useMediaQuery";
import inviteFriends from "../assets/invitefriend.png";
import { GET_PROFILE } from "../services/solana_services";
import '../App.css';

export default function InviteFriendsBanner() {
  const [open, setOpen] = useState(true);
  const [copied, setCopied] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [showExploreModal, setShowExploreModal] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [isBannerCollapsed, setIsBannerCollapsed] = useState(false);
  const laptopmatches = useMediaQuery("(min-height:900px)");
  const [referralCode, setReferralCode] = useState(false);

  const handleOpenReferralModal = () => {
    setShowReferralModal(true);
  };

  const handleCloseReferralModal = () => {
    setShowReferralModal(false);
  };

  const featureUpdates = [
    { title: '🗺️ Synx Map', description: "We've made a map that shows where all non encrypted images appear where you upload them!"},
    { title: '🗺️ Synx Market', description: "Upload and label your data to help train machine learning algorithms and improve retailer information."},
    { title: '🎯 Vault Creation', description: 'You can now create a vault of any size on desktop, simply click on the vault icon in top right of screen -> create new vault -> type your requested number of GB.' },
    { title: '🔍 Predictive Search', description: 'Search for your files now across all vaults using the top bar! More advanced search and filtering coming soon.' },
];

  const copyToClipboard = () => {
    navigator.clipboard.writeText('test');
    setCopied(true);
  }

  const handleSnackbarClose = () => {
    setCopied(false);
  };

  if (!open) {
    return null;
  }
  
  return (
    <div>
            <div className={`transition-all duration-500 ease-in-out ${isBannerCollapsed ? 'w-12 h-12' : 'w-full h-auto'} bg-gradient-to-r from-purple-new to-blue-new opacity-95 absolute inset-x-0 top-0 z-10`}>
            {isBannerCollapsed ? (
                <IconButton color="purple-new" onClick={() => setIsBannerCollapsed(false)} style={{ margin: '4px', color: '#FFFFFF' }}>
                    <InfoOutlinedIcon />
                </IconButton>
            ) : (
            <div className="mpx-4 sm:px-6 lg:px-4 mx-auto">
            <div className="grid justify-center md:grid-cols-2 md:justify-between md:items-center gap-2">
            <div className="text-center md:text-left md:order-2 md:flex md:justify-end md:items-center">
                {/* <div className="pt-3 px-4 justify-center  gap-2 rounded-md font-medium text-white transition-all text-sm flex">
                    <a className="inline-block justify-center items-center pb-3 text-lg font-semibold text-white" href="https://synx-storage.canny.io/requests" target="_blank" sx={{fontFamily: 'DM Sans'}}>
                     <div className=" px-3  hover:bg-white/[.1] rounded">
                       Provide Feedback
                     </div>
                    </a>
                    </div> */}
                    <p className="mt-3 py-2 px-2 inline-flex items-center gap-2 rounded-md border-2 border-white font-medium text-white hover:bg-white/[.1] hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all text-sm" onClick={() => setShowReferralModal(true)}>
                        Copy Referral Link 
                        <FileCopyOutlinedIcon style={{ marginLeft: '4px', width: '16px', height: '16px'}} />
                    </p>
                    <IconButton color="inherit" onClick={() => setIsBannerCollapsed(true)} style={{ marginLeft: '4px', color: '#FFFFFF' }}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div className="flex items-center">
                    <p className="inline-flex justify-center items-center gap-2 rounded-md font-medium text-white focus:outline-none focus:ring-2 transition-all text-lg" onClick={() => setShowDemoModal(true)}>
                        <div className="flex justify-center items-center pt-3 px-2">
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                            </svg>
                            <span className="text-center px-3 hover:bg-white/[.1] rounded">Watch demo</span>
                        </div>
                    </p>
                    <span className="border-r border-white/[.3] w-px h-6"></span>
                    <p className="inline-flex justify-center items-center gap-2 pr-1 pt-3 font-medium text-white underline focus:outline-none focus:ring-2 transition-all text-lg" onClick={() => setShowExploreModal(true)}>
                        <div className=" px-3  hover:bg-white/[.1] rounded">
                            Explore what's new
                        </div>
                    </p>
                </div>
            </div>
        </div>
        )}
        </div>

        <Snackbar open={copied} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Copied to clipboard!
          </Alert>
        </Snackbar>
            <Dialog
                open={showDemoModal}
                onClose={() => setShowDemoModal(false)}
                aria-labelledby="demo-modal-title"
                PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
                fullWidth={true}
                maxWidth={'md'}  // Set max width
            >
                <DialogContent>
                    <div style={{ width: '100%', height: '80vh' }}>  // Set 80% viewport height
                        <iframe 
                            src="https://www.loom.com/embed/47b608819ab84b6ca4fedf796c3fdbce?sid=6a82d796-68fe-453d-921f-c04321611ebf" 
                            frameborder="0" 
                            style={{ width: '100%', height: '100%' }}
                        >
                        </iframe>
                    </div>
                </DialogContent>
            </Dialog>
            
            {/* Refer friends modal */}
            <Dialog
                disableScrollLock={true}
                open={showReferralModal}
                onClose={handleCloseReferralModal}
                aria-labelledby="referral-modal-title"
                sx={{ width: '100%', padding: 0, margin: 0 }}  // add this line
                PaperProps={{ 
                    style: {    
                        alignItems: "center",
                        justifyContent: "center", 
                        width: '25%', 
                        height: '65%', 
                        margin: 'auto', 
                        backgroundColor: '#1E1E25', 
                        color: 'white' 
                    } 
                }}
            >
            {/* <DialogTitle 
                id="referral-modal-title" 
                sx={{ width: '100%', justifyContent: 'flex-end', borderBottom: 0, padding: 0 }}
            >
                <IconButton edge="end" color="inherit" onClick={handleCloseReferralModal} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle> */}

            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <img src={inviteFriends} alt="invitefriend" style={{width: '100%', Height: '15%', marginBottom: '1rem'}}/>

                    <Typography
                        sx={{
                            fontSize: laptopmatches ? "1.6rem" : "1rem",
                            fontWeight: "700",
                            fontFamily: "DM Sans",
                            marginBottom: "1rem",
                        }}
                    >
                        Referrals Are Live!
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: laptopmatches ? "1rem" : "0.8rem",
                            fontWeight: "400",
                            color: "rgba(255, 255, 255, 0.6)",
                            fontFamily: "DM Sans",
                            marginBottom: "1.5rem",
                        }}
                    >
                        You now earn 100 loyalty points for each person that uses your referral code. These contribute to winning Web3 prizes.
                    </Typography>

                    <Button
                        className="buttonMarginRightBar"
                        variant="contained"
                        sx={{
                            "&:hover": {
                                backgroundColor: "#6C5DD3",
                            },
                            backgroundColor: "#6C5DD3",
                            borderRadius: "8px",
                            width: "80%",
                            textTransform: "capitalize",
                            fontFamily: 'DM Sans',
                            fontWeight: '800',
                            fontSize: laptopmatches ? "1.1rem" : "",
                        }}
                        // onClick={handleQROpen}
                    >
                        Copy Referral Link
                        <FileCopyOutlinedIcon style={{ marginLeft: '8px', width: '16px', height: '16px'}} />
                    </Button>
                </Box>
            </DialogContent>
            </Dialog>

            <Dialog
                open={showExploreModal}
                onClose={() => setShowExploreModal(false)}
                aria-labelledby="explore-modal-title"
                PaperProps={{ style: { backgroundColor: '#282832', color: 'white' } }}
                maxWidth="sm" 
                fullWidth={true}
            >
                <DialogTitle id="explore-modal-title"  sx={{ fontFamily: 'DM Sans', fontWeight: '700',}}>Releases & Updates: 05/07/2023</DialogTitle>
                <DialogContent>
                    <List>
                        {featureUpdates.map((feature, index) => (
                          <ListItem key={index}>
                          <ListItemText
                              primary={feature.title}
                              secondary={feature.description}
                              sx={{
                                  '& .MuiListItemText-primary': {
                                      fontFamily: 'DM Sans',
                                      fontWeight: '700',
                                      color: 'white'
                                  },
                                  '& .MuiListItemText-secondary': {
                                    fontSize: laptopmatches ? "1rem" : "0.8rem",
                                    fontWeight: "600",
                                    color: "rgba(255, 255, 255, 0.6)",
                                    fontFamily: "DM Sans",
                                    marginBottom: "0.5rem",
                                  }
                              }}
                          />
                      </ListItem>                      
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
    </div>
  );
}


