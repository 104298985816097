import React from "react";
import { Navigate } from "react-router-dom";
import { GET_PROFILE } from "../services/solana_services";
import logOut from "./logout";
export default function Protected({ children }) {
  const str_payload = localStorage.getItem('payload');
  let payload = JSON.parse(str_payload)

  if(payload){
    let get_profile_body = {"email": payload.email, "name": payload.username,"typeOfLogin": payload.typeOfLogin}
    GET_PROFILE(get_profile_body)
    .then(({ data }) => {
      console.log("data => ", data)
      return children;
    })
    .catch(async function (error) {
      console.log("GET PROFILE => ERROR =>",error)
    if (error.response) {
      if(error?.response?.data?.message.includes("Unauthorized")){
        logOut()
        return <Navigate to="/" />;
       }
    }
    })
  }else{
    logOut()
    return <Navigate to="/" />;
  }

return children;
}