import React from "react";
import { Grid, Typography } from "@mui/material";
import ViewModuleRoundedIcon from "@mui/icons-material/ViewModuleRounded";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import FolderIcon from "@mui/icons-material/Folder";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import CustomizedTables from "../components/allFilesTable";
import sideMenuPersonalIcon from "../assets/sideMenuPersonalIcon.png";

function AllFilesHeader({refreshing}) {
  
  
  return (
    <>
      <Grid
        container
        item
        sx={{
          marginTop: "0.8rem",
          marginBottom: "0.8rem",
          display: "flex",
        }}
      >
        <Grid item xs={6}>
          <Typography
            className="overViewText"
            sx={{
              color: " #FFFFFF",
              fontSize: "1rem",
              fontWeight: "700",
              fontFamily: "DM Sans",
            }}
          >
            All Files
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <FileUploadRoundedIcon
            sx={{
              height: "24px",
              width: "24px",
              color: "#DADADA",
              marginLeft: "1.5rem",
            }}
          />
          <CreateNewFolderRoundedIcon
            sx={{
              height: "24px",
              width: "24px",
              color: "#DADADA",

              marginLeft: "1.5rem",
              marginRight: "1.5rem",
            }}
          />
          |
          <ViewListRoundedIcon
            sx={{
              height: "24px",
              width: "24px",
              color: "#DADADA",
              marginLeft: "1.5rem",
            }}
          />
          <ViewModuleRoundedIcon
            sx={{
              height: "24px",
              width: "24px",
              color: "#DADADA",
              marginLeft: "1.5rem",
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "0.8rem" }}>
          <CustomizedTables refreshing={refreshing} />
        </Grid>

        <Grid
          item
          xs={12}
          className="personalVaultPosition"
          sx={{
            marginTop: "2rem",
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: "89%",
          }}
        >
        </Grid>
      </Grid>
    </>
  );
}

export default AllFilesHeader;
