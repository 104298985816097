import React,{useEffect, useRef} from "react";
import { styled, InputBase, } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  height: "45px",
  border: "1px solid #7D7D7D",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#7D7D7D",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.5, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    '&:focus': {
      outline: 'none',
    },
    '&&&&:hover:before': {
      borderBottom: 'none !important',
    },
    '&&&&:before': {
      borderBottom: 'none',
    },
    '&&&&:after': {
      borderBottom: 'none',
    },
  },
}));

function SearchFieldModal({triggerSearch, inputText, setInputText}) {
  return (
    <Search className="searchField" style={{ alignItems: "center", px: "10" }}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        className="StyledInputBaseSearch"
        sx={{
            width: "100%",
            color:"#fff",
            paddingBottom: "5px",
            lineHeight: "normal",
            '&:focus': {
              outline: 'none',
              boxShadow: 'none',
            },
        }}
        placeholder="Search Synx"
        value={inputText}
        onChange={(event) =>  {setInputText(event.target.value); triggerSearch(event.target.value)}}
        autoFocus = {true}
      />

    </Search>
  );
}

export default SearchFieldModal;
