import React from "react";

import { Typography, Box, Grid } from "@mui/material";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { dashBoardLeftSideBar } from "../../constants/appConstants";
function TextSectionLeftBar3({ activeSupport, Support, activeSettings, Settings }) {
  const { GENERALHEADING, SUPPORT, SETTINGS } = dashBoardLeftSideBar;

  return (
    <Grid item xs={12}>
      {/* <Box
        className="boxHeight"
        sx={{
          height: "2rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography className="buttonText" variant="generalSectionH1">
          {GENERALHEADING}
        </Typography>
      </Box>

      <Box
        className="boxHeight"
        onClick={Support}
        sx={{
          background: activeSupport
            ? "linear-gradient(0deg, rgba(108, 93, 211, 0.08), rgba(108, 93, 211, 0.08)), #1E1E25"
            : "",
          height: "2rem",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          className="buttonText"
          variant="generalBtnSection"
          sx={{
            color: activeSupport ? "#A79EE5" : "",
          }}
        >
          <HelpOutlineOutlinedIcon
            className="buttonIcon"
            sx={{
              color: activeSupport ? "#A79EE5" : "#DADADA",
              display: "inline-flex",
              verticalAlign: "middle",
              fontSize: "1rem",
              fontWeight: "500",
              marginRight: "0.3rem",
            }}
          />
          {SUPPORT}
        </Typography>
      </Box> */}

    </Grid>
  );
}

export default TextSectionLeftBar3;
