import React, { useRef, useEffect, useState } from "react";
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios from 'axios';
import Upload from "../../assets/upload.png";
import { UploadSection } from "../../constants/appConstants";
import { useLocation, useNavigate } from 'react-router-dom';
import { JSEncrypt } from "jsencrypt";
import  URL  from "../../components/url";
import { GET_PROFILE } from "../../services/solana_services";
import AlertMessage from "../alertComponents/AlertMessage";
import UploadProgress from "../alertComponents/UploadProgress";
function UploadFileSection({updateRecentFiles, setUploadDrawerOpen, onRefreshDrawerRecents, updateAllData}) {
  var encrypt = new JSEncrypt();
 var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
  const [solanaAccount, setSolanaAccount] = useState("");
  const [email, setEmail] = useState("");
  const [vaultsArr, setVaultsArray] = useState([]);
  const [vaultIndex, setVaultIndex] = useState(0);
 
  const [loaderVal, setLoaderVal] = useState(0);
  const [alertPopup, setAlertPopup] = useState(false);
  const [uploadProgressPopup, setUploadProgressPopup] = useState(false);
  
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const navigate = useNavigate();


  const handleUpload = () => {
    setUploadProgressPopup(true);
  };
  
  const handleCloseUpload = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUploadProgressPopup(false);
  };
  

  useEffect(() => {
    getUserData();
 
  }, [])
  const getUserData = async () => {

    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload)
    setSolanaAccount(payload.solanaaccount)
    setEmail(payload.email)
    const current_vault = localStorage.getItem('currentVault');
    if(current_vault){
     setVaultIndex(current_vault)
    }
    let get_profile_body = { "email": payload.email, "name": payload.username, "typeOfLogin": payload.typeOfLogin }
    GET_PROFILE(get_profile_body)
    .then(({ data }) => {
        let vaults_array = data.data.payload.user_profile.vaults;
        if (vaults_array.length > 0) {
          setVaultsArray(vaults_array)
       
        }
    })
    .catch(async function (error) {
 
    })
  }
  const location = useLocation();

  function getCurrentPage() {
    const path = location.pathname;
    return path.substring(1).toLowerCase();
  }
  const laptopmatches = useMediaQuery("(min-height:900px)");
  const { UPLOADHEADING, DRAGANDDROP, SYNXSUPPORTEXT } = UploadSection;
  const fileInputRef = useRef(); 

  const onUploadClick = () => {
    fileInputRef.current.click();
  };
  
  const onFileDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      let filesArr = []
      Object.keys(e.dataTransfer.items).map((index) => {
        if (e.dataTransfer.items[index].kind === 'file') {
          var file = e.dataTransfer.items[index].getAsFile();
         filesArr.push(file)
        }
      })
      triggerUploadFile(filesArr)
    }
  };

  const onFileSelect = (e) => {
    const files = e.target.files;
    console.log("files selected =>",files)
    triggerUploadFile(files)
  };
const triggerUploadFile = async (fileData) => {
  const accessTokenVal = localStorage.getItem('access_token');
  const secretKey = localStorage.getItem('solana_key');
 
      if (secretKey && vaultsArr[vaultIndex].vaultaddress) {
        handleUpload()
      var enc_secret_key = encrypt.encrypt(secretKey);
      const formData = new FormData();
      formData.append('secret_key', enc_secret_key);
      formData.append('email', email);
      formData.append('storage_public_key', vaultsArr[vaultIndex].vaultaddress);
      formData.append('publicKey', solanaAccount);
      formData.append('vault_id', vaultsArr[vaultIndex]._id);
      formData.append('taskSlug', "upload_file");
      formData.append('isBackup', false);
   
     
      Object.keys(fileData).map((index) => {
         
                  const newFile = new Blob([fileData[index]], { type: fileData[index].type });
            newFile.name = fileData[index].name.replace(/\s+/g, '');
          formData.append('file_name', fileData[index]);
          console.log("formData =>",fileData[index])
        })
     
        let endpoint = "upload-files";
     
        if(vaultsArr[vaultIndex].isEncrypted){
          endpoint = "upload-files-encryptV2"
        }
console.log("secret_key",enc_secret_key)
console.log("email",email)
console.log("storage_public_key",vaultsArr[vaultIndex].vaultaddress)
console.log("publicKey",solanaAccount)
console.log("vault_id",vaultsArr[vaultIndex]._id)
console.log("taskSlug","upload_file")
console.log("isBackup",false)
console.log("accessTokenVal",accessTokenVal)
         await axios.post(`${URL}solana/${endpoint}`, formData, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
              Authorization: `${accessTokenVal}`
            },
            onUploadProgress: progressEvent => {
              let percentComplete = progressEvent.loaded / progressEvent.total
              percentComplete = parseInt(percentComplete * 100);
              setLoaderVal(percentComplete);
            }
          }
          )
            .then(({ data }) => {
          
              if (data.status == 200) {
                if(getCurrentPage().toLowerCase() == "dashboard"){
                  updateRecentFiles()
                
                }else{
                  setUploadDrawerOpen(false)
                  updateAllData()
                }
                onRefreshDrawerRecents()
                handleCloseUpload()
              }
            })
            .catch(function (error) {
              if(error?.response?.data?.message){
                if(error?.response?.data?.message.includes("failed to get balance of account")){
                  setAlertType("error")
                  setAlertMessage("Failed to get balance of account")
                  setAlertPopup(true)
                
                }else{
                  setAlertType("error")
                  setAlertMessage(error?.response?.data?.message)
                  setAlertPopup(true)
                }
              }
              
              handleCloseUpload()
              setLoaderVal(0)
            })
      }
}
 
  return (
    <>
     <input type="file" multiple ref={fileInputRef} style={{ display: 'none' }} onChange={onFileSelect} />
    {alertPopup && <AlertMessage alertDisplay={alertPopup} type={alertType} message={alertMessage} />}
    {uploadProgressPopup && <UploadProgress alertDisplay={uploadProgressPopup} loaderVal={loaderVal} />}
   
      <Grid
        item
        xs={12}
        sx={{
          marginTop: "0.8rem",
          marginBottom: "0.8rem",
        }}
      >
        <Typography
          sx={{
            color: " #FFFFFF",
            fontSize: "1rem",
            fontWeight: "700",
            fontFamily: "DM Sans",
          }}
        >
          {UPLOADHEADING}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className="uploadBox"
        sx={{
          backgroundColor: "#1E1E25",
          borderRadius: "8px",
          height: "14.0625rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        onDrop={onFileDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <img
          src={Upload}
          alt="Upload File"
          onClick={onUploadClick}
          style={{
            objectFit: "contain",
            width: laptopmatches ? "5rem" : "3.75rem",
            height: laptopmatches ? "5rem" : "3.75rem",
          }}
        />
        <Typography
          sx={{
            color: " #FFFFFF",
            fontSize: laptopmatches ? "1.25rem" : "1rem",
            fontWeight: "700",
            marginTop: "0.5rem",
            fontFamily: "DM Sans",
          }}
        >
          {DRAGANDDROP}
        </Typography>
        <Typography
          sx={{
            color: " #A0A0A0",
            fontSize: laptopmatches ? "1rem" : "0.8rem",
            fontWeight: "400",
            fontFamily: "DM Sans",
          }}
        >
          {SYNXSUPPORTEXT}
        </Typography>
      </Grid>
    </>
  );
}

export default UploadFileSection;
