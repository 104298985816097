import axios from 'axios';

export const CREATE_FREE_PLAN = async (body) => {
    const accessToken = localStorage.getItem('access_token');
    return axios.post(`https://api.synx.xyz/v0/api/plan/purchased-free-plan`, body, {
        headers: { Authorization: `${accessToken}` },
      }
    )
  };

