import React, {useState, useEffect} from "react";
import { Grid, TextField, Button, Switch, Card, CardMedia, Typography, IconButton  } from "@mui/material";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import SYNX_LOGO_White from "../assets/SYNX_LOGO_White.png";

function ComingSoon() {
  const navigate = useNavigate();
  const letters = 'Coming Soon'.split('');


    useEffect(() => {
      document.title = 'Synx | Coming Soon 👤';
    }, []); 
    
    return (
      
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={1.7}
          sx={{
            backgroundColor: "primary.light",
          }}
        >
          <DashboardSideBar />
        </Grid>
        <Grid
        item
        xs={10.3}
        sx={{
            display: "flex",
            flexDirection: "column", 
            justifyContent: "center",
            alignItems: "center"
        }}
        >
        <img src={SYNX_LOGO_White} alt="SYNX Logo" style={{width: '200px', height: '200px'}}/>
        <Typography 
            variant="h1" 
            sx={{
            fontSize: "8em",
            fontWeight: 'bold',
            fontFamily: "DM Sans",
            color: "#6C5DD3", 
            }}
        >
            Data Market.
        </Typography>
        <Typography 
            sx={{
            fontSize: "1em",
            fontWeight: 'bold',
            fontFamily: "DM Sans",
            color: "#FFFFFF", 
            }}
        >
            Coming Soon.
        </Typography>
      </Grid>
     </Grid>
    </ThemeProvider>
  );
}

export default ComingSoon;
