import { ADAPTER_EVENTS, CHAIN_NAMESPACES, CONNECTED_EVENT_DATA, CustomChainConfig, UserInfo, SafeEventEmitterProvider } from '@web3auth/base';
import { Web3Auth } from '@web3auth/modal';
import { LOGIN_MODAL_EVENTS } from '@web3auth/ui';
import { SolanaWallet } from '@web3auth/solana-provider';
import { getED25519Key } from "@toruslabs/openlogin-ed25519";
import { JSEncrypt } from "jsencrypt";
import React, { useCallback, useEffect, useState } from 'react';
import { GET_PROFILE, SET_PROFILE } from '../services/solana_services.js';

// const WEB3AUTH_CLIENT_ID = 'BPnBIdJeIcpR-HpWdb9raiVv7yJuVpD2JFHxWK6zBlNwJ3EvGIQ5sfQ20nOH6H3dw3uZVwUHXDs6JPi9Mh9E59I';
const WEB3AUTH_CLIENT_ID =process.env.REACT_APP_CLIENT_ID
const solanaChainConfig: CustomChainConfig = {
  chainNamespace: CHAIN_NAMESPACES.SOLANA,
  rpcTarget: 'https://rpc.helius.xyz/?api-key=7c7b5cbb-0144-45e2-b738-21a9ceea3b3f',
  blockExplorer: 'https://solscan.io/',
  chainId: '0x1',
  displayName: 'mainnet',
  ticker: 'SOL',
  tickerName: 'solana',
};

export interface AuthProviderData {
  web3auth: Web3Auth | null;
  provider: SafeEventEmitterProvider | null;
  user: Partial<UserInfo> | null;
  solanaWallet: SolanaWallet | null;
  onSuccessfulLogin: (data: SafeEventEmitterProvider, user: any) => void;
  login: () => void;
  logout: () => void;
  
}

export const AuthProviderContext = React.createContext<AuthProviderData>({
  web3auth: null,
  provider: null,
  user: null,
  solanaWallet: null,
  onSuccessfulLogin: (data: any) => {},
  login: () => {},
  logout: () => {},
});

const web3auth = new Web3Auth({
  chainConfig: solanaChainConfig,
  clientId: WEB3AUTH_CLIENT_ID,
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [provider, setProvider] = useState<SafeEventEmitterProvider | null>(null);
  const [user, setUser] = useState<Partial<UserInfo> | null>(null);
  const [solanaWallet, setSolanaWallet] = useState<SolanaWallet | null>(null);
 
  const onSuccessfulLogin = useCallback(async (data: SafeEventEmitterProvider, user: Partial<UserInfo>) => {
    console.log('onSuccessfulLogin => DATA', data);
    console.log('onSuccessfulLogin => user', user);
   
  
    setProvider(data);
   
    let solWallet = new SolanaWallet(web3auth.provider!)

    setSolanaWallet(solWallet);
    let isLoggedIn = localStorage.getItem('access_token');
    setUser(user);
    if(data.adapter == "phantom"){
      let userDetails = await web3auth.authenticateUser();
      console.log("userDetails =>", userDetails)
    }
    if(!isLoggedIn && data.adapter == "openlogin"){
      let userInfo = user;
      const privateKey = await solWallet.request({
        method: "solanaPrivateKey",
      });

      let idToken = userInfo.idToken
      console.log("privateKey =>",privateKey)
      console.log("idToken =>",idToken)
      let LOGIN_METHOD = "";
      if(userInfo){
        if(userInfo.typeOfLogin == "jwt"){
          LOGIN_METHOD="email_passwordless"
        }
        else{
          LOGIN_METHOD = userInfo.typeOfLogin
        }
        localStorage.setItem('access_token',idToken);
      const { pk, sk } = getED25519Key(privateKey);
      console.log("sk =>",sk)

      let parsed = sk.toJSON()
      let secretArray = "";
      if(parsed){
        let i = parsed.data.length - 1;
        while (i >= 0 && parsed.data[i] === 0) {
          i--;
        }
         secretArray = parsed.data.slice(0, i + 1);
      }
   
    
      let secret_key = "["+secretArray.toString()+"]"
      console.log("secret_key =>",secret_key)
      var encrypt = new JSEncrypt();
      var publicKey = process.env.REACT_APP_PUB_KEY;
      encrypt.setPublicKey(publicKey);
      var enc_secret_key = encrypt.encrypt(secret_key);
      let body = {   
        "name": userInfo.name,
      "typeOfLogin": LOGIN_METHOD,
      "email":userInfo.email,
      "secret_key": enc_secret_key,
      };
      console.log("BODY =>", body)

      GET_PROFILE(body)
      .then(({ data }) => {
        localStorage.setItem('payload', JSON.stringify(data.data.payload.user_profile))
        localStorage.setItem('solana_key',secret_key);
        localStorage.setItem("currentVault", 0);
         if(data.data.payload.user_profile.vaults.length > 0){
         localStorage.setItem('vaults', JSON.stringify(data.data.payload.user_profile.vaults))
         window.location.href = '/Dashboard';
         }else{
          window.location.href = '/onboarding';
         }
      })
      .catch(function (error) {
        console.log(":GET_PROFILE => CATCH =>", error.response.data)
       
       if(error.response.status == 404){
        logout()
        alert("Please update the app to latest version.")

        // setAlertType("error")
        // setAlertMessage("Please update the app to latest version.")
        // setAlertPopup(true)
       }
       else if(error?.response?.data?.auth == "unauthorized"){
        logout()
        alert(error?.response?.data?.message)
        // setAlertType("error")
        // setAlertMessage(error?.response?.data?.message)
        // setAlertPopup(true)
        
       }
       else{
        SET_PROFILE(body)
        .then(({ data }) => {
            if(data.status == 200){
      
              GET_PROFILE(body)
      .then(({ data }) => {
     
       
            localStorage.setItem('access_token', idToken)
             localStorage.setItem('payload', JSON.stringify(data.data.payload.user_profile))
             localStorage.setItem("currentVault", 0);
             localStorage.setItem('solana_key',secret_key);
             window.location.href = '/onboarding';
      
     
      })
      .catch(function (error) {
      if (error.response) {
        logout()
        alert(error?.response?.data?.message)
        // setAlertType("error")
        // setAlertMessage(error?.response?.data?.message)
        // setAlertPopup(true)
      }
      })
      .finally(() => { });
      
            }
            else{
              alert(error?.response?.data?.message)
              // setAlertType("error")
              // setAlertMessage(data.message)
              // setAlertPopup(true)
            }
        })
        .catch(function (error) {
          logout()
          if (error.response) {
            alert(error?.response?.data?.message)
        //     setAlertType("error")
        // setAlertMessage(error?.response?.data?.message)
        // setAlertPopup(true)
          }
          console.log(error)
         })
        .finally(() => {  });
       }
        
       })
      }
    }
    else if(!isLoggedIn && data.adapter != "phantom"){
      logout()
      console.log("ASasasas")
    }

  }, []);

  const login = useCallback(() => {
    web3auth
      .connect()
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setProvider(null);
    setSolanaWallet(null);
    web3auth
      .logout()
      .then(() => {
        // login on logout
      })
      .catch((err) => {
        console.log('logout', err);
      });
  }, []);

  const subscribeAuthEvents = useCallback(
    (web3auth: Web3Auth) => {
      web3auth.on(ADAPTER_EVENTS.CONNECTED, (data: SafeEventEmitterProvider) => {
        web3auth.getUserInfo().then(async (user) => {
        
       
          onSuccessfulLogin(data, user);
        });
      });

      web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
        console.log('connecting');
      });

      web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
        console.log('disconnected');
        setUser(null);
        setProvider(null);
        setSolanaWallet(null);
      });

      web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
        console.log('some error or user have cancelled login request', error);
      });

      web3auth.on(LOGIN_MODAL_EVENTS.MODAL_VISIBILITY, (isVisible) => {
        console.log('modal visibility', isVisible);
      });
    },
    [onSuccessfulLogin]
  );

  useEffect(() => {
    subscribeAuthEvents(web3auth);

    web3auth.initModal().catch((err) => {
      alert('error' + err);
    });
  }, []);

  const ctx: AuthProviderData = {
    web3auth,
    provider,
    user,
    solanaWallet,
    onSuccessfulLogin,
    login,
    logout,
  };
  return <AuthProviderContext.Provider value={ctx}>{children}</AuthProviderContext.Provider>;
};

export const AuthConsumer = AuthProviderContext.Consumer;