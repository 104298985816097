let IMGS_ARR = [
    {
      name: 'png',
      image: require('../assets/files_icons/Type=png.png'),
    },
    {
      name: 'pdf',
      image: require('../assets/files_icons/Type=pdf.png'),
    },
    {
      name: 'avi',
      image: require('../assets/files_icons/Type=avi.png'),
    },
    {
      name: 'css',
      image: require('../assets/files_icons/Type=css.png'),
    },
    {
      name: 'dll',
      image: require('../assets/files_icons/Type=dll.png'),
    },
    {
      name: 'doc',
      image: require('../assets/files_icons/Type=doc.png'),
    },
    {
      name: 'docx',
      image: require('../assets/files_icons/Type=doc.png'),
    },
    {
      name: 'eps',
      image: require('../assets/files_icons/Type=eps.png'),
    },
    {
      name: 'html',
      image: require('../assets/files_icons/Type=html.png'),
    },
    {
      name: 'jpg',
      image: require('../assets/files_icons/Type=jpg.png'),
    },
    {
      name: 'jpeg',
      image: require('../assets/files_icons/Type=jpg.png'),
    },
    {
      name: 'js',
      image: require('../assets/files_icons/Type=js.png'),
    },
    {
      name: 'mov',
      image: require('../assets/files_icons/Type=mov.png'),
    },
    {
      name: 'mp3',
      image: require('../assets/files_icons/Type=mp3.png'),
    },
    {
      name: 'nft',
      image: require('../assets/files_icons/Type=NFT.png'),
    },
    {
      name: 'php',
      image: require('../assets/files_icons/Type=php.png'),
    },
    {
      name: 'ppt',
      image: require('../assets/files_icons/Type=ppt.png'),
    },
    {
      name: 'psd',
      image: require('../assets/files_icons/Type=psd.png'),
    },
    {
      name: 'rar',
      image: require('../assets/files_icons/Type=rar.png'),
    },
    {
      name: 'txt',
      image: require('../assets/files_icons/Type=txt.png'),
    },
    {
      name: 'wav',
      image: require('../assets/files_icons/Type=wav.png'),
    },
    {
      name: 'xls',
      image: require('../assets/files_icons/Type=xls.png'),
    },
    {
      name: 'zip',
      image: require('../assets/files_icons/Type=zip.png'),
    }
    
  ];
  let DEFAULT_IMG = {
    name : 'default',
    image: require('../assets/files_icons/synx-logo.png'),
  }

  export {IMGS_ARR, DEFAULT_IMG}