import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import { GET_PROFILE } from "../services/solana_services";
import { GET_FILES_PIN } from "../services/services";
import PhotoAlbum from "react-photo-album";

function Album() {
  const [vaultsArray, setVaultsArray] = useState([]);
  const [filesArr, setFilesArr] = useState([]);

const [photos, setPhotos] = useState([]);

  useEffect(() => {
    document.title = "Synx | Photo Album 🖼️";
    fetchBalances();
  }, []);

  const fetchBalances = async () => {
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload);
    let get_profile_body = { "email": payload.email, "name": payload.username, "typeOfLogin": payload.typeOfLogin };
    
    GET_PROFILE(get_profile_body)
      .then(({ data }) => {
        let vaults_array = data.data.payload.user_profile.vaults;
        const publicVaultsArr = vaults_array.filter((item) => { return item.isEncrypted === false });
        if (publicVaultsArr.length > 0) {
          setVaultsArray(publicVaultsArr);
          // Fetch the storage list after setting the vaultsArray
          getStorageList(payload._id, payload.email, publicVaultsArr);
        }
      })
      .catch(async function (error) {
        // Handle error
      });
  };

  const getStorageList = async (user_id, user_email, publicVaultsArr) => {
    let body = {
      "id": user_id,
      "email": user_email,
    };

    try {
      const { data } = await GET_FILES_PIN(body);
      if (data.status === 200) {
        let payload = data.data.payload;
        let files_arr = [];
        if (payload.length > 0) {
          console.log("payload =>", payload);
          payload.map((item, index) => {
            let vaultData = publicVaultsArr.find(vaultItem => vaultItem.vaultaddress === item.vaultAddress);
            if (vaultData) {
              if (item.files) {
                if (item.files.length > 0) {
                  item.files.forEach(element => {
                    element.storage_address = item.vaultAddress;
                    files_arr.push(element);
                  });
                }
              }
            }
          });
        }
        if (files_arr.length > 0) {
          const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.heic', '.avif'];
          let dataFiles = files_arr.filter(file => {
            const extension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            return imageExtensions.includes(extension);
          });
          dataFiles = dataFiles.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
          setFilesArr(dataFiles);
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        // Handle error
      }
    }
  };

  useEffect(() => {
    if (filesArr.length > 0) {
      const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.heic', '.avif'];
     
      let dataFiles = filesArr.filter(file => {
        const extension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        return imageExtensions.includes(extension);
      });
      dataFiles = dataFiles.sort((a, b) => b.createdAt.localeCompare(a.createdAt));

      setPhotos(dataFiles.map(file => ({ src: `https://shdw-drive.genesysgo.net/${file.storage_address}/${file.name}`, width: 200, height: 200 })));
      console.log("photos =>", photos)

    }
  }, [filesArr]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ backgroundColor: "primary.main", color: "white", minHeight: "100vh" }}>
        <Grid item xs={1.7} sx={{ backgroundColor: "primary.light" }}>
          <DashboardSideBar />
        </Grid>

        <Grid item xs={10.3}>
          <PhotoAlbum layout="rows" photos={photos} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Album;
