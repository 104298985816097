import React, { useState, useEffect, useRef } from "react";
import { styled, Grid, Box, InputBase, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
  import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import mammoth from 'mammoth';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from "@mui/material/Divider";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { JSEncrypt } from "jsencrypt";
import { DECRYPT_FILE, GET_PROFILE } from "../services/solana_services";
import { GET_All_FILES } from "../services/services";
import { DEFAULT_IMG, IMGS_ARR } from "../constants/ImagesExts";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import SearchFieldModal from "../components/SearchFieldModal";
import ShareIcon from "@mui/icons-material/Share";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  height: "45px",
  border: "1px solid #7D7D7D",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#7D7D7D",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.5, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#7D7D7D',
  position: "absolute",
  pointerEvents: "initial",
});


function SearchField({searchable}) {
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false); // new state for the modal
  const [allFilesArr, setAllFilesArr] = useState([])
  const [userEmail, setUserEmail] = useState("")
  const [userID, setUserID] = useState("")
  const [vaultsArray, setVaultsArray] = useState([])
  const [solanaPublicAddress, setSolanaPublicAddress] = useState("")
  const [modalContent, setModalContent] = useState({ title: '', imageUrl: '' });
  const [openModal, setOpenModal] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [solanaKey, setSolanaKey] = useState("")
  const [inputText, setInputText] = useState("")
  
  useEffect(() => {
    fetchBalances()
    }, [])
 
    const triggerSearch = (searchParam) => {
setSearchQuery(searchParam)
// getStorageList(userID,userEmail,searchParam)
    }
    const fetchBalances = async () => {
      const str_payload = localStorage.getItem('payload');
      let payload = JSON.parse(str_payload)
      setUserID(payload?._id)
      setUserEmail(payload?.email)
      setSolanaPublicAddress(payload.solanaaccount)
      const str_solana_keys = localStorage.getItem('solana_key');
      setSolanaKey(str_solana_keys)
  let get_profile_body = { "email": payload.email, "name": payload.username, "typeOfLogin": payload.typeOfLogin }
  GET_PROFILE(get_profile_body)
  .then(({ data }) => {
    if (data.status == 200) {
      let vaults_array = data.data.payload.user_profile.vaults;
      if (vaults_array.length > 0) {
        setVaultsArray(vaults_array)
        getStorageList(payload._id, payload.email, "");
     
      }
    }
    else {
      alert(data.message)
    }
  })
  .catch(async function (error) {

    console.log("><8><")
   
  })
    }
    const getStorageList = async (user_id, user_email, searchParam) => {
      let body = {
        "id": user_id,
        "email": user_email,
      };
  
      GET_All_FILES(body)
        .then(({ data }) => {
          if (data.status == 200) {
            let payload = data.data.payload;
            let files_arr = [];
            if(payload.length > 0){
              payload.map((item, index) => {
                 if(item.files.length > 0){
                  item.files.forEach(element => {
                    
                    element.storage_address = item.storageAccount
                    
                    files_arr.push(element)
  
                  });
                 }
  
              })
            }
           
         
            let items = [];
            if (files_arr.length > 0) {
              files_arr = files_arr.sort((a, b) => b.last_modified.localeCompare(a.last_modified));
              
console.log("searchParam =>",searchParam)
              if(searchParam != ""){
               
                let toSearch = searchParam.toLowerCase();
                
                files_arr = files_arr.filter(i => {
                  let str =  i.file_name.toLowerCase();
               return str.toLowerCase().includes(toSearch)
                });
             console.log("filtered arr =>",files_arr)
              }else{
                files_arr = files_arr
              }
            
            
            }
            console.log("FINAL FILES =>",files_arr)
            setAllFilesArr(files_arr)
          }
        })
        .catch(async function (error) {

          console.log("><9><", error)
          if (error.response) {
           
            if (!error?.response?.data?.message == "Unauthorized! Access Token expired or invalid!" || !error?.response?.data?.auth == "unauthorized") {
              // alert(error?.response?.data?.message)
            }
           
          }
        })
  
    }
  const handleOpenModal = () => setOpen(true);
  const handleClose = () => { setInputText("");
   setOpen(false);
  };
  const handleCloseModal = () => setOpenModal(false);
  
  const handleOpen = (item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, isEncrypted ) => {

    let docxFile = false
    if(!isEncrypted){
    
     setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
     setOpenModal(true);
    }else{
      decryptFile( item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size )
    }
   
    };
    const decryptFile = async (item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size ) => {
      setIsFileLoading(true)
      var enc_secret_key = encrypt.encrypt(solanaKey);
                let file_body = {
                  "secret_key" : enc_secret_key,
                  "fileUrl" : encodeURI(url),
                  "publicKey" : solanaPublicAddress,
                  "email" : userEmail,
                }
                setOpenModal(true);
                DECRYPT_FILE(file_body)
              .then(async ({ data }) => {
                    if (data.status == 200) {
                      if(title.toLowerCase().includes("recording")){
                        url = `data:audio/mp4;base64,${data.data.payload.file}`
                      }else{
                        url = `data:${data.data.payload.mimetype};base64,${data.data.payload.file}`
                        let docxFile = false
                        if(title.includes("docx") || title.includes("doc")){
                          const parts = url.split(';base64,');
                          const contentType = parts[0].split(':')[1];
                          const byteString = atob(parts[1]);
                          const arrayBuffer = new ArrayBuffer(byteString.length);
                          const uint8Array = new Uint8Array(arrayBuffer);
                          for (let i = 0; i < byteString.length; i++) {
                            uint8Array[i] = byteString.charCodeAt(i);
                          }
                         url = new Blob([arrayBuffer], { type: contentType });
                         mammoth.convertToHtml({ arrayBuffer: url })
                         .then(result => {
                     url = result.value
                          //  setFileHtml(result.value);
                          docxFile = true
                           setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
                           setIsFileLoading(false)
                         })
                         .done();
                        }else{
                          docxFile = false
                          setModalContent({ item, url, title,is_image,is_audio,is_video,isDocx,fileExt, fileIndex, filesArr, storageAddr, solanaAddr, date, size, docxFile });
                          setIsFileLoading(false)
                      }
                      }
                    }
                  })
                  .catch(function (error) {
                      alert("Failed to decrypt")
                    console.log(error.response.data)
                  })
     }
  const bytesToSize = (bytes) => {
    var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 B';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }
  return (
    <Search className="searchField" style={{ display: "flex", alignItems: "center" }}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        // onClick={() => !searchable && handleOpenModal()}  
        className="StyledInputBaseSearch"
        sx={{
            width: "100%",
            color:"#fff",
            paddingBottom: "5px",
            lineHeight: "normal",
            '&:focus': {
              outline: 'none',
              boxShadow: 'none',
            },
        }}
        placeholder="Search Synx"
        onChange={(event) => {
          setInputText(event.target.value)
          triggerSearch(event.target.value)
          handleOpenModal()
        }}
        value={inputText}
      />

   <StyledModal
    open={open}
    onClose={handleClose}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    autoFocus = {false}
    >
    <TableContainer component={Paper} sx={{backgroundColor: '#1E1E25',  width: '50%', height:"50vh"}}>
   
    <Box justifyContent="center" alignItems="center" paddingBottom="10px" paddingTop="20px" paddingX="7%">
    <SearchFieldModal triggerSearch={triggerSearch} inputText={inputText} setInputText={setInputText} />
    </Box>

    <Box display="flex" justifyContent="center" alignItems="center" paddingBottom="10px">
        <Divider sx={{backgroundColor: '#6B7280', width: '86%', height: '2px'}} />
    </Box>
    <Box sx={{ maxHeight: '40vh', overflow: 'auto' }}>
      <Table>       
        <TableBody sx={{paddingBottom: '2%'}}>
          {allFilesArr.length > 0 ? allFilesArr.filter(i => {
                 let str =  i.file_name.toLowerCase();
               return str.toLowerCase().includes(searchQuery)
                }).map((fileItem, fileIndex) => { 
                 let vaultData = vaultsArray.find(vaultItem => vaultItem.vaultaddress === fileItem.storage_address);
                 let image_types = ['png', 'jpg', 'jpeg', 'gif', 'heic', 'avif'];
                 var fileExt = fileItem.file_name.split('.').pop().toLowerCase();
                 let isImage =  image_types.indexOf(fileExt);
                 let isDocx =  false;
                 let isAudio = false;
                 let isVideo = false;
                
                
                 if(fileExt == "docx" || fileExt == "doc" || fileExt == "pdf" || fileExt == "ppt"|| fileExt == "pptx"){
                  isDocx =  true;
                
                 }
                
                 if (fileExt == "mp4" || fileExt.toLowerCase() == "mov") {
                  if(fileItem.file_name.includes("Recording") || fileItem.file_name.includes("audio")){
                    isAudio = true;
                  }else{
                    isVideo = true;
                  }
                }
                if(fileExt == "mp3") {
                  isAudio = true;
                }
                 const found = IMGS_ARR.find(e => e.name === fileExt.toLowerCase());
                 let IMAGE_DISPLAY = null;
                 if(found){
                   IMAGE_DISPLAY=found.image
                 }else{
                   IMAGE_DISPLAY=DEFAULT_IMG.image
                 }
                  return(

            <TableRow key={fileIndex} 
            onClick={() => {
              handleOpen(fileItem,"https://shdw-drive.genesysgo.net/"+fileItem.storage_address+"/"+fileItem.file_name, fileItem.file_name,isImage,isAudio,isVideo,isDocx,fileExt, fileIndex,allFilesArr,fileItem.storage_address, solanaPublicAddress,moment(fileItem.last_modified).format("DD-MM-YYYY"), bytesToSize(fileItem.size), vaultData.isEncrypted)
              }}> 
              <TableCell 
                component="th" 
                scope="row" 
                sx={{color: '#FFFFFF', border: 'none', paddingRight: '8%', paddingLeft: '8%',}}
              >
               <Grid display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Box display="flex" alignItems="center" >
                      <InsertDriveFileIcon style={{ marginRight: "10px", width: '32px', height:'32px', color:"#6B7280" }} /> 
                      {/* Insert correct file icons here */}
                      <span style={{ fontFamily: '"DM Sans", sans-serif' }}>{fileItem.file_name}</span>
                  </Box>
                  <ChevronRightIcon color="#121212" />
              </Grid>
              </TableCell>
            </TableRow>
          )}) : null}
        </TableBody>
      </Table>
      </Box>

    </TableContainer>
</StyledModal>

<Dialog
      open={openModal}
      onClose={handleCloseModal}
      fullWidth={true}
      maxWidth="xl" 
      PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
    >
      <DialogActions>
        <IconButton
          style={{ position: 'absolute', top: 5, right: 5, color: '#fff' }}
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogTitle
        sx={{
          color: "#A79EE5",
          fontFamily: "DM Sans",
        }}
      >
        {modalContent.title}
      </DialogTitle>

      <DialogTitle
        sx={{
          color: "#FFFFFF",
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontWeight: "400",
        }}
      >
        File Uploaded: {modalContent.date}
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          objectFit: "contain",
          overflow: "auto"
        }}
      >
        {isFileLoading ? (
          <img src={require("../assets/loading_files.gif")} alt={"Loading..."}
            style={{ maxWidth: "85vw", maxHeight: "85vh", objectFit: "contain", borderRadius: '7px'}} />
        ) : modalContent.isDocx ? (
          <>
            {!modalContent.docxFile ? (
              <DocViewer documents={[
                { uri: modalContent.url }, // Remote file
              ]} pluginRenderers={DocViewerRenderers} />
            ) : (
              <iframe srcDoc={modalContent.url} width="100%" height="100%" />
            )}
          </>
        ) : modalContent.is_image >= 0 ? (
          <img src={modalContent.url} alt={modalContent.title}
            style={{ maxWidth: "85vw", maxHeight: "85vh", objectFit: "contain", borderRadius: '7px' }} />
        ) : modalContent.is_video ? (
          <video controls style={{ maxWidth: "100%", maxHeight: "100%" }}>
            <source src={modalContent.url} type={`video/${modalContent.fileExt}`} />
            Your browser does not support the video tag.
          </video>
        ) : modalContent.is_audio ? (
          <audio controls style={{ maxWidth: "100%", maxHeight: "100%" }}>
            <source src={modalContent.url} type={`audio/${modalContent.fileExt}`} />
            Your browser does not support the audio element.
          </audio>
        ) : null}
      </DialogContent>
    </Dialog>


    </Search>
  );
}

export default SearchField;
