import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  SwipeableDrawer,
  Box,
  Button,
} from "@mui/material";
import axios from 'axios';
import { JSEncrypt } from "jsencrypt";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import ImageAnnotator from '@alteryx/mini-tate';
import InfoIcon from '@mui/icons-material/Info';
import { GET_PROFILE } from "../services/solana_services";
import { GET_All_FILES } from "../services/services";
import AlertMessage from "../components/alertComponents/AlertMessage";
import ImageSelectionDrawer from "../components/dataImageListDrawer";
import { ChevronLeft, KeyboardArrowUp } from '@mui/icons-material';
import { keyframes } from "@emotion/react";
import AnnotationProgress from "../components/alertComponents/AnnotationProgress";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from 'react-router-dom';
import PermMediaIcon from '@mui/icons-material/PermMedia';

function DataSubmit() {
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);

  useEffect(() => {
    document.title = 'Synx | Data Tasks 🤖';
  }, []);
  
  useEffect(() => {
    fetchBalances()
  }, []);

  const [currentImageIndex, setCurrentImageIndex] = useState(10);
  const [imageAnnotations, setImageAnnotations] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filesArr, setFilesArr] = useState([]);
  const [openModal, setOpenModal] = useState(false); 
  const [vaultsArray, setVaultsArray] = useState([]);
  const [alertPopup, setAlertPopup] = useState(false);
  const [uploadProgressPopup, setUploadProgressPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const scrollContainerRef = useRef(null);
  const [modalRightOpen, setModalRightOpen] = useState(false);
  const [loaderVal, setLoaderVal] = useState(0);

  let startX = 0;
  let startY = 0;
  let startScrollTop = 0;
  let dx = 0;


  const location = useLocation();
  const selectedTask = location.state?.selectedTask;

  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleRightModalClose = () => {
    setModalRightOpen(false);
  };

  const handleRightModalOpen = () => {
    setModalRightOpen(true);
  };

  const bounceAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(0);
  }
}`;

const RightSwipeModal = () => {
  return (
    <Grid >
      <IconButton
        style={{ position: 'fixed', top: '45%', right: '35%', color: 'pink'}}
        aria-label="Open Modal"
        onClick={handleRightModalOpen} 
      >
        <ChevronLeft style={{ animation: `${bounceAnimation} 2s infinite`, color: '#FFFFFF' }}/>
        <Typography
          sx={{ 
            writingMode: 'vertical-lr', 
            textOrientation: 'sideways',
            fontFamily: 'DM Sans', 
            fontSize: '16px',
            color: '#FFFFFF',
            animation: `${bounceAnimation} 2s infinite`,
          }}
        >
          Select images
        </Typography>
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={modalRightOpen}
        onClose={handleRightModalClose}
        onOpen={handleRightModalOpen}
        swipeAreaWidth={30}
        transitionDuration={{
          enter: 400,
          exit: 200,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: '80vw',
            maxWidth: '400px',
            transition: 'width 0.2s',
            backgroundColor: '#1E1E25',
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pr: 1 }}>
          <IconButton
            onClick={handleRightModalClose}
            sx={{ color: '#FFFFFF' }}
            aria-label="Close Modal"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ fontFamily: 'DM Sans', alignContent: 'center', justifyContent: 'center', px: 2, py: 2}}>
          <Box sx={{ color: '#FFFFFF', fontWeight: '700'}}>
            <h5>Select where to start label:</h5>
          </Box>
          <Box sx={{ color: '#949494'}}>
            <p>Your labelling will start here, you can click back & mark complete to skip through your publicly uploaded image files.</p>
          </Box>
        </Box>
        {filesArr.map((item, index) => (
          <Box
            key={index}
            onClick={() => setCurrentImageIndex(index)}
            sx={{
              cursor: 'pointer',
              width: 'auto',
              height: '100%',
              p: '10px',
              pb: '20px',
              borderTop: '1px solid #ccc',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: '10px',
            }}
          >
            <Typography variant="subtitle1" sx={{ fontFamily: 'DM Sans', fontSize: '14px', color: 'pink', fontWeight: '500', mb: '5px'}}>
              {item.file_name}
            </Typography>
            <img
              src={`https://shdw-drive.genesysgo.net/${item.storage_address}/${item.file_name}`}
              alt={item.file_name}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </Box>
        ))}
      </SwipeableDrawer>
    </Grid>
  );
};

 const fetchBalances = async () => {
      const str_payload = localStorage.getItem('payload');
      let payload = JSON.parse(str_payload)
      let get_profile_body = { "email": payload.email, "name": payload.username, "typeOfLogin": payload.typeOfLogin }
      GET_PROFILE(get_profile_body)
      .then(({ data }) => {
          let vaults_array = data.data.payload.user_profile.vaults;
          const publicVaultsArr = vaults_array.filter((item) => {return item.isEncrypted === false}); 
          if (publicVaultsArr.length > 0) {
           
            setVaultsArray(publicVaultsArr)
            getStorageList(payload._id, payload.email,publicVaultsArr);
     
          }
      })
    .catch(async function (error) {

  })
    }
    const getStorageList = async (user_id, user_email,publicVaultsArr) => {
      let body = {
        "id": user_id,
        "email": user_email,
      };
      GET_All_FILES(body)
        .then(({ data }) => {
          if (data.status == 200) {
            let payload = data.data.payload;
            let files_arr = [];
            if(payload.length > 0){
            
              payload.map((item, index) => {
                let vaultData = publicVaultsArr.find(vaultItem => vaultItem.vaultaddress === item.storageAccount );
                if(vaultData){
                if(item.files){
                  if(item.files.length > 0){
                    item.files.forEach(element => {
                      element.storage_address = item.storageAccount
                      files_arr.push(element)
                    });
                   }
                }
               }
              })
            }
            if (files_arr.length > 0) {
              const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.heic', '.avif'];
              let dataFiles = files_arr.filter(file => {
                    const extension = file.file_name.substring(file.file_name.lastIndexOf('.')).toLowerCase();
                    return imageExtensions.includes(extension);
                      });
               dataFiles = dataFiles.sort((a, b) => b.last_modified.localeCompare(a.last_modified));
              setFilesArr(dataFiles)
             
            }
          }
        })
        .catch(async function (error) {
          if(error?.response?.data?.message){
            if (!error?.response?.data?.message == "Unauthorized! Access Token expired or invalid!" || !error?.response?.data?.auth == "unauthorized") {
              // setAlertType("error")
              // setAlertMessage(error?.response?.data?.message)
              // setAlertPopup(false)
            }
          }
        });
    }
  
  const navigate = useNavigate();

  const handleGridItemClick = () => {
    navigate("/data"); 
  };
  const handleChange = newAnnos => {
    let annotationsArr = newAnnos;
    const filteredData = annotationsArr.filter(item => item.name.includes(' '));
    if(filteredData.length > 0){
      setAlertType("error")
      setAlertMessage("Label with spaces is not accepted!")
      setAlertPopup(true)
    }
    setImageAnnotations(newAnnos);
    console.log("ADDED ANNOTATION =>",newAnnos)
  };

  const handleNextImage = () => {
    let totalFiles = filesArr.length - 1;
    let currentFile = currentImageIndex;
    if(currentFile < totalFiles){
      currentFile = currentFile + 1;
       setCurrentImageIndex(currentFile);
     };
  };

  const handlePreviousImage = () => {
    let currentFile = currentImageIndex;
 if(currentFile > 0){
   currentFile = currentFile - 1;
    setCurrentImageIndex(currentFile);
  };
}

  const onError = error => {
    console.log(error);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  
  const transformToClarifaiFormat = (annotations, imageId) => {
    const USER_ID = 'lj_synx_99';
    const APP_ID = 'synx_storage';
    const INPUT_ID = imageId;
    let bodyObj = {
      "user_app_id": {
        "user_id": USER_ID,
        "app_id": APP_ID
    },
    };
    let annotationsArr = [];
     annotations.map(anno => {
      let obj = {
        "input_id": INPUT_ID,
        "data": {
            "regions": [
                {
                    "region_info": {
                        "bounding_box": {
                          "top_row": anno.y,
                          "left_col": anno.x,
                          "bottom_row": (anno.h + anno.y),
                          "right_col": (anno.w + anno.x)
                      }
                    },
                    "data": {
                        "concepts": [
                            {
                                "id": anno.name,
                                "value": 1
                            }
                        ]
                    }
                }
            ]
        }
        
    };
      annotationsArr.push(obj)
    });
    bodyObj.annotations = annotationsArr;
    return  bodyObj;
  }  

  const uploadToClarifai = async () => {
    if (currentImageIndex) {
      const filteredData = imageAnnotations.filter(item => item.name.includes(' '));
      if(filteredData.length > 0){
        setAlertType("error")
        setAlertMessage("Label with spaces is not accepted!")
        setAlertPopup(true)
      }else{
        let body = {
          "inputs": [
            {
              "data": {
                "image": {
                  "url": `https://shdw-drive.genesysgo.net/${filesArr[currentImageIndex].storage_address}/${filesArr[currentImageIndex].file_name}`,
                  "allow_duplicate_url": true
                }
              }
            }
          ]
        };
        setUploadProgressPopup(true)
        await axios.post(`https://api.clarifai.com/v2/inputs`, JSON.stringify(body), {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Key 2358ad66309e47d2870a69498a5a8235',
          },
              onUploadProgress: progressEvent => {
                let percentComplete = progressEvent.loaded / progressEvent.total
                percentComplete = parseInt(percentComplete * 70);
                setLoaderVal(percentComplete);
                console.log("percentComplete => ",percentComplete)
              }
        }
        )
          .then(({ data }) => {
              if (!data.inputs || data.inputs.length === 0) {
          console.error("Unexpected API response:", data);
          return;
        }
          if(data?.status?.description == "Ok"){
            // setAlertType("success")
            // setAlertMessage("Image submitted")
            // setAlertPopup(true)
            setTimeout(function(){
             
              let inputId = data.inputs[0].id;
          
              let transformedAnnotations = transformToClarifaiFormat(imageAnnotations, inputId);
            
              const PAT = '2358ad66309e47d2870a69498a5a8235';
              const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Key ' + PAT
                },
                body: JSON.stringify(transformedAnnotations) 
            };
                handleNextImage()
          if(transformedAnnotations){
            fetch("https://api.clarifai.com/v2/annotations", requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log("Anotations Response =>",result)
               setLoaderVal(100)
            
              setTimeout(function(){ 
                setUploadProgressPopup(false); 
                setLoaderVal(0)
              }, 2000)
    
            
            
            })
            .catch(error => {
              setAlertType("error")
              setAlertMessage("Sorry! Failed to add labels. Please try again")
              setAlertPopup(true)
            });
          }
  
            }, 7000)
          
          }
        
          })
          .catch(function (error) {
            setAlertType("error")
            setAlertMessage("Sorry! Failed to add image to Data Market. Please try again in few seconds")
            setAlertPopup(true)
          })
      }
   
    };  
  }

  return (

    <ThemeProvider theme={theme}>
        {alertPopup && <AlertMessage alertDisplay={alertPopup} message={alertMessage} type={alertType} setAlertPopup={setAlertPopup}/>}
        {uploadProgressPopup && <AnnotationProgress alertDisplay={uploadProgressPopup} loaderVal={loaderVal} />}
      <RightSwipeModal />
      <ImageSelectionDrawer />
      <div style={{ position: 'absolute', bottom: 50, left: 50, color: '#FFFFFF' }}>
        <InfoIcon />
        <Typography>Learn how to label</Typography>
      </div>
      <Grid
        container
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <Grid
          item
          xs={1.7}
          sx={{
            backgroundColor: "primary.light",
          }}
        >
          <DashboardSideBar />
        </Grid>
        <Grid
          item
          xs={10.3}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
          <Grid item>
            <IconButton
              onClick={handleGridItemClick}
              style={{
                position: 'absolute',
                top: '3%',
                left: '15%',
                color: '#FFFFFF',
                backgroundColor: '#6C5DD3',
                
              }}
              aria-label="Go Back"
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              onClick={handleOpenModal}
              style={{
                position: 'absolute',
                top: '3%',
                left: '19%',
                color: '#FFFFFF',
                backgroundColor: '#6C5DD3',
             
              }}
              aria-label="Info Icon"
            >
              <InfoIcon />
            </IconButton>
            <IconButton
              onClick={handleOpenModal}
              style={{
                position: 'absolute',
                top: '3%',
                left: '19%',
                color: '#FFFFFF',
                backgroundColor: '#6C5DD3',
             
              }}
              aria-label="Info Icon"
            >
              <InfoIcon />
            </IconButton>

            <Button
              style={{
                position: 'absolute',
                top: '3.2%',
                right: '41%',
                color: '#FFFFFF',
                backgroundColor: '#6C5DD3',
                fontWeight: 'bold'
              }}
              aria-label="Info Icon"
            >
              BETA
            </Button>

            <IconButton
              onClick={handleRightModalOpen}
              style={{
                position: 'absolute',
                top: '3%',
                right: '2%',
                color: '#FFFFFF',
                backgroundColor: '#6C5DD3',
             
              }}
              aria-label="Info Icon"
            >
              <PermMediaIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <div style={{ display: "flex", alignItems: "center", paddingTop: '15%', justifyContent: "center" }}>
            <Typography variant="h5" style={{ fontFamily: 'DM Sans', fontWeight: 'bold', marginRight: '10px', justifyContent: "center", alignItems: "center"}}>
              {selectedTask}
            </Typography>
            </div>
            <Typography variant="body1" style={{ fontFamily: 'DM Sans', paddingBottom: 16, justifyContent: "center", alignItems: "center", color: "#949494" }}>
              Label more data to earn more rewards on the leaderboard and web 3 prizes.
            </Typography>
          </Grid>
          <Grid item>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: "#1E1E25",
                  padding: 2,
                  borderRadius: 5,
                  width: "35vw",
                  height: "68vh",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 0 10px #A79EE5", 
                  border: "1px solid #A79EE5",
                  
                }}
              >
                {filesArr.length > 0 && (
                  <div style={{ width: "100%", height: "100%", textAlign:"center" }}>
                    <ImageAnnotator
                      key={currentImageIndex}
                      imageSrc={`https://shdw-drive.genesysgo.net/${filesArr[currentImageIndex].storage_address}/${filesArr[currentImageIndex].file_name}`}
                      onChange={handleChange}
                      onError={onError}
                      options={{
                        annoStyles: { borderColor: "pink" },
                        imgStyles:{height:"50vh", width:"100%", objectFit:"contain"},
                        labels: { nameLabel: "Name" }
                      }}
                      rainbowMode
                      style={{ maxWidth: "100%", maxHeight: "100%", alignItems:"center" }}
                    />
                    <div style={{ width: '400px', color: '#949494', marginBottom: '-4%', justifyContent: 'center', alignContent: 'center', textAlign: 'center', margin: '0 auto', fontWeight: '500' }}>
                        <p>When you're done labeling click the button to mark complete and move on to the next image.</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                      <button
                          onClick={handlePreviousImage}
                          style={{
                              backgroundColor: "#6C5DD3",
                              color: "white",
                              fontFamily: "DM Sans",
                              fontWeight: "bold",
                              padding: "0.5rem",
                              border: "none",
                              borderRadius: "5px",
                              cursor: "pointer",
                              marginRight: 10,
                              width: '200px',
                          }}
                          disabled={currentImageIndex === filesArr.length - 1}
                      >
                          Go Back
                      </button>

                      <button
                          onClick={() => {uploadToClarifai();}}
                          style={{
                              backgroundColor: "#C96183",
                              color: "white",
                              fontFamily: "DM Sans",
                              fontWeight: "bold",
                              padding: "0.5rem",
                              border: "none",
                              borderRadius: "5px",
                              cursor: "pointer",
                              width: '200px'
                          }}
                      >
                          Mark Complete
                      </button>
                  </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    {/* Dialog modal */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth={'md'}  
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            height: "100vh", 
            width: "100vw", 
          },
        }}
      >
      <DialogContent>
        <div style={{ width: '100%', height: '80vh' }}>  
            <iframe 
                src="https://www.loom.com/embed/ead01fb86c7f42a8a76c4595da4fa393?sid=3339aa52-ecba-4275-bcc6-695331d8ac07" 
                frameborder="0" 
                style={{ width: '100%', height: '100%' }}
            >
            </iframe>
        </div>
        </DialogContent>
      </Dialog>

    </ThemeProvider>
  );
}

export default DataSubmit;