import React, { useState } from "react";
import { Grid, Button, Paper, Typography } from "@mui/material";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Carousel from 'react-material-ui-carousel'
import { useNavigate } from "react-router-dom";
import icon1 from "../assets/Twitterwhite.png";
import icon2 from "../assets/Discord.png";
import headerlogo from "../assets/synxRightBarAllFiles.png";

import onboardingGif from '../assets/onboarding/ob1.gif';
import onboardingGif2 from '../assets/onboarding/ob2.gif';
import onboardingGif3 from '../assets/onboarding/ob8.gif'

function Onboarding(props) {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(0);

  const items = [
    {
      name: "What is Synx?",
      description: "The cloud storage provider where you own your data, nobody else.",
      image: onboardingGif,
      buttontext: "Continue"
    },
    {
      name: "Store data forever",
      description: "We store and encrypt your data on the Solana blockchain.",
      image: onboardingGif2,
      buttontext: "Continue"
    },
    {
      name: "Manage your keys",
      description: "Your recovery phrase is your master key or password, write it down.",
      image: onboardingGif3,
      buttontext: "Let's get started!"
    },
  ];

  const handleClick = () => {
    let nextSlide = activeItem + 1;
    console.log("nextSlide =>",nextSlide)
       if(nextSlide == 3){
      navigate("/vaultcreation");
    }else{
      if (nextSlide >= items.length) {
        nextSlide = 0;
      }
    }
  
  

    setActiveItem(nextSlide);
  };

  return (
    <div className="bgdark h-100vh">
      <Navbar className="header pb-1" collapseOnSelect expand="lg" bg="dark" variant="dark">
        <div className="container-fluid">
          <Navbar.Brand href="#home"> <img src={headerlogo} alt="headerLogo" /> </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="https://twitter.com/synx_xyz">
                <img src={icon1} alt="icon1" />
              </Nav.Link>
              <Nav.Link href="https://discord.gg/blxckout">
                <img src={icon2} alt="icon2" />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <Grid container style={{ height: '100vh' }} justify="center" alignItems="center">
        <Grid item style={{ width: '100%' }} justify="center" alignItems="center">
          <Carousel height={"70vh"} autoPlay={false} index={activeItem} onChange={(index, activeItem) => setActiveItem(index)}>
            {
              items.map((item, i) => <Item key={i} item={item} handleClick={handleClick} />)
            }
          </Carousel>
        </Grid>
      </Grid>

    </div>
  );
}

function Item(props) {
  return (
    <Paper style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#121212',
      color: '#fff'
    }}>
      <Typography variant="h4" style={{ fontFamily: 'DM Sans', fontWeight: 700, marginBottom: 16 }}>
        {props.item.name}
      </Typography>
      <Typography style={{ fontFamily: 'DM Sans', fontWeight: 700, marginBottom: 20 }}>
        {props.item.description}
      </Typography>
      <img
        src={props.item.image}
        alt={props.item.name}
        style={{ maxWidth: '400px', maxHeight: '350px' }}
      />
      <Button
        className="buttonMarginRightBar"
        variant="contained"
        sx={{
          "&:hover": {
            backgroundColor: "#6C5DD3",
          },
          backgroundColor: "#6C5DD3",
          borderRadius: "8px",
          marginBottom: "1.2rem",
          width: "300px",
          maxWidth: "300px",
          textTransform: "capitalize",
          margin: "0 auto",
          display: "block",
        }}
        onClick={props.handleClick}
      >
        {props.item.buttontext}
      </Button>
    </Paper>
  )
}

export default Onboarding;