import React, {useEffect, useState} from "react";

import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import { Grid } from "@mui/material";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import SearchField from "../components/searchField";
import RightBarAllFiles from "../components/rightBarAllFiles";
import PinnedData from "../components/pinnedData";
import AllFilesHeader from "../components/allFilesHeader";

function AllFiles() {

  useEffect(() => {
    document.title = 'Synx | Files 📁';
    const vaultIndex = localStorage.getItem('currentVault');
    if(vaultIndex){
      setVaultIndex(vaultIndex)

    }
  }, []); 
  const [vaultIndex, setVaultIndex] = React.useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    setTimeout(function(){setRefreshing(false)}, 2000)
  }, [refreshing]);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        item
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          minheight: "100vh",
        }}
      >
        <Grid
          item
          xs={1.7}
          sx={{
            backgroundColor: "primary.light",
          }}
        >
          <DashboardSideBar />
        </Grid>
        <Grid item xs={10.3}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <Grid
              item
              xs={8.5}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2.1rem",
              }}
            >
              <Grid item xs={11}>
                <SearchField />
              </Grid>
            </Grid>
            <Grid
              item
              xs={3.5}
              className="rightBarAllfilesMargin"
              sx={{
                marginTop: "1rem",
                paddingLeft: "2rem",
              }}
            >
              <RightBarAllFiles changeVault={setVaultIndex} updateAllData={onRefresh} />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={11.3}>
              <PinnedData vaultIndex={vaultIndex} refreshing={refreshing} />
              <AllFilesHeader refreshing={refreshing} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default AllFiles;
