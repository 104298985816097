import React from 'react';
import { Typography, Box,  Grid } from "@mui/material";

function LeaderboardPrize() {
  return (
    <Box sx={{ padding: '1rem' }}>
      <Grid
        container
        sx={{
          backgroundColor: '#1E1E25',
          borderRadius: '10px',
          marginBottom: '1rem',
          width: '90%', 
          padding: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <img
            src="https://shdw-drive.genesysgo.net/7RwSs3vo3bP3LZ1n3ifBzrtq2wF2CB1fSKSfBMng2HBi/3792.png" 
            style={{
              width: '10%',
              height: '100%',
              borderRadius: '5%',
            }}
          />
          <Box>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.8)',
                fontFamily: 'DM Sans',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              Netrunner!
            </Typography>
            <Typography
              sx={{
                color: '#C7C7C8',
                marginTop: '0.2rem',
                fontSize: '1rem',
                fontFamily: 'DM Sans',
              }}
            >
              Top 3 get Netrunner NFTs, 6 to earn in total! 🏆
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}

export default LeaderboardPrize;

