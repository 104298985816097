import React, { useState, useEffect } from "react";
import { Grid, Typography, Drawer } from "@mui/material";
import synxRightBarAllFiles from "../assets/synxRightBarAllFiles.png";
import PersonalIconImg from "../assets/personal.png";
import SynxWalletIcon from "../assets/SynxWalletIcon.png";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { dashBoardRightSideBar } from "../constants/appConstants";
import Divider from '@mui/material/Divider';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import UploadDrawer from './uploadDrawer';
import { SwitchVaultPopover } from "./switchVaultPopover";
import moment from "moment";
import copy_24px from "../assets/copy_24px.png";
import solana_logo from "../assets/solana_logo.png";
import charts from "../assets/charts.png";
import genesysgo_logo from "../assets/genesysgo_logo.png";
import buyIcon from '../assets/manageWallet/buy.png';
import ReceiveCash from '../assets/manageWallet/ReceiveCash.png';
import sendIcon from '../assets/manageWallet/send.png';
import swapIcon from '../assets/manageWallet/swap.png';
import { GET_CONVERSION_RATE, GET_PERCENT_CHANGE, GET_PERCENT_RAISE, GET_SHADOW_BALANCE, GET_SOLANA_BALANCE, GET_USDC_BALANCE } from "../services/solana_services";
import WalletDrawer from "./walletDrawer";



// This also contains wallet right modal

function RightBarAllFiles({changeVault,updateAllData}) {
  const { UPLOADTEXT, WALLET } = dashBoardRightSideBar;
  const [modalOpen, setModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showHoldings, setShowHoldings] = useState(true);  // Initially show the holdings
  const [uploadDrawerOpen, setUploadDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [solanaPublicAddress, setSolanaPublicAddress] = useState("")
  const [smallSolanaAddress, setShortSolanaPublicAddress] = useState("")
  
  const [userEmail, setUserEmail] = useState("")
  const [solanaBalance, setSolanaBalance] = useState(0)
  const [shadowBalance, setShadowBalance] = useState(0)
  const [usdcBalance, setUsdcBalance] = useState(0)
  
  const [solInUSD, setSolInUSD] = useState(0)
  const [shdwInUSD, setShdwInUSD] = useState(0)
  const [usdcInUSD, setUSDCInUSD] = useState(0)
  
  const [hasCopied, setHasCopied] = useState(false)
  const [solChange, setSolChange] = useState(0)
  const [shdwChange, setShdwChange] = useState(0)
  const [usdChange, setUsdChange] = useState(0)
  const [refreshing, setRefreshing] = useState(false);

  const [solPercentChange, setSolPercentChnage] = useState(0);
  const [shdwPercentChange, setShdwPercentChnage] = useState(0);

  const [menuDisplay, setMenuDisplay] = React.useState(false);
  const [menuTransferDisplay, setMenuTransferDisplay] = React.useState(false);

  useEffect(() => {
    getData();
    get_percent_change("solana")
    get_percent_change("genesysgo-shadow")
    get_conversions();
  

  }, [])
  const getData = async () => {
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload)
let keyVal = payload.solanaaccount;
const first5 = keyVal.slice(0, 5);
var last5 = keyVal.substr(keyVal.length - 5);
let sol_ADDR = first5+"....."+last5;

    setShortSolanaPublicAddress(sol_ADDR)
    setSolanaPublicAddress(payload.solanaaccount)
    setUserEmail(payload.email)

    // getSolanaBalance(payload.solanaaccount,payload.email)
    // getShadowBalance(payload.solanaaccount,payload.email)
    // getUsdcBalance(payload.solanaaccount,payload.email)
   
  

  }


  const get_conversions  = () => {
    GET_CONVERSION_RATE("SOL")
    .then(({ data }) => {

      setSolInUSD(data?.data?.SOL?.price)
      // console.log("SOLANA in USD =>", data?.data?.SOL?.price)

      get_percent_gain("solana",data?.data?.SOL?.price);
    })
    .catch(function (error) {
     // // console.log(error)
     });

     GET_CONVERSION_RATE("SHDW")
     .then(({ data }) => {
       setShdwInUSD(data?.data?.SHDW?.price)
      //  console.log("SHADOW in USD =>", data?.data?.SHDW?.price)
       // console.log("SHDW Price Today >>",data?.data?.SHDW?.price)

       get_percent_gain("genesysgo-shadow",data?.data?.SHDW?.price);
     })
     .catch(function (error) {
      // // console.log(error)
      });
      GET_CONVERSION_RATE("USDC")
      .then(({ data }) => {
        setUSDCInUSD(data?.data?.USDC?.price)

        get_percent_gain("usd-coin",data?.data?.USDC?.price);
        // setTimeout(function(){ get_percent_gain();}, 1000)
      })
      .catch(function (error) {
       // // console.log(error)
       });
  }
  const get_percent_gain  = (coin,current_price) => {
    const date = moment()
    .startOf("day")
    .subtract(1, "day")
    .format("DD-MM-YYYY");
    GET_PERCENT_RAISE(coin,date)
    .then(({ data }) => {
      if(data){
        if(data.id == "solana"){
          let old_price = data?.market_data?.current_price?.usd

let percentChangeSol = (((current_price - old_price) / old_price) * 100).toFixed(2)

setSolChange(percentChangeSol)
        }
        if(data.id == "genesysgo-shadow"){
          let old_price = data?.market_data?.current_price?.usd
          // console.log("SHDW Price on "+date+">> ",old_price)

let percentChangeShdw = (((current_price - old_price) / old_price) * 100).toFixed(2)
// console.log("SHDW Price Percentage Change =>",percentChangeShdw)

setShdwChange(percentChangeShdw)
        }
        if(data.id == "usd-coin"){
          let old_price = data?.market_data?.current_price?.usd
let percentChangeUsdc = (((current_price - old_price) / old_price) * 100).toFixed(2)
setUsdChange(percentChangeUsdc)
        }
      }
     
    })
    .catch(function (error) {
     // // console.log(error)
     });
  

    
  }
  const get_percent_change  = (coin) => {
    GET_PERCENT_CHANGE(coin)
    .then(({ data }) => {
     if(coin == "solana"){
      setSolPercentChnage(data[0].price_change_percentage_24h)
     }else{
      setShdwPercentChnage(data[0].price_change_percentage_24h)

     }
    })
    .catch(function (error) {
     // // console.log(error)
     });
  

    
  }
  const getSolanaBalance = (solana_public, email) => {
    let body = {
      "address": solana_public,
      "email":email
  };
 
    GET_SOLANA_BALANCE(body)
    .then(({ data }) => {
      // // console.log("data",data)
      setSolanaBalance(data.data.payload)
    })
    .catch(function (error) {
      if (error.response) {
        // // console.log(error?.response?.data?.message)
        console.log("1>>",error?.response?.data?.message)
      //  alert("Please check internet connection")
      if(error?.response?.data?.message == "Unauthorized! Access Token expired or invalid!"){
        // localStorage.clear()
        
      }
      }else{
       console.log(error)
      }
     });
  }
  const getShadowBalance = (solana_public, email) => {
    let body = {
      "address": solana_public,
      "email":email
  };
  // // console.log("shadow Body", body)
    GET_SHADOW_BALANCE(body)
    .then(({ data }) => {
      // // console.log("shadow data")
      setShadowBalance(data.data.payload)
    })
    .catch(function (error) {
      if (error.response) {
        // // console.log(error.response.data)
        console.log("2>>",error?.response?.data?.message)
        if(error?.response?.data?.message == "Unauthorized! Access Token expired or invalid!"){
          // localStorage.clear()
        
        }
      //  alert("Please check internet connection")
      }else{
        // // console.log(error)
      }
     });
  }
  const getUsdcBalance = (solana_public, email) => {
    let body = {
      "address": solana_public,
      "email":email
  };
  // // console.log("shadow Body", body)
    GET_USDC_BALANCE(body)
    .then(({ data }) => {
      // // console.log("shadow data")
      setUsdcBalance(data.data.payload)
    })
    .catch(function (error) {
      if (error.response) {
        // // console.log(error.response.data)
        console.log("3>>",error?.response?.data?.message)
        if(error?.response?.data?.message == "Unauthorized! Access Token expired or invalid!"){
          // localStorage.clear()
         
        }
      //  alert("Please check internet connection")
      }else{
        // // console.log(error)
      }
     });
  }
  const toggleHoldings = () => {
    setShowHoldings(!showHoldings);  // Toggle the visibility of the holdings on click
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(smallSolanaAddress);
    setCopied(true);
  }

  const handleSnackbarClose = () => {
    setCopied(false);
  };

  // Need to slide overlay here
  const profileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const profileClose = () => {
    setAnchorEl(null);
  };

  const walletClose = () => {
    setModalOpen(false); // Close the modal
  };

  const walletClick = () => {
    setModalOpen(true); 
  };

  
  const uploadClick = () => {
    setUploadDrawerOpen(true); // Open the drawer
  };

  const handleUploadDrawerClose = () => {
    setUploadDrawerOpen(false); // Close the drawer
  };

  let holdings = [
    {
      logo: solana_logo,
      name: 'Solana',
      amount: `${solanaBalance.toFixed(2)} SOL`,
      price: `$${(solInUSD * solanaBalance).toFixed(2)}`,
      percentage: solChange > 0 && "+"+solChange+"%",
    },
    {
      logo: genesysgo_logo,
      name: 'Shadow',
      amount: `${shadowBalance.toFixed(2)} SHDW`,
      price: `$${(shdwInUSD * shadowBalance).toFixed(2)}`,
      percentage: shdwChange > 0 && "+"+shdwChange+"%",
    }
  ];

  return (
    <>
      <SwitchVaultPopover anchorEl={anchorEl} handleClose={profileClose} changeVault={changeVault} updateAllData={updateAllData} />
      <UploadDrawer open={uploadDrawerOpen} handleClose={handleUploadDrawerClose} updateAllData={updateAllData} />
      <WalletDrawer open={modalOpen} close={walletClose} />
      {/* Add the Drawer component */}
    

      {/* End of drawer component */}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={5.9}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            className="logoRightBarAllFiles"
            src={synxRightBarAllFiles}
            alt="synxRightBarAllFiles"
            style={{ height: "71px", width: "76px" }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
            }}
          >
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={uploadClick} // Add upload click handler
              >
                <FileUploadIcon
                  className="filUploadIcon"
                  size="small"
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    display: "inline-flex",
                    fontSize: "1.5rem",
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  className="fileUploadTextSize"
                  sx={{
                    fontSize: "0.6rem",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                    fontFamily: "DM Sans",
                  }}
                >
                  {UPLOADTEXT}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
            }}
          >
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                  onClick={walletClick} // Added wallet click handler
              >
                <img
                  className="imgSizeWalletIcon"
                  src={SynxWalletIcon}
                  alt="SynxWalletIcon-Logo"
                  style={{
                    height: "22px",
                    width: "22px",
                    display: "inline-flex",
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0.1rem",
                }}
              >
                <Typography
                  className="fileUploadTextSize"
                  sx={{
                    fontSize: "0.6rem",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                    fontFamily: "DM Sans",
                  }}
                >
                  {WALLET}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
            }}
          >
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={PersonalIconImg}
                  alt="DonutChart"
                  style={{ height: "2.15rem", width: "2.15rem" }}
                  onClick={profileClick} // Add profile click handler
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default RightBarAllFiles;
