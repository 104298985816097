import React, {useEffect} from "react";
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
function AlertMessage({alertDisplay, message, type, setAlertPopup}) {
  const [showAlert, setShowAlert] = React.useState(alertDisplay ? alertDisplay : false);
  useEffect(() => {
    setTimeout(function(){
      setShowAlert(false)
      if(setAlertPopup){
        setAlertPopup(false)
      }
   
    }, 4000)
 
  }, [])
  const triggerCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
   if(setAlertPopup){
        setAlertPopup(false)
      }
  };
  
  return (
     <Snackbar open={showAlert} autoHideDuration={null} onClose={triggerCloseAlert}>
        <Alert onClose={triggerCloseAlert} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
  );
}
export default AlertMessage;
