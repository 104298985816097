import URL from '../components/url'
import axios from 'axios';

// .............AUTHENTICATION.........
export const SIGNIN = async (body) => {
    return axios.post(`${URL}auth/signin`, body)
  };

export const SIGNUP = async (body) => {
    return axios.post(`${URL}auth/signup`, body)
  };

export const RESEND_VERIFICATION = async (body) => {
    return axios.post(`${URL}auth/resendverificationtoken`, body)
  };

export const REQUEST_PASSWORD_RESET = async (body) => {
    return axios.post(`${URL}auth/requestpasswordreset`, body)
  };

export const PASSWORD_RESET = async (body) => {
    return axios.post(`${URL}auth/passwordreset`, body)
  };

export const UPDATE_PROFILE = async (body) => {
    const accessToken = localStorage.getItem('access_token');

    return axios.post(`${URL}user/update-profile`, body, {
        headers: { Authorization: `${accessToken}` },
      }
    )
};

export const REFRESH_TOKEN = async (body) => {
    return axios.post(`${URL}auth/refreshtoken`, body)
};

//   ........PROFILE.........
export const GET_PROFILE_PICTURE = async (body) => {
    const accessToken = localStorage.getItem('access_token');
    return axios.post(`${URL}user/profile-image`, body, {
        headers: { Authorization: `${accessToken}` },
      }
    )
};

export const UPLOAD_PROFILE_PICTURE = async (body) => {
    const accessToken = localStorage.getItem('access_token');

    return axios.post(`${URL}user/update-profile-image`, body, {
        headers: { 
          'Accept': 'application/json', 
          'Content-Type': 'multipart/form-data',
          Authorization: `${accessToken}` 
         },
      }
    )
};


//   ........DASHBOARD.........
  export const GET_FILES = async (body) => {
    const accessToken = await localStorage.getItem('access_token');
 
    return axios.post(`${URL}solana/get-object-list`, body, {
        headers: { Authorization: `${accessToken}` },
      }
    )
  };
  
export const GET_FILES_PIN = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/all-storage-files-db`, body, {
    headers: { Authorization: `${accessToken}` },
    }
  )
};

export const GET_BACKUP_FILES = async (body, params) => {
  const accessToken = localStorage.getItem('access_token');

  return axios.post(`${URL}solana/get-backup-files${params}`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};

export const GET_DECRYPT_FILES = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/get-decrypted-file`, body, {
      headers: {  
        'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `${accessToken}` 
    },
    }
  )
};

export const GET_SHDW_USD_RATES = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/shdw-price`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};

export const GET_STORAGE_ACCOUNT_INFO = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  
  return axios.post(`${URL}solana/storage-account-info`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};

export const GET_ALL_STORAGE_ACCOUNT_INFO = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/all-storage-account-info`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};
export const GET_All_FILES = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/all-storage-files`, body, {
    headers: { Authorization: `${accessToken}` },
  }
  )
};
export const CREATE_STORAGE_ACCOUNT = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  
  return axios.post(`${URL}solana/create-storage-account`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};

export const CREATE_SOLANA_ACCOUNT = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/create-solana-account`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};

export const MAKE_STORAGE_IMMUTABLE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/make-storage-immutable`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};

export const ADD_STORAGE_SPACE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/add-storage-space`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};

export const REDUCE_STORAGE_SPACE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/reduce-storage-space`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};

export const DELETE_FILE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/delete-file`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};

export const PIN_FILE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}vault/add-fav-file`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};

export const UNPIN_FILE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}vault/remove-fav-file`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  )
};

export const UPLOAD_FILES = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/upload-files`, body, {
      headers: {  
        'Accept': 'application/json', 
        'Content-Type': 'multipart/form-data',
        Authorization: `${accessToken}` 
      },
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
        if (totalLength !== null) {
            // this.updateProgressBarValue(Math.round( (progressEvent.loaded * 100) / totalLength ));
        }
    }
    }
  )
};
