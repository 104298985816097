import axios from 'axios';

const URL = 'https://api.synx.xyz/api/';

export const GET_SOLANA_BALANCE = async (body) => {
  const accessToken = localStorage.getItem('access_token');

  return axios.post(`${URL}solana/get-balance`, body, {
    headers: { Authorization: `${accessToken}` },
  })
};

export const GET_STATS = async () => {
  return axios.get(`${URL}solana/get-analytics`);
};

export const GET_SHADOW_BALANCE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/get-shdw-balance`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};

export const GET_USDC_BALANCE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/get-usdc-balance`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};
export const GET_CONVERSION_RATE = async (body) => {
  return axios.get(`https://price.jup.ag/v4/price?ids=${body}`)
};

export const GET_PERCENT_RAISE = async (COIN,DATE) => {
  return axios.get(`https://api.coingecko.com/api/v3/coins/${COIN}/history?x_cg_pro_api_key=CG-MzEi2KuPt8croL2WAQQXWZep&date=${DATE}`)
};

export const GET_PERCENT_CHANGE = async (COIN) => {
  return axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${COIN}`)
};



export const MINT_NFT = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/mint-nft`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};

export const TRANSFER_NFT = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/transfer-nft`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};

export const TRANSFER_SOLANA = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/transfer-sol`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};

export const TRANSFER_USDC = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/transfer-usdc`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};

export const TRANSFER_SHADOW = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/transfer-shdw`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};

export const CREATE_VAULT = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/create-storage-account`, body, {
      headers: { Authorization: `${accessToken}` },
    }
  );
};

export const SET_PROFILE = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`https://api.synx.xyz/v0/api/user/set-profile`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};
export const SET_PROFILE_EXTERNAL = async (body, token) => {
  return axios.post(`https://api.synx.xyz/v0/api/wallet/user/set-profile`, body, {
    headers: { Authorization: `${token}` },
  });
};
export const SET_INVITATION = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}user/invitation-reward`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};

export const GET_PROFILE = async (body) => {
  const accessToken = await localStorage.getItem('access_token');
  return axios.post(`https://api.synx.xyz/v0/api/user/get-profile`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};
export const DECRYPT_FILE = async (body) => {
  const accessToken = await localStorage.getItem('access_token');
  return axios.post(`https://api.synx.xyz/api/solana/get-decrypted-filev2`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};
export const SWAP_TOKEN = async (body) => {
  const accessToken = localStorage.getItem('access_token');
  return axios.post(`${URL}solana/swap-token`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};
