import React from "react";
import { Navigate } from "react-router-dom";
export default function Protected({ children }) {
  const authUser = localStorage.getItem("access_token");
  const str_payload = localStorage.getItem('payload');
  if(str_payload){
    let payload = JSON.parse(str_payload)
    if (payload.vaults.length > 0) {
      return <Navigate to="/dashboard" />;
    }else{
      return <Navigate to="/onboarding" />;
    }
  }
 
  return children;
}
