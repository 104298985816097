import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import DashboardSideBar from "../components/dashboardLeftSideBar";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import synxRightBarAllFiles from "../assets/synxRightBarAllFiles.png";
import { useNavigate } from "react-router-dom";
import DataTaskList from "../components/datataskList";

function DataTasks() {
  useEffect(() => {
    document.title = 'Synx | Data Tasks 🤑';
  }, []);

  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const navigate = useNavigate(); 

  
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <Grid
          item
          xs={1.7}
          sx={{
            backgroundColor: "primary.light",
          }}
        >
          <DashboardSideBar />
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            padding: "1rem",
          }}
        >
          <img
            className="logoRightBarAllFiles"
            src={synxRightBarAllFiles}
            alt="synxRightBarAllFiles"
            style={{ height: "90px", width: "90px" }}
          />
        </Grid>
        <Grid item container xs={10} sx={{ padding: 5 }}>
          <Grid
            item
            xs={12}
          >
            <Typography
              sx={{
                color: " #FFFFFF",
                fontSize: "1rem",
                fontWeight: "700",
                fontFamily: "DM Sans",
              }}
            >
              Data Market Tasks
            </Typography>
            <Typography
              sx={{
                color: " #949494",
                fontSize: "1rem",
                paddingTop: "1rem",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              Verified tasks will count towards your points on the leaderboard, later in the year we plan to integrate our SPL token & SOL into the ecosystem for rewards. We partner with companies to help you monetise your data if you choose to and make sure you are fairly rewarded.
            </Typography>
            <Grid item>
                <DataTaskList />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default DataTasks;

