import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";

import StickyNote2RoundedIcon from "@mui/icons-material/StickyNote2Rounded";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import RsvpOutlinedIcon from '@mui/icons-material/RsvpOutlined';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SynxSendLockiIcon from "../../assets/SendSynxLockIcon.png";
import SynxsendlockColorChange from "../../assets/synxsendlockcolorchange.png";
import { dashBoardLeftSideBar } from "../../constants/appConstants";
function TextSectionLeftBar2({
  activeDataMarket,
  DataMarket,
  activeMinter,
  Minter,
  activeAlbum,
  Album,
  activeSpartans,
  Spartans,
}) {
  const { TOOLSHEADING, SYNXSEND, MINT, ALBUM, SPARTANS } = dashBoardLeftSideBar;
  const navigate = useNavigate();
  return (
    <Grid item xs={12}>
      <Box
        className="boxHeight"
        sx={{
          height: "2rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography className="buttonText" variant="toolSectionH1">
          {TOOLSHEADING}
        </Typography>
      </Box>
      <Box
        className="boxHeight"
        onClick={DataMarket}
        sx={{
          background: activeDataMarket
            ? "linear-gradient(0deg, rgba(108, 93, 211, 0.08), rgba(108, 93, 211, 0.08)), #1E1E25"
            : "",

          height: "2rem",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          className="buttonText"
          variant="imageIconsTypography"
          sx={{
            color: activeDataMarket ? "#A79EE5" : "",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {activeDataMarket ? (
              <img
                className="buttonImg"
                src={SynxsendlockColorChange}
                alt="SynxsendlockColorChange-Logo"
                style={{
                  height: "15px",
                  width: "15px",
                  marginRight: "0.3rem",
                  display: "inline-flex",
                }}
              />
            ) : (
              <img
                className="buttonImg"
                src={SynxSendLockiIcon}
                alt="SynxSendLockIcon-Logo"
                style={{
                  height: "15px",
                  width: "15px",
                  marginRight: "0.3rem",
                  display: "inline-flex",
                }}
              />
            )}
          </Box>
          {SYNXSEND}
        </Typography>
      </Box>
      <Box
        className="boxHeight"
        onClick={() => navigate('/map')}
        sx={{
          background: activeMinter
            ? "linear-gradient(0deg, rgba(108, 93, 211, 0.08), rgba(108, 93, 211, 0.08)), #1E1E25"
            : "",
          height: "2rem",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          className="buttonText"
          variant="toolBtnSection"
          sx={{
            color: activeMinter ? "#A79EE5" : "",
          }}
        >
          <MapOutlinedIcon
            className="buttonIcon"
            sx={{
              color: activeMinter ? "#A79EE5" : "#DADADA",

              display: "inline-flex",
              verticalAlign: "middle",
              fontSize: "1rem",
              fontWeight: "500",
              marginRight: "0.3rem",
              alignContent: 'center'
            }}
          />
          {MINT}
        </Typography>
      </Box>

      {/* <Box
        className="boxHeight"
        onClick={Spartans}
        sx={{
          background: activeSpartans
            ? "linear-gradient(0deg, rgba(108, 93, 211, 0.08), rgba(108, 93, 211, 0.08)), #1E1E25"
            : "",
          height: "2rem",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          className="buttonText"
          variant="toolBtnSection"
          sx={{
            color: activeSpartans ? "#A79EE5" : "",
          }}
        >
          <RsvpOutlinedIcon
            className="buttonIcon"
            sx={{
              color: activeSpartans ? "#A79EE5" : "#DADADA",

              display: "inline-flex",
              verticalAlign: "middle",
              fontSize: "1rem",
              fontWeight: "500",
              marginRight: "0.3rem",
            }}
          />
          {SPARTANS}
        </Typography>
      </Box> */}
      {/* <Box
        className="boxHeight"
        onClick={Album}
        sx={{
          background: activeAlbum
            ? "linear-gradient(0deg, rgba(108, 93, 211, 0.08), rgba(108, 93, 211, 0.08)), #1E1E25"
            : "",
          height: "2rem",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          className="buttonText"
          variant="toolBtnSection"
          sx={{
            color: activeAlbum ? "#A79EE5" : "",
          }}
        >
          <PhotoLibraryIcon
            className="buttonIcon"
            sx={{
              color: activeAlbum ? "#A79EE5" : "#DADADA",
              display: "inline-flex",
              verticalAlign: "middle",
              fontSize: "1rem",
              fontWeight: "500",
              marginRight: "0.3rem",
            }}
          />
          {ALBUM}
        </Typography>
      </Box> */}
    </Grid>
  );
}

export default TextSectionLeftBar2;
