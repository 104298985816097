import React,{useEffect, useState} from "react";
import { Button, Card, CardContent, Grid } from "@mui/material";
import logo from "../assets/logo.png";
import CountUp from 'react-countup';
import InviteFriendsBanner from "../components/inviteFriendsBanner.js";
import { LoginButton } from "../store/LoginButton.tsx";
import { GET_STATS } from "../services/solana_services";


export const Login = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);
  const [totalfiles, setTotalfiles] = useState(0);

  useEffect(() => {
    document.title = 'Synx | The Web3 storage platform 👋';
  }, []); 
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://synx.xyz";
    }
  }, []);

  useEffect(() => {
    GET_STATS().then(response => {
    const payload = response.data.data.payload;
    setTotalUsers (payload.totalVaults);
    setTotalStorage (payload.totalStorage / 1e12) ;
    setTotalfiles (payload.totalFiles);
    });
  }, []);

  return (
  
    <div className="bgdark h-100vh">
        <InviteFriendsBanner />
        
        <Grid container sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "3rem",
          paddingRight: "3rem",
          paddingTop: "2rem"
        }}>
          <Grid item md={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Grid container justifyContent="center" alignItems="center" style={{ marginBottom: '25vh', marginTop: '12.5vh' }}>
              <Grid item xs={12} md={11}>
              <div className="loginpage">
                  <div className="logo text-center">
                    <div className="text-center" style={{ display: 'flex', justifyContent: 'center', marginBottom: '-5px' }}>
                      <img src={logo} alt="missingLogo" />
                    </div>
                    <p style={{
                        textAlign: "center",
                        marginBottom: '20%',
                        color: 'pink',
                        fontSize: '86px',
                        fontWeight: 'bold',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        textShadow: '0px 4px 6px rgba(0, 0, 0, 0.4)',
                      }}>
                        The Web3 storage platform.
                      </p>
                      <h2 style={{ textAlign: "center", marginTop: '37.5%', fontFamily: 'DM Sans', fontWeight: '700' }}>📷 Snap. 🗄️ Store. 🔒 Secure.</h2>
                    <div className="text-center">
                     <LoginButton/>
                    </div>
                  </div>
                  <div className="info-cards-container" style={{ paddingTop: '10%' }}>
                      <Grid container spacing={2}>
                          <Grid item xs={4}>
                              <Card sx={{ backgroundColor: '#282832', boxShadow: '0 0 5px rgba(179, 136, 255, 0.5)' }}>
                                  <CardContent style={{ textAlign: 'center' }}>
                                      <h2 style={{ color: '#FFFFFF', fontSize: '14px' }}>Files Stored</h2>
                                      <CountUp end={totalfiles} duration={5} prefix="" style={{ color: 'pink', fontSize: '32px', fontWeight: '700' }} />
                                  </CardContent>
                              </Card>
                          </Grid>
                          <Grid item xs={4}>
                              <Card sx={{ backgroundColor: '#282832', boxShadow: '0 0 5px rgba(179, 136, 255, 0.5)' }}>
                                  <CardContent style={{ textAlign: 'center' }}>
                                      <h2 style={{ color: '#FFFFFF', fontSize: '14px' }}>Total Storage</h2>
                                      <CountUp end={totalStorage} duration={5} prefix="" suffix=" TB" style={{ color: 'pink', fontSize: '32px', fontWeight: '700' }} />
                                  </CardContent>
                              </Card>
                          </Grid>
                          <Grid item xs={4}>
                              <Card sx={{ backgroundColor: '#282832', boxShadow: '0 0 5px rgba(179, 136, 255, 0.5)' }}>
                                  <CardContent style={{ textAlign: 'center' }}>
                                      <h2 style={{ color: '#FFFFFF', fontSize: '14px' }}>Happy Clients</h2>
                                      <CountUp end={totalUsers} duration={5} prefix="" style={{ color: 'pink', fontSize: '32px', fontWeight: '700' }} />
                                  </CardContent>
                              </Card>
                          </Grid>
                      </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
 
  );
}

