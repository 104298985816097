import React from "react";
import { useState } from "react";
import { Grid, Box, Typography,  Modal } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import BasicSelect from "./select";
import PieChartjs from "../components/textSectionRightBar/pieChart";
import SearchBarAvatar from "../assets/searchbarAvatars.png";
import SynxWalletIcon from "../assets/SynxWalletIcon.png";
import PersonalIconImg from "../assets/personal.png";
import TeamIconImg from "../assets/team.png";
import DashBoardGif from "../assets/dashboard-gif.gif";
import { useNavigate } from "react-router-dom";
import { dashBoardRightSideBar } from "../constants/appConstants";
import UploadDrawer from './uploadDrawer';
import { SwitchVaultPopover } from "./switchVaultPopover";
import WalletDrawer from "./walletDrawer";
import QRCode from "react-qr-code";


function DashboardRightSideBar({handleChangeVault, updateAllData}) {
  const laptopmatches = useMediaQuery("(min-height:900px)");
  const [pieChartValues, setPieChartValues] = useState("all");
  const [profileImage, setProfileImage] = useState("");
  const [refreshPage, setRefreshPage] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadDrawerOpen, setUploadDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleQROpen = () => {
    setOpen(true);
  };
  
  const handleQRClose = () => {
    setOpen(false);
  };
  


  const onRefreshPage = React.useCallback(async () => {
    setRefreshPage(true);
    setTimeout(function(){setRefreshPage(false)}, 2000)
  }, [refreshPage]);

  const handleUploadDrawerClose = () => {
    setUploadDrawerOpen(false); // Close the drawer
    
  };


   // Need to slide overlay here
   const profileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const profileClose = () => {
    setAnchorEl(null);
  };

  const { UPLOADTEXT, WALLET, GOPREMIUM, UPGRADETEXT, UPGRADEBUTTONTEXT } =
    dashBoardRightSideBar;
 
    const walletClose = () => {
      setModalOpen(false); // Close the modal
    };
  
    const walletClick = () => {
      setModalOpen(true); 
    };
    const uploadClick = () => {
      setUploadDrawerOpen(true); // Open the drawer
    };

  return (
    <>
    <SwitchVaultPopover anchorEl={anchorEl} handleClose={profileClose} />
 
      {/* first div */}
      <UploadDrawer open={uploadDrawerOpen} handleClose={handleUploadDrawerClose} updateRecentFiles={updateAllData} />
      <WalletDrawer open={modalOpen} close={walletClose} />
      <Grid container item sx={{ height: "100vh", position: "relative" }}>
        <Grid
          item
          xs={12}
          className="gridMargin"
          sx={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            marginTop: "0.8rem",
            paddingLeft: "0.8rem",
          }}
        >
          <Grid
            item
            xs={5.9}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BasicSelect
              handleChangeVault={handleChangeVault}
              onRefreshPage={onRefreshPage} 
             
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
              }}
              onClick={uploadClick}
            >
              <Grid item xs={12}>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FileUploadIcon
                    className="filUploadIcon"
                    size="small"
                    sx={{
                      color: "rgba(255, 255, 255, 0.5)",
                      display: "inline-flex",
                      fontSize: "1.5rem",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className="fileUploadTextSize"
                    sx={{
                      fontSize: "0.6rem",
                      fontWeight: "400",
                      color: "rgba(255, 255, 255, 0.5)",
                      fontFamily: "DM Sans",
                    }}
                  >
                    {UPLOADTEXT}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={3}
              onClick={walletClick}
              sx={{
                display: "flex",
              }}
            >
              <Grid item xs={12}>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="imgSizeWalletIcon"
                    src={SynxWalletIcon}
                    alt="SynxWalletIcon-Logo"
                    style={{
                      height: "22px",
                      width: "22px",
                      display: "inline-flex",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "0.1rem",
                  }}
                >
                  <Typography
                    className="fileUploadTextSize"
                    sx={{
                      fontSize: "0.6rem",
                      fontWeight: "400",
                      color: "rgba(255, 255, 255, 0.5)",
                      fontFamily: "DM Sans",
                    }}
                  >
                    {WALLET}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
              }}
            >
              <Grid item xs={12}>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={profileClick}
                >
                 <img
                  src={
                    profileImage
                      ? profileImage
                      : pieChartValues === "all"
                      ? SearchBarAvatar
                      : pieChartValues === "Personal"
                      ? PersonalIconImg
                      : TeamIconImg
                  }
                  style={{
                    height: "2.15rem",
                    width: "2.15rem",
                    cursor: "pointer",
                  }}
                />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PieChartjs refreshPage={refreshPage} />
        {/* third div */}

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className="gifImg "
            src={DashBoardGif}
            alt="Synx-Logo"
            style={{
              objectFit: "contain",
              borderRadius: "50%",
              width: "9rem",
              height: "8rem",
              position: "absolute",
              top: "66.5%",
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: laptopmatches ? "0rem" : "2rem",
            backgroundColor: "#282832",
          }}
        >
          <Box
            sx={{
              height: laptopmatches ? "2.75rem" : "2rem",
              display: "flex",
              alignItems: "center",
              marginTop: "0rem",
            }}
          >
            <Typography
              sx={{
                fontSize: laptopmatches ? "1.6rem" : "1rem",
                fontWeight: "700",
                fontFamily: "DM Sans",
              }}
            >
              {GOPREMIUM}
            </Typography>
          </Box>
          <Box
            sx={{
              height: laptopmatches ? "2.75rem" : "2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: laptopmatches ? "1rem" : "0.8rem",
                fontWeight: "400",
                color: "rgba(255, 255, 255, 0.5)",
                fontFamily: "DM Sans",
              }}
            >
              {UPGRADETEXT}
            </Typography>
          </Box>
          <Button
            className="buttonMarginRightBar"
            variant="contained"
            sx={{
              "&:hover": {
                backgroundColor: "#6C5DD3",
              },
              backgroundColor: "#6C5DD3",
              borderRadius: "8px",
              marginTop: laptopmatches ? "0.5rem" : "0.3rem",
              marginBottom: "1.2rem",
              height: laptopmatches ? "2.7rem" : "2.5rem",
              width: "40%",
              textTransform: "capitalize",
              fontSize: laptopmatches ? "1.1rem" : "",
            }}
            onClick={handleQROpen}
          >
            {UPGRADEBUTTONTEXT}
          </Button>

          <Modal
            open={open}
            onClose={handleQRClose}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#1E1E25',
                borderRadius: '8px',
                boxShadow: 24,
                p: 4,
                textAlign: 'center',
              }}
            >
               <Typography
              sx={{
                fontSize: laptopmatches ? "1rem" : "0.8rem",
                fontWeight: "400",
                color: "#FFFFFF",
                fontFamily: "DM Sans",
                paddingBottom: '10px',
              }}
            >
              Scan me to download for your device.
            </Typography>
              <QRCode value="https://linktr.ee/synxstorage" /> {/* Replace "QR Code Value" with the actual value you want to display */}
            </Box>
          </Modal>

        </Grid>
      </Grid>
    </>
  );
}

export default DashboardRightSideBar;
