import React, { useEffect, useState} from "react";

import { Grid, Typography } from "@mui/material";
import { JSEncrypt } from "jsencrypt";
import { GET_PROFILE } from "../../services/solana_services";
import { GET_All_FILES, GET_FILES_PIN, GET_STORAGE_ACCOUNT_INFO } from "../../services/services";

import Docs from "../../assets/docs.png";
import Images from "../../assets/images.png";
import Videos from "../../assets/videos.png";
import Audio from "../../assets/audio.png";
import { overViewSection } from "../../constants/appConstants";
function OverViewSection({fileCounts, refreshing}) {
  const {
    OVERVIEWHEADING,
    DOCS,
    DOCSITEMSDATA,
    IMAGES,
    IMAGESITEMDATA,
    VIDEOS,
    VIDEOSITEMSDATA,
    AUDIO,
    AUIOITEMSDATA,
  } = overViewSection;
  var encrypt = new JSEncrypt();
  var publicKey = process.env.REACT_APP_PUB_KEY;
  encrypt.setPublicKey(publicKey);
  const [files, setFiles] = useState([])
  const [recentFiles, setRecentFiles] = useState([])
  const [vaultsArray, setVaultsArray] = useState([])
  const [solanaPublicAddress, setSolanaPublicAddress] = useState("")
  const [solanaKey, setSolanaKey] = useState("")
  const [accessToken, setAccessToken] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [isImmutable, setImmutable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allFilesDetailedArr,setAllFilesDetailedArr]=useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    fetchBalances()
  }, []);
  const fetchBalances = async () => {
    const str_payload = localStorage.getItem('payload');
    let payload = JSON.parse(str_payload)
    setUserEmail(payload?.email)
  
    const accessTokenVal = localStorage.getItem('access_token');
    setAccessToken(accessTokenVal)
    const str_solana_keys = localStorage.getItem('solana_key');
    setSolanaKey(str_solana_keys)
    setSolanaPublicAddress(payload.solanaaccount)
    

    var enc_secret_key = encrypt.encrypt(str_solana_keys);
    let get_profile_body = { "email": payload.email, "name": payload.username, "secret_key": enc_secret_key, "typeOfLogin": payload.typeOfLogin }
    GET_PROFILE(get_profile_body)
    .then(({ data }) => {
        let vaults_array = data.data.payload.user_profile.vaults;
        if (vaults_array.length > 0) {
          getStorageList(payload._id, payload.email);
   
        }else{
          setIsLoading(false)
        }
     
    })
  .catch(async function (error) {

  if (error.response) {
  
  }
})
  }
  const getStorageList = async (user_id, user_email) => {
    let body = {
      "id": user_id,
      "email": user_email,
    };

    GET_All_FILES(body)
      .then(({ data }) => {
        if (data.status == 200) {
          let payload = data.data.payload;
          let files_arr = [];
          if(payload.length > 0){
            payload.map((item, index) => {
               if(item.files.length > 0){
                item.files.forEach(element => {
                  
                  element.storage_address = item.storageAccount
                  
                  files_arr.push(element)

                });
               }

            })
          }
         
          setFiles(files_arr)
     
        
          setIsLoading(false)
        }
      })
      .catch(async function (error) {
        if(!error?.response?.data?.message.includes("Unauthorized")){
          // alert(error?.response?.data?.message)
         }
      });

  }
  return (
    <>
      <Grid
        className="overViewHeading"
        item
        xs={10}
        sx={{
          marginTop: "0.8rem",
          marginBottom: "0.8rem",
        }}
      >
        <Typography
          sx={{
            color: " #FFFFFF",
            fontSize: "1rem",
            fontWeight: "700",
            fontFamily: "DM Sans",
          }}
        >
          {OVERVIEWHEADING}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginTop: "0.8rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid
          Item
          xs={2.75}
          sx={{
            height: "128px",
            // width: "100%",
            backgroundColor: "#1E1E25",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-betweeen",
            padding: "1rem",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.8)",
                fontSize: "1rem",
                fontWeight: "700",
                fontFamily: "DM Sans",
              }}
            >
              {DOCS}
            </Typography>
            <img
              src={Docs}
              alt="docs"
              style={{
                objectFit: "contain",
                width: "30px",
                height: "38px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              marginTop: "0.5rem",
            }}
          >
            <Typography
              sx={{
                color: " #7D7D7D",
                fontSize: "0.8rem",
                fontWeight: "400",
                fontFamily: "DM Sans",
              }}
            >
             {fileCounts?.documents} items
            </Typography>
          </Grid>
        </Grid>
        <Grid
          Item
          xs={2.75}
          sx={{
            height: "128px",
            // width: "174px",
            backgroundColor: "#1E1E25",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-betweeen",
            padding: "1rem",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: " rgba(255, 255, 255, 0.8)",
                fontSize: "1rem",
                fontWeight: "700",
                fontFamily: "DM Sans",
              }}
            >
              {IMAGES}
            </Typography>
            <img
              src={Images}
              alt="images"
              style={{
                objectFit: "contain",
                width: "30px",
                height: "38px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Typography
              sx={{
                color: " #7D7D7D",
                fontSize: "0.8rem",
                fontWeight: "400",
                fontFamily: "DM Sans",
                marginTop: "0.5rem",
              }}
            >
              {fileCounts?.media} items
            </Typography>
          </Grid>
        </Grid>
        <Grid
          Item
          xs={2.75}
          sx={{
            height: "128px",
            // width: "174px",
            backgroundColor: "#1E1E25",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-betweeen",
            padding: "1rem",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.8)",
                fontSize: "1rem",
                fontWeight: "700",
                fontFamily: "DM Sans",
              }}
            >
              {VIDEOS}
            </Typography>
            <img
              src={Videos}
              alt="videos"
              style={{
                objectFit: "contain",
                width: "30px",
                height: "38px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Typography
              sx={{
                color: " #7D7D7D",
                fontSize: "0.8rem",
                fontWeight: "400",
                fontFamily: "DM Sans",
                marginTop: "0.5rem",
              }}
            >
             {fileCounts?.others} items
            </Typography>
          </Grid>
        </Grid>
        <Grid
          Item
          xs={2.75}
          sx={{
            height: "128px",
            // width: "174px",
            backgroundColor: "#1E1E25",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-betweeen",
            padding: "1rem",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.8);",
                fontSize: "1rem",
                fontWeight: "700",
                fontFamily: "DM Sans",
              }}
            >
              {AUDIO}
            </Typography>
            <img
              src={Audio}
              alt="audio"
              style={{
                objectFit: "contain",
                width: "30px",
                height: "38px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Typography
              sx={{
                color: " #7D7D7D",
                fontSize: "0.8rem",
                fontWeight: "400",
                fontFamily: "DM Sans",
                marginTop: "0.5rem",
              }}
            >
              {fileCounts?.unknown} items
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default OverViewSection;
